<template>
  <div>
    <div>
      <div class="py-3">
        <button
          @click="$emit('back-step')"
          class="btn btn-lime text-sm flex items-center justify-center mx-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 font-bold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>

          Go Back To Previous Step
        </button>
      </div>

      <div v-if="P_customerType == 3">
        <h1 class="font-bold">
          Please Confirm You Want To Receive Text Message Notifications for this
          vehicle. Celluar provider Text Rates Apply.
        </h1>
      </div>

      <div v-if="P_customerType == 2">
        <h1 class="font-bold">
          Please verify the vehicle details below are correct
        </h1>
      </div>

      <h1
        v-if="P_newestCompatibleYear != null && P_oldestCompatibleYear != null"
        class="font-bold"
      >
        {{ P_oldestCompatibleYear }} - {{ P_newestCompatibleYear }}
        {{ P_manufacturer }} {{ P_model }}
      </h1>

      <h1
        v-if="P_newestCompatibleYear == null || P_oldestCompatibleYear == null"
        class="font-bold"
      >
        {{ P_exactYear }} {{ P_manufacturer }} {{ P_model }}
      </h1>
      <div
        class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll mxh-70"
      >
        <div>
          <div class="items-center">
            <button
              @click="$emit('next-step')"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 font-large text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Everything Looks Good!
            </button>
            <button
              @click="$emit('goto-step', 'change_manufacturer')"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 font-large text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Change Manufacturer
            </button>
            <button
              @click="$emit('goto-step', 'change_model')"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 font-large text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Change Model
            </button>
            <button
              @click="$emit('goto-step', 'change_exact_year')"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 font-large text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Change Year
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c1c9ConfirmVehicleDetails",
  props: [
    "P_customerType",
    "P_manufacturer",
    "P_model",
    "P_exactYear",
    "P_newestCompatibleYear",
    "P_oldestCompatibleYear",
  ],
  data() {
    return {};
  },
};
</script>
