/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function processTransactionForSlimCD(
  recycler_uid: string,
  recycler_location_uid: string,
  consumer_contact_uid: string,
  conversation_uid: String,
  fs36_doc_id: string,
  first_name: string,
  last_name: string,
  exp_month: string,
  exp_year: string,
  gate_id: string,
  cvv: string,
) {

  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_bi2_data.IV_firebase_uid.value,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      consumer_contact_uid: consumer_contact_uid,
      conversation_uid: conversation_uid,
      p1s3e24_fs36_doc_id: fs36_doc_id,
      p1s3e24_first_name: first_name,
      p1s3e24_last_name: last_name,
      p1s3e24_expiry_month: exp_month,
      p1s3e24_expiry_year: exp_year,
      p1s3e24_gate_id: gate_id,
      p1s3e24_cvv: cvv
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e24ProcessTransactionForSlimCD",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e24.ts",
          class_name: "p1s3e24",
          method_name: "processTransactionForSlimCD",
          error_title: "Failed to process transaction",
          error_description:
            "The server responded with an error when trying to process transaction",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default processTransactionForSlimCD;
