<template>
  <div>
    <div v-show="DV_isLoading">
      <h2 class="text-xl mt-32 text-gray-400">Please wait. Connecting...</h2>
      <svg class="h-12 mt-2 text-gray-400 spin-clockwise mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
      </svg>
    </div>

    <div v-show="!DV_isLoading">
      <c1c10GetConsumerDetails
        v-if="DV_activeStep == 'get_consumer_details'"
        @next-step="stepAfterConsumerDetails"
      />

      <c1c1SelectVehicle
        v-if="DV_activeStep == 'select_manufacturer'"
        :P_customerType="P_customerType"
        :P_selectedManufacturer="DV_manufacturer"
        :P_selectedYear="DV_selectedExactYear"
        :P_selectedModel="DV_selectedModel"
        @next-step="dynamicStepAfterVehicleSelected"
        @back-step="backStep"
      />

      <c1c5InterchangePartNumbers
        v-if="P_customerType == 'part' && DV_activeStep == 'part-numbers'"
        :P_interchangePartNumbersList="DV_interchangePartNumbersList"
        @next-step="nextStepPartNumberList"
        @back-step="backStep"
      />

      <c1c6VehiclesYouNeedPart
        v-if="P_customerType == 'part' && DV_activeStep == 'vehicles-and-parts-list'"
        :P_interchangePartNumbersList="DV_interchangePartNumbersList"
        :P_partsVehiclesList="DV_partsVehiclesList"
        @goto-step="dynamicGotoStep"
        @event-remove-part="removeInterchangePartNumber"
        @event-remove-vehicle="removeSelectedVehicle"
        @event-add-vehicle="AddNewVehcile"
        @next-step="queuePartsAvailabilityChat"
        @back-step="backStep"
      />

      <c1c11ConversationCreated
        v-if="DV_activeStep == 'conversation_created_feedback'"
        :P_phoneNumber="DV_phoneNumber"
        :P_conversationUrlUid="DV_conversationUrlUid"
        @next-step="stepAFterCreationFeedback"
      />

      <div v-if="DV_activeStep ==='too_many_active_conversations_error'" class="text-left px-2 text-sm my-4">
        <span class="mb-4">Currently, there are already 3 active conversations associated with the phone number {{DV_phoneNumber}}:</span>
        <span v-for="conversation in this.DV_existingConversationDetails" class="my-2 block text-xs">
         Conversation started on {{ timestampFormatter(conversation.chat_start_timestamp) }} with {{ conversation.employee_name }}
        </span>
        <span class="mt-4">To ensure the best experience for all users, we maintain a limit of 3 concurrent conversations per phone number.
        If you need assistance, please wait for one of the existing conversations to conclude, or you can consider using an 
        alternate phone number to initiate a new conversation. Thank you for your cooperation.</span>
      </div>
    </div>
  </div>
</template>

<script>
import c1c6VehiclesYouNeedPart from "./c1c6VehiclesYouNeedPart.vue";
import c1c5InterchangePartNumbers from "./c1c5InterchangePartNumbers.vue";
import c1c4VehicleInfo from "./c1c4VehicleInfo.vue";
import c1c1SelectVehicle from "./c1c1SelectVehicle.vue";
import c1c9ConfirmVehicleDetails from "./c1c9ConfirmVehicleDetails.vue";
import c1c10GetConsumerDetails from "./c1c10GetConsumerDetails.vue";
import c1c11ConversationCreated from "./c1c11ConversationCreated";

import AWN from "awesome-notifications";
import moment from "moment-timezone";

import queueConsumerConversation from "../../includes/server_calls/p1s3/p1s3e16";
import { manufacturer_uids } from "../../includes/vehicles.ts";
export default {
  name: "c1PickVehicleStepForm",
  
  props: ["P_customerType"],
  
  components: {
    c1c1SelectVehicle,
    c1c4VehicleInfo,
    c1c5InterchangePartNumbers,
    c1c6VehiclesYouNeedPart,
    c1c9ConfirmVehicleDetails,
    c1c10GetConsumerDetails,
    c1c11ConversationCreated,
  },

  data() {
    return {
      DV_activeStep: "get_consumer_details",
      DV_previousStep: "",
      DV_stepsHistory: [""],
      DV_manufacturer: "",
      DV_selectedExactYear: 0,
      DV_selectedModel: "",
      DV_interchangePartNumbersList: [],
      DV_partsVehiclesList: [],
      DV_activePartsVehicleListIndex: -1,
      DV_firstName: "",
      DV_lastName: "",
      DV_phoneNumber: "",
      DV_conversationUrlUid: "",
      DV_isLoading: false,
      DV_existingConversationDetails: []
    };
  },

  methods: {
    dynamicStepAfterVehicleSelected(selected) {
      if (selected == "part-numbers" && this.P_customerType == 'part') {
        this.DV_stepsHistory.push(this.DV_activeStep);
        this.DV_previousStep = this.DV_activeStep;
        this.DV_activeStep = "part-numbers";
        return;
      }

      this.DV_stepsHistory.push(this.DV_activeStep);
      this.DV_previousStep = this.DV_activeStep;

      this.DV_manufacturer = selected.selectedManufacturer;
      this.DV_selectedExactYear = selected.selectedYear;
      this.DV_selectedModel = selected.selectedModel;
      
      // Add to vehicles list or update existing entry
      if(this.DV_activePartsVehicleListIndex === -1) {
        this.DV_partsVehiclesList.push({
          manufacturer: this.DV_manufacturer,
          model: this.DV_selectedModel,
          year: this.DV_selectedExactYear,
          vehicle_parts_description: selected.vehiclePartDescription,
        });
        this.DV_activePartsVehicleListIndex = this.DV_partsVehiclesList.length -1;
      } else {
        this.DV_partsVehiclesList[this.DV_activePartsVehicleListIndex] = {
          manufacturer: this.DV_manufacturer,
          model: this.DV_selectedModel,
          year: this.DV_selectedExactYear,
          vehicle_parts_description: selected.vehiclePartDescription,
        };
      }
      
      if (this.P_customerType === 'sale') { 
        this.queueSaleVehicleChat();
        return;
      }
      
      this.DV_activeStep = "vehicles-and-parts-list";
    },

    stepAfterConsumerDetails(first_name, last_name, phone_number) {
      this.DV_firstName = first_name;
      this.DV_lastName = last_name;
      this.DV_phoneNumber = phone_number;

      this.stepSelectManufacturer();
    },

    AddNewVehcile() {
      this.DV_activePartsVehicleListIndex = -1;
      this.DV_manufacturer = "";
      this.DV_stepsHistory = [];
      this.DV_selectedExactYear = 0;
      this.DV_selectedModel = "";
      this.stepSelectManufacturer();
    },

    stepSelectManufacturer(goto_flag = false) {
      if (goto_flag != true) {
        this.DV_stepsHistory.push(this.DV_activeStep);
        this.DV_previousStep = this.DV_activeStep;
      }
      this.DV_activeStep = "select_manufacturer";
    },

    nextStepPartNumberList(list, goto_flag = false) {
      let previousStep = this.DV_previousStep;

      if (goto_flag != true) {
        this.DV_stepsHistory.push(this.DV_activeStep);
        this.DV_previousStep = this.DV_activeStep;
      }

      if (previousStep == 'select_model') {
        let parts = [];
        let lastIndex = this.DV_partsVehiclesList.length - 1;
        parts = list.map((part) => {return part.value})
        this.DV_partsVehiclesList[lastIndex]["parts"] = parts.join(", ");
      } else {
        this.DV_interchangePartNumbersList = list;
      }

      this.DV_activeStep = "vehicles-and-parts-list";
    },

    removeInterchangePartNumber(part) {
      let index = this.DV_interchangePartNumbersList.findIndex((p) => p.value == part.value)
      if (index > -1) {
        this.DV_interchangePartNumbersList.splice(index, 1);
      }
    },

    removeSelectedVehicle(vehicle) {
      let index = this.DV_partsVehiclesList.findIndex((v) => {
        return ((v.manufacturer == vehicle.manufacturer) && (v.model == vehicle.model) && (v.year == vehicle.year));
      })
      
      if (index > -1) {
        this.DV_partsVehiclesList.splice(index, 1);
      }
    },


    stepAFterCreationFeedback() {

    },

    backStep() {
      this.DV_activeStep = this.DV_stepsHistory.pop();
      this.DV_previousStep = this.DV_stepsHistory[this.DV_stepsHistory.length - 1];
    },

    queuePartsAvailabilityChat() {
      const CI = this;
      this.DV_isLoading = true;

      let vehiclesAndParts = []

      for (let vehicle of this.DV_partsVehiclesList) {
        vehiclesAndParts.push({
          vehicle_make_uid: manufacturer_uids[vehicle.manufacturer],
          vehicle_model_uid: vehicle.model,
          vehicle_year: vehicle.year,
          vehicle_parts_description: vehicle.vehicle_parts_description
        })
      }

      for (let part of this.DV_interchangePartNumbersList) {
        vehiclesAndParts.push(part.value)
      }

      let params = {
        recycler_uid: window.G_bi2_data.IV_guest_consumer_information.recycler_business_uid,
        recycler_location_uid: window.G_bi2_data.IV_guest_consumer_information.recycler_location_uid,
        conversation_info: {
          type: this.getActiveCustomerType(),
          first_name: this.DV_firstName,
          last_name: this.DV_lastName,
          vehicles_and_parts: vehiclesAndParts
        },
        phone_number: this.DV_phoneNumber,
      };

      queueConsumerConversation(params).then((resp) => {        
        if("max_open_conversation_count_reached" in resp && resp["max_open_conversation_count_reached"] === true) {
          CI.DV_existingConversationDetails = resp["conversation_summary"];
          CI.DV_activeStep = 'too_many_active_conversations_error';
        }  else if("new_conversation_uid" in resp) {
          CI.DV_conversationUrlUid = resp["new_conversation_uid"]
          CI.DV_activeStep = 'conversation_created_feedback';
        }
        CI.DV_isLoading = false;
      }).catch((error) => {
        CI.DV_isLoading = false;
        setTimeout(() => {
          CI.$awn.alert("Failed to start the conversation. Please try again.");
        }, 50)
      });
      // TODO - hide loading and set DV_conversationUrlUid to the return data from p1s3e16
    },

    queueSaleVehicleChat() {
      // TODO - show loading
      const CI = this;
      CI.DV_isLoading = true;

      let params = {
        recycler_uid: window.G_bi2_data.IV_guest_consumer_information.recycler_business_uid,
        recycler_location_uid: window.G_bi2_data.IV_guest_consumer_information.recycler_location_uid,
        conversation_info: {
          type: CI.getActiveCustomerType(),
          first_name: CI.DV_firstName,
          last_name: CI.DV_lastName,
        },
        phone_number: CI.DV_phoneNumber,
      };

      queueConsumerConversation(params).then((resp) => {        
        if("max_open_conversation_count_reached" in resp && resp["max_open_conversation_count_reached"] === true) {
          CI.DV_existingConversationDetails = resp["conversation_summary"];
          CI.DV_activeStep = 'too_many_active_conversations_error';
        }  else if("new_conversation_uid" in resp) {
          CI.DV_conversationUrlUid = resp["new_conversation_uid"]
          CI.DV_activeStep = 'conversation_created_feedback';
        }
        CI.DV_isLoading = false;
      }).catch((error) => {
        CI.DV_isLoading = false;
        setTimeout(() => {
          CI.$awn.alert("Failed to start the conversation. Please try again.");
        }, 50)
      });
    },

    getActiveCustomerType() {
      let type = "buy parts";

      if (window.G_bi2_data.IV_guest_consumer_information.request_type == "part") {
        type = "buy parts";
      } else if (window.G_bi2_data.IV_guest_consumer_information.request_type == "sale") {
        type = "sell vehicle";
      }

      return type;
    },

    dynamicGotoStep(step_name) {
      switch (step_name) {
        case "change_manufacturer": {
          this.DV_stepsHistory = [""];
          this.DV_previousStep = "";
          this.stepSelectManufacturer(true);
          break;
        }
        case "change_exact_year": {
          this.DV_stepsHistory = ["select_manufacturer"];
          this.DV_previousStep = "select_manufacturer";
          this.stepSelectExactYear(this.DV_manufacturer, true);
          break;
        }
        case "change_model": {
          this.DV_stepsHistory = ["select_manufacturer", "select_exact_year"];
          this.DV_previousStep = "select_exact_year";
          this.stepAfterYearSelected(this.DV_selectedExactYear, true);
          break;
        }
        case "select_manufacturer": {
          this.DV_stepsHistory = [""];
          this.DV_previousStep = "";
          this.DV_manufacturer = "";
          this.DV_selectedExactYear = "";
          this.DV_selectedModel = "";
          this.stepSelectManufacturer(true);
          break;
        }
        case "part-numbers": {
          this.DV_stepsHistory = ["get_consumer_details", "select_manufacturer", "select_exact_year", "select_model","vehicles-and-parts-list"];
          this.DV_previousStep = "vehicles-and-parts-list";
          this.DV_activeStep = "part-numbers";
          break;
        }
      }
    },

    timestampFormatter(timestamp) {
      // converting to 12 hrs format
      let timezone = moment.tz.guess();
      return moment.utc(timestamp, "DD/MM/YYYY, HH:mm:ss").tz(timezone).format("MM-DD-YYYY h:mm:ss A")
    }
  }
};
</script>
