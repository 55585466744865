/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

export type Params = {
  recycler_uid: string,
  recycler_location_uid: string,
  consumer_contact_uid: string,
  conversation_uid: String,
  fs36_doc_id: string,
  token: string,
  first_name: string,
  last_name: string,
  billing_zip_code: string,
  billing_address_line_1?: string,
  billing_address_line_2?: string,
  billing_city?: string,
  billing_state?: string,
  shipping_address_line_1?: string,
  shipping_address_line_2?: string,
  shipping_city?: string,
  shipping_state?: string,
  shipping_zip_code?: string,
  pickup_order?: boolean,
}

function processTransactionForAxePayments(
  data: Params
) {

  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_bi2_data.IV_firebase_uid.value,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: data.recycler_uid,
      recycler_location_uid: data.recycler_location_uid,
      consumer_contact_uid: data.consumer_contact_uid,
      conversation_uid: data.conversation_uid,
      p1s3e44_fs36_doc_id: data.fs36_doc_id,
      p1s3e44_axe_payments_token: data.token,
      p1s3e44_first_name: data.first_name,
      p1s3e44_last_name: data.last_name,
      p1s3e44_billing_address_line_1: data.billing_address_line_1,
      p1s3e44_billing_address_line_2: data.billing_address_line_2,
      p1s3e44_billing_city: data.billing_city,
      p1s3e44_billing_state: data.billing_state,
      p1s3e44_billing_zip_code: data.billing_zip_code,
      p1s3e44_shipping_address_line_1: data.shipping_address_line_1,
      p1s3e44_shipping_address_line_2: data.shipping_address_line_2,
      p1s3e44_shipping_city: data.shipping_city,
      p1s3e44_shipping_state: data.shipping_state,
      p1s3e44_shipping_zip_code: data.shipping_zip_code,
      p1s3e44_pickup_order: data.pickup_order,
    }

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e44ProcessTransactionForAxePayments",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e44.ts",
          class_name: "p1s3e44",
          method_name: "processTransactionForAxePayments",
          error_title: "Failed to process transaction",
          error_description:
            "The server responded with an error when trying to process transaction",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default processTransactionForAxePayments;
