<template>
  <div ref="scrollContainer" style="height: 81vh;" class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll">

    <!-- Header -->
    <div class="text-center mb-3 flex items-center justify-between">
      <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="cursor-pointer w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
      </svg>

      <span v-show="DV_activeView == 'card-info'">
        Pay Invoice <br />
      </span>

      <span v-show="C_termAndConditionPresent && (DV_activeView == 'terms-and-conditions' || DV_activeView == 'warning')">
        Transaction Terms and Conditions
      </span>      

      <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="cursor-pointer w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>

    <hr>

    <div v-show="C_termAndConditionPresent && DV_activeView == 'warning'">
      <!-- <span class="font-bold text-2xl inline-block">Transaction Terms and Conditions</span> -->
      <span class="mt-6 inline-block font-bold text-left italic">"I understand that by not agreeing to the Transaction Terms and Conditions I will not be able to complete the purchase of the requested auto parts today."</span>
      <button class="btn btn-lime text-center w-48 mt-5 block mx-auto" @click="DV_activeView='terms-and-conditions'">Review Terms and Conditions</button>
      <button class="btn btn-red text-white text-center w-48 mt-2 block mx-auto" @click="$emit('event-back')">Cancel Transaction</button>
    </div>

    <div v-show="C_termAndConditionPresent && DV_activeView == 'terms-and-conditions'">
      <!-- <span class="font-bold text-2xl inline-block">Transaction Terms and Conditions</span> -->
        <iframe id="terms-iframe" class="terms-iframe h-[60vh]" :srcdoc="DV_termsCondition" />
      <div class="flex flex-row items-center justify-center mt-5">
        <button class="btn btn-red text-center text-white w-48 block mr-2" @click="DV_activeView='warning'">Disagree</button>
        <button class="btn btn-lime text-center w-48 block" @click="agreedTerms">Agree</button>
      </div>
    </div>

    <!-- Payment loading -->
    <div v-show="DV_processingRequest && DV_activeView == 'card-info'" class="mt-20">  
      <h2 class="text-xl text-gray-400">Please wait. Payment Processing...</h2>
      <svg class="h-12 mt-2 text-gray-400 spin-clockwise mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
      </svg>
    </div>
    
    <div class="mt-4" v-show="!DV_processingRequest && DV_activeView == 'card-info'">
      <h1 class="font-bold text-2xl">{{P_paymentLink.payment_link_description}} for <span class="text-lime-500">${{P_paymentLink.amount.toFixed(2)}}</span></h1>

      <!-- Form fields -->
      <div class="mt-4">
        <label for="name">First Name <font color="red">*</font></label>
        <input v-model="DV_firstName" type="text" name="first_name"  id="first_name" class="text-center"/>
      </div>

      <div class="mt-4">
        <label for="name">Last Name <font color="red">*</font></label>
        <input v-model="DV_lastName" type="text" name="last_name" id="last_name" class="text-center"/>
      </div>

      <!-- <div class="mt-4">
        <label for="email">E-mail Address</label>
        <input type="text" name="email" id="email"/>
      </div> -->

      <div class="mt-4">
        <label for="name">Card information <font color="red">*</font></label>

        <svg v-if="!DV_slimcdLoaded && !DV_axeTokenizerLoaded" class="h-12 mt-2 text-gray-400 spin-clockwise mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        
        <div v-show="DV_paymentProcessorType === 'axe'" id="container"></div>

        <div v-show="(DV_slimcdLoaded || DV_axeTokenizerLoaded) && DV_paymentProcessorType === 'slimcd'">
          <div id="card" class="card">
            <div class="fieldset cc">
              <div class="cc-icon">
                <svg class="svg-inline--fa fa-credit-card icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="credit-card" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path class="" fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96v32H576V96c0-35.3-28.7-64-64-64H64zM576 224H0V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V224zM112 352h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16z"></path>
                </svg>
              </div>
              <input v-model="DV_cardNumber" placeholder="0000 0000 0000 0000" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==16) return false;" class="cc-input">
            </div>
            <div class="break"></div>
            <div class="fieldset exp flex w-full">
              <input placeholder="MM" v-model="DV_expMonth" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==2) return false;" class="exp-input !w-1/4" >
              <input placeholder="YYYY" v-model="DV_expYear" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;" class="exp-input !w-3/4 ml-1" >
            </div>
            <div class="fieldset cvv">
              <input placeholder="CVV" v-model="DV_cvv" type="number" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==4) return false;" class="cvv-input">
            </div>
          </div>
        </div>
      </div>
     
     
      <div v-show="DV_completeAddress">
        <hr class="mt-4"> 
      
        <!-- BILLING ADDRESS -->
        <div class="flex mt-4">
          <span class="text-xl">Billing Address:</span>
        </div>
        
        <div class="mt-2">
          <label for="billing_address_line_1">Street Address line 1 <font color="red">*</font></label>
          <input v-model="DV_billing_address_line_1" type="text" name="billing_address_line_1"  id="billing_address_line_1" class="text-center"/>
        </div>
          
        <div class="mt-4">
          <label for="billing_address_line_2">Street Address line 2</label>
          <input v-model="DV_billing_address_line_2" type="text" name="billing_address_line_2"  id="billing_address_line_2" class="text-center"/>
        </div>
          
        <div class="mt-4">
          <label for="billing_city">City <font color="red">*</font></label>
          <input v-model="DV_billing_city" type="text" name="billing_city"  id="billing_city" class="text-center"/>
        </div>
          
        <div class="mt-4">
          <label for="shipping_state">State <font color="red">*</font></label>
        </div>

        <div>
          <select class="text-center" v-model="DV_billing_state" style="background-color: white">
            <option v-for="state in DV_stateList"
              :value="state">{{ state }}
            </option>
          </select>
        </div>
      </div>
     
      <div class="mt-4">
        <label>Billing Zip Code <font color="red">*</font></label>
        <input v-model="DV_billingZipCode" style="width: 8rem; margin-inline: auto;" type="number" inputmode="numeric" pattern="/^-?\d+\.?\d*$/" onKeyPress="if(this.value.length==5) return false;" autocomplete="off" id="billing-zip-code" class="text-center"/>
      </div>
      
      <hr class="mt-4"> 
      
      <!-- SHIPPING ADDRESS -->
      
      <div class="flex mt-4">
        <span class="text-xl">Shipping Address:</span> 
      </div>

      <div v-show="DV_completeAddress" class="flex mt-1">
        <label class="text-m">Same as Billing Address?</label>  
        <!-- Toggle switch -->
          <div class="flex items-center ml-2">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_sameAsBillingAddress ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>

              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_sameAsBillingAddress"
                :class="[DV_sameAsBillingAddress ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>
      </div>

      <div class="flex mt-1">
        <label class="text-m">Pickup order</label>  
        <!-- Toggle switch -->
          <div class="flex items-center ml-2">
            <label 
              aria-pressed="false" 
              aria-labelledby="toggleLabel" 
              class="toggle-label toggle-btn"
              :class="[DV_pickUpOrder ? 'bg-blue-600' : 'bg-gray-200']"
            >
              <span class="sr-only">Use setting</span>

              <input 
                type="checkbox" 
                hidden="true"
                class="opacity-0 w-0 h-0"
                v-model="DV_pickUpOrder"
                :class="[DV_pickUpOrder ? 'toggle bg-blue-600' : 'bg-gray-200']"
              >
              <span aria-hidden="true" class="slider toggle-btn-slider"></span>
            </label>
          </div>
      </div>
      <div class="mt-2">
        <label for="shipping_address_line_1">Street Address line 1 <font color="red">*</font></label>
        <input 
          v-model="shippingAddress1"
          type="text" 
          name="shipping_address_line_1" 
          id="shipping_address_line_1" 
          class="text-center" 
          :readonly="DV_sameAsBillingAddress || DV_pickUpOrder"
          :style="{ backgroundColor: (DV_sameAsBillingAddress || DV_pickUpOrder) ? 'lightgray' : 'white' }" />
      </div>

      <div class="mt-4">
        <label for="shipping_address_line_2">Street Address line 2</label>
        <input 
          v-model="shippingAddress2" 
          type="text" 
          name="shipping_address_line_2" 
          id="shipping_address_line_2" 
          class="text-center"
          :readonly="DV_sameAsBillingAddress || DV_pickUpOrder"
          :style="{ backgroundColor: (DV_sameAsBillingAddress || DV_pickUpOrder) ? 'lightgray' : 'white'  }" />
      </div>

      <div class="mt-4">
        <label for="shipping_city">City <font color="red">*</font></label>
        <input 
          v-model="shippingCity" 
          type="text" 
          name="shipping_city" 
          id="shipping_city" 
          class="text-center"
          :readonly="DV_sameAsBillingAddress || DV_pickUpOrder"
          :style="{ backgroundColor: (DV_sameAsBillingAddress || DV_pickUpOrder) ? 'lightgray' : 'white'  }" />
      </div>

      <div class="mt-4">
        <label for="shipping_state">State <font color="red">*</font></label>
      </div>
      
      <div>
        <select
          class="text-center" 
          v-model="shippingState"
          :disabled="DV_sameAsBillingAddress || DV_pickUpOrder"
          :style="{ backgroundColor: (DV_sameAsBillingAddress || DV_pickUpOrder) ? 'lightgray' : 'white'  }"
        >
          <option v-for="state in DV_stateList"
            :value="state">{{ state }}
          </option>
        </select>

      <div class="mt-4">
        <label>Zip Code <font color="red">*</font></label>
        <input 
          v-model="shippingZipCode" 
          style="width: 8rem; margin-inline: auto;" 
          type="number" 
          inputmode="numeric" 
          pattern="/^-?\d+\.?\d*$/" 
          onKeyPress="if(this.value.length==5) return false;" 
          autocomplete="off" 
          id="shipping-zip-code" 
          class="text-center"
          :readonly="DV_sameAsBillingAddress || DV_pickUpOrder"
          :style="{ backgroundColor: (DV_sameAsBillingAddress || DV_pickUpOrder) ? 'lightgray' : 'white'  }" />
      </div>
    </div>
      
      <button v-if="DV_processingRequest" disabled class="btn border-2 rounded text-center w-80 mt-10" type="submit" id="btnSubmit">
        Processing transaction... <br/>
      </button>

      <button v-else @click.stop="submitHandler" :disabled="DV_processingRequest" class="btn btn-lime text-center w-48 mt-10 mb-20" type="submit" id="btnSubmit">
        Pay Now <br/>
      </button>
    </div>
  </div>
</template>

<script>
import processTransactionForSlimCD from "../../includes/server_calls/p1s3/p1s3e24"
import processTransactionForAxePayments from "../../includes/server_calls/p1s3/p1s3e44"
import getPublicTokenForPaymentProcessor from "../../includes/server_calls/p1s3/p1s3e25"
import locationSpecific from "../../mixins/locationSpecific.js"
import AWN from "awesome-notifications";
import { ajax } from "noquery-ajax";

export default {
  name: "c2c1c1PaymentLinkPage",

  props: ['P_paymentLink', 'P_retryFormData'],

  mixins: [locationSpecific],

  data() {
    return {
      DV_slimcdLoaded: false,
      DV_axeTokenizerLoaded: false,
      DV_paymentProcessorPublicToken: null,
      DV_paymentProcessorType: null,
      DV_invalidPublicToken: true,
      DV_processingRequest: false,
      DV_cardNumber: "",
      DV_firstName: "",
      DV_lastName: "",
      DV_expMonth: "",
      DV_expYear: "",
      DV_cvv: "",
      DV_billingZipCode: "",
      DV_billing_address_line_1: "",
      DV_billing_address_line_2: "",
      DV_billing_city: "",
      DV_billing_state: "",
      DV_shippingZipCode: "",
      DV_shipping_address_line_1: "",
      DV_shipping_address_line_2: "",
      DV_shipping_city: "",
      DV_shipping_state: "",
      DV_termsCondition: "",
      DV_termsAgreement: false,
      DV_activeView: "card-info",
      DV_sameAsBillingAddress: false,
      DV_pickUpOrder: false,
      DV_completeAddress: true,
      DV_AVSoption: 2,
    }
  },

  mounted() {
    const CI = this;

    // set terms description when type_number assigned
    if (this.P_paymentLink.terms_type_number && this.P_paymentLink.terms_type_number !== 0) {
      let key = `${window.G_bi2_data.IV_recycler_information.value.recycler_business_uid}-${window.G_bi2_data.IV_recycler_information.value.recycler_location_uid}-${this.P_paymentLink.terms_type_number}`
      CI.DV_termsCondition = window.G_bi2_data.IV_transaction_terms_and_conditions.value[key].transaction_terms_and_conditions;
    }

    if (CI.C_termAndConditionPresent && CI.DV_termsCondition !== "") {
      CI.DV_activeView = 'terms-and-conditions';
      //CI.adjustIframeHeight();
      CI.scrollToTop();
    }
 
    getPublicTokenForPaymentProcessor(
      window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
      window.G_bi2_data.IV_recycler_information.value.recycler_location_uid
    ).then((resp) => {
      CI.DV_paymentProcessorPublicToken = resp.public_api_token;

      if (resp.avs_verification_type) { CI.DV_AVSoption = resp.avs_verification_type }
      if (resp.avs_verification_type && resp.avs_verification_type == 1) { CI.DV_completeAddress = false }
    
      if (resp.payment_processor_id && resp.payment_processor_id == 1) { CI.DV_paymentProcessorType = 'slimcd' }
      if (resp.payment_processor_id && resp.payment_processor_id == 2) { CI.DV_paymentProcessorType = 'axe' }

      // Verify all information present
      if (!CI.DV_paymentProcessorPublicToken || !CI.DV_paymentProcessorType) { return; }      
      CI.DV_invalidPublicToken = false;

      // Include SlimCD
      if (CI.DV_paymentProcessorType === 'slimcd') {
        CI.includeCDN('stats.slimcd.com/soft/json/slimcd.js', function() {
          CI.DV_slimcdLoaded = true;
        }.bind(CI) );
      } 

      // Include Axe payment
      if (CI.DV_paymentProcessorType === 'axe') {
        
        CI.includeCDN('app.axevt.com/tokenizer/tokenizer.js', function() {
          CI.loadAxePaymentElements();
        }.bind(CI) );
      }
    }).catch((error) => {
      CI.DV_invalidPublicToken = true;
    })

    if (CI.P_retryFormData) {
      if (CI.P_retryFormData.firstName) {CI.DV_firstName = CI.P_retryFormData.firstName}
      if (CI.P_retryFormData.lastName) {CI.DV_lastName = CI.P_retryFormData.lastName}
    }
  },

  methods: {
    /*
    adjustIframeHeight() {
      const iframe = document.getElementById('terms-iframe');
      iframe.onload = function() {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframe.style.height = iframeDocument.body.scrollHeight + 'px';
      };
    },
    */

    agreedTerms() {
      const CI = this;

      CI.DV_activeView = 'card-info';
      CI.scrollToTop();
    },

    scrollToTop() {
      const container = this.$refs.scrollContainer;
      const scrollTo = 0;
      const duration = 0.3 * 1000;
      const startTime = performance.now();
      const ease = t => t<0.5 ? 2*t*t : -1+(4-2*t)*t;
    
      const scrollAnimation = currentTime => {
        const elapsedTime = currentTime - startTime;
        const nextScrollPosition = ease(elapsedTime / duration) * (scrollTo - container.scrollTop);
      
        if (elapsedTime < duration) {
          container.scrollTop += nextScrollPosition;
          requestAnimationFrame(scrollAnimation);
        } else {
          container.scrollTop = scrollTo;
        }
      };

      requestAnimationFrame(scrollAnimation);
    },

    includeCDN(URL, callback) {
      let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      
      object.src = '//' + URL;

      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    loadAxePaymentElements() {
      const CI = this;
      let cardContainer = document.getElementById('container');

      // satefy check  already loaded
      if (cardContainer && cardContainer.children.length == 1) {
        CI.DV_axeTokenizerLoaded = true;
        return;
      }

      CI.DV_axeTokenizer = new Tokenizer({
        url: 'https://app.axevt.com', 
        apikey: CI.DV_paymentProcessorPublicToken,
        container: '#container', 
        submission: (resp) => {
          if (resp.status === "success") {
            CI.handleAxePaymentSuccessResponse(resp.token)
            return;
          }

          if (resp.status === "validation") {
            if (resp.invalid.includes('cc')) { CI.$awn.warning('Invalid card information, please retry.'); }
            if (resp.invalid.includes('exp')) { CI.$awn.warning('Invalid expiration date, please retry'); }
            CI.DV_processingRequest = false;
            return;
          }

          CI.$awn.alert('Unable to process payment at the moment, reload page and try again. Please contact support if the issue presist.');
          CI.DV_processingRequest = false;
        },

        onLoad: () => { 
          CI.DV_axeTokenizerLoaded = true; 
          CI.scrollToTop();
        },
      })
    },

    submitHandler(form) {
      const CI = this;
 
      // Incorrect public token
      if (CI.DV_invalidPublicToken == true) {
        CI.$awn.alert('Unable to process payment at the moment, reload page and try again. Please contact support if the issue presist.');
        return;
      }

      if (!CI.DV_firstName || CI.DV_firstName.trim() == "") {
        CI.$awn.warning('First name cannot be empty.');
        return;
      }

      if (!CI.DV_lastName || CI.DV_lastName.trim() == "") {
        CI.$awn.warning('Last name cannot be empty.');
        return;
      }

      if (CI.DV_paymentProcessorType === 'slimcd') {

        if (!CI.DV_billing_address_line_1 || CI.DV_billing_address_line_1.trim() == "") {
          CI.$awn.warning('Billing Address line 1 cannot be empty.');
          return;
        }

        if (!CI.DV_billing_state || CI.DV_billing_state.trim() == "") {
          CI.$awn.warning('Billing State cannot be empty.');
          return;
        }

        if (!CI.DV_billing_city || CI.DV_billing_city.trim() == "") {
          CI.$awn.warning('Billing City cannot be empty.');
          return;
        }
      }
            
      if (CI.DV_paymentProcessorType === 'axe') {

        if (!CI.DV_billingZipCode || CI.DV_billingZipCode.trim() == "") {
          CI.$awn.warning('Billing Zip Code cannot be empty.')
          return;
        }
        
        if (CI.DV_AVSoption === "2") {
          
          if (!CI.DV_billing_address_line_1 || CI.DV_billing_address_line_1.trim() == "") {
            CI.$awn.warning('Billing Address line 1 cannot be empty.')
            return;
          }

          if (!CI.DV_billing_city || CI.DV_billing_city.trim() == "") {
            CI.$awn.warning('Billing City cannot be empty.')
            return;
          }

          if (!CI.DV_billing_state || CI.DV_billing_state.trim() == "") {
             CI.$awn.warning('Billing State cannot be empty.')
             return;
          }
        }

        if (CI.DV_sameAsBillingAddress !== true && CI.DV_pickUpOrder !== true) {

          if (!CI.DV_shipping_address_line_1 || CI.DV_shipping_address_line_1.trim() == "") {
            CI.$awn.warning('Shipping Address line 1 cannot be empty.')
            return;
          }

          if (!CI.DV_shipping_city || CI.DV_shipping_city.trim() == "") {
            CI.$awn.warning('Shipping City cannot be empty.')
            return;
          }

          if (!CI.DV_shipping_state || CI.DV_shipping_state.trim() == "") {
             CI.$awn.warning('Shipping State cannot be empty.')
             return;
          }

          if (!CI.DV_shippingZipCode || CI.DV_shippingZipCode.trim() == "") {
            CI.$awn.warning('Shipping Zip Code cannot be empty.')
            return;
          }
        }
      }

      CI.DV_processingRequest = true;
      
      if (CI.DV_paymentProcessorType === 'axe') {
        CI.initAxePaymentRequest();
      }

      if (CI.DV_paymentProcessorType === 'slimcd') {
        CI.initSlimCdRequest();
      }
    },

    // Process Axe - Submit the user input to axe using an auth transaction type
    initAxePaymentRequest() {
      const CI = this;

      if (CI.DV_axeTokenizerLoaded === false) {
        CI.$awn.alert('Unable to process payment at the moment, reload page and try again. Please contact support if the issue presist.');
        CI.DV_processingRequest = false;
        return;
      }

      CI.DV_axeTokenizer.submit();
    },

    // Process SlimCD - Submit the user input to slimcd using a LOAD transaction type
    initSlimCdRequest() {
      const CI = this;

      if (CI.DV_slimcdLoaded === false) {
        CI.$awn.alert('Unable to process payment at the moment, reload page and try again. Please contact support if the issue presist.');
        CI.DV_processingRequest = false;
        return;
      }

      // Check the user input for validity
      if (SlimCD.Validate.isValidCardNumber(CI.DV_cardNumber) === false) {
        CI.$awn.warning('Invalid cardnumber, please retry');
        CI.DV_processingRequest = false;
        return;
      }

      if (SlimCD.Validate.isValidExpDate(CI.DV_expMonth, CI.DV_expYear) === false) {
        CI.$awn.warning('Invalid expiration date, please retry');
        CI.DV_processingRequest = false;
        return;
      }

      SlimCD.Transact.ProcessTransaction( 
        {
          "username": CI.DV_paymentProcessorPublicToken, 
          "transtype": "LOAD", 
          "cardnumber": CI.DV_cardNumber, 
          "expmonth": CI.DV_expMonth, 
          "expyear": CI.DV_expYear, 
          "cvv2": CI.DV_cvv, 
          "first_name": CI.DV_firstName, 
          "last_name": CI.DV_lastName,
          "zip": CI.DV_billingZipCode,
          "address": CI.DV_billing_address_line_1 + " " + CI.DV_billing_address_line_2,
          "city": CI.DV_billing_city,
          "state": CI.DV_billing_state,
        },

        function (reply) {
          // AVS reply check
          if (reply.datablock.avsreply !== "Y") {
            CI.$awn.alert("Address does not match.");
            CI.DV_processingRequest = false;
          } else {
            if (reply.response == "Success") {
              CI.handleSlimCDSuccessResponse(reply.datablock.gateid);
            } else {
              // Announce the problem
              CI.$awn.alert('There was a problem processing the payment:\r\n' + reply.description);
              CI.DV_processingRequest = false;
            }
          }  
        } 
      );    
    },

    handleSlimCDSuccessResponse(gate_id) {
      const CI = this;

      processTransactionForSlimCD(
        window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        window.G_bi2_data.IV_conversation_information.value.consumer_uid,
        window.G_bi2_data.IV_conversation_information.value.conversation_uid,
        CI.P_paymentLink.payment_link_uid,
        CI.DV_firstName,
        CI.DV_lastName,
        CI.DV_expMonth,
        CI.DV_expYear,
        gate_id,
        CI.DV_cvv,
      ).then((resp) => {
        // Clear the fields to stay out of scope
        CI.DV_cardNumber = '';
        CI.DV_cvv = '';
        CI.DV_firstName = '';
        CI.DV_lastName = '';
        CI.DV_expMonth = '';
        CI.DV_expYear = '';

        CI.$emit('event-payment-success');
        CI.DV_processingRequest = false;
      }).catch((error) => {
        // Clear the fields to stay out of scope
        CI.DV_cardNumber = '';
        CI.DV_cvv = '';
        CI.DV_firstName = '';
        CI.DV_lastName = '';
        CI.DV_expMonth = '';
        CI.DV_expYear = '';

        CI.$emit('event-payment-failed', error.error_title);
        CI.DV_processingRequest = false;
      });
    },

    handleAxePaymentSuccessResponse(token) {
      const CI = this;

      if (CI.DV_sameAsBillingAddress === true) {
        CI.DV_shipping_address_line_1 = CI.DV_billing_address_line_1;
        CI.DV_shipping_address_line_2 = CI.DV_billing_address_line_2;
        CI.DV_shipping_city = CI.DV_billing_city;
        CI.DV_shipping_state = CI.DV_billing_state;
        CI.DV_shippingZipCode = CI.DV_billingZipCode;
      }

      if (CI.DV_AVSoption === 1) {
        if (CI.DV_pickUpOrder === true) {
          processTransactionForAxePayments({
            recycler_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
            recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
            consumer_contact_uid: window.G_bi2_data.IV_conversation_information.value.consumer_uid,
            conversation_uid: window.G_bi2_data.IV_conversation_information.value.conversation_uid,
            fs36_doc_id: CI.P_paymentLink.payment_link_uid,
            token: token,
            first_name: CI.DV_firstName,
            last_name: CI.DV_lastName,
            billing_zip_code: CI.DV_billingZipCode,
            pickup_order: CI.DV_pickUpOrder,
          }).then((resp) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

           CI.$emit('event-payment-success');
           CI.DV_processingRequest = false;
         }).catch((error) => {
           // Clear the fields to stay out of scope
           CI.DV_cardNumber = '';
           CI.DV_cvv = '';
           CI.DV_firstName = '';
           CI.DV_lastName = '';
           CI.DV_expMonth = '';
           CI.DV_expYear = '';

            CI.$emit('event-payment-failed', error.error_title);
            CI.DV_processingRequest = false;
          });
        } else {
          processTransactionForAxePayments({
            recycler_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
            recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
            consumer_contact_uid: window.G_bi2_data.IV_conversation_information.value.consumer_uid,
            conversation_uid: window.G_bi2_data.IV_conversation_information.value.conversation_uid,
            fs36_doc_id: CI.P_paymentLink.payment_link_uid,
            token: token,
            first_name: CI.DV_firstName,
            last_name: CI.DV_lastName,
            billing_zip_code: CI.DV_billingZipCode,
            shipping_city: CI.DV_shipping_city,
            shipping_state: CI.DV_shipping_state,
            shipping_zip_code: CI.DV_shippingZipCode,
            pickup_order: CI.DV_pickUpOrder,
          }).then((resp) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-success');
            CI.DV_processingRequest = false;
          }).catch((error) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-failed', error.error_title);
            CI.DV_processingRequest = false;
          });
        }
      }

      if (CI.DV_AVSoption === 2) {
        if (CI.DV_pickUpOrder === true) {
          processTransactionForAxePayments({
            recycler_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
            recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
            consumer_contact_uid: window.G_bi2_data.IV_conversation_information.value.consumer_uid,
            conversation_uid: window.G_bi2_data.IV_conversation_information.value.conversation_uid,
            fs36_doc_id: CI.P_paymentLink.payment_link_uid,
            token: token,
            first_name: CI.DV_firstName,
            last_name: CI.DV_lastName,
            billing_zip_code: CI.DV_billingZipCode,
            billing_address_line_1: CI.DV_billing_address_line_1,
            billing_address_line_2: CI.DV_billing_address_line_2,
            billing_city: CI.DV_billing_city,
            billing_state: CI.DV_billing_state,
            pickup_order: CI.DV_pickUpOrder
          }).then((resp) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-success');
            CI.DV_processingRequest = false;
          }).catch((error) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-failed', error.error_title);
            CI.DV_processingRequest = false;
          }); 
        } else {
          processTransactionForAxePayments({
            recycler_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
            recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
            consumer_contact_uid: window.G_bi2_data.IV_conversation_information.value.consumer_uid,
            conversation_uid: window.G_bi2_data.IV_conversation_information.value.conversation_uid,
            fs36_doc_id: CI.P_paymentLink.payment_link_uid,
            token: token,
            first_name: CI.DV_firstName,
            last_name: CI.DV_lastName,
            billing_zip_code: CI.DV_billingZipCode,
            billing_address_line_1: CI.DV_billing_address_line_1,
            billing_address_line_2: CI.DV_billing_address_line_2,
            billing_city: CI.DV_billing_city,
            billing_state: CI.DV_billing_state,
            shipping_address_line_1: CI.DV_shipping_address_line_1,
            shipping_address_line_2: CI.DV_shipping_address_line_2,
            shipping_city: CI.DV_shipping_city,
            shipping_state: CI.DV_shipping_state,
            shipping_zip_code: CI.DV_shippingZipCode,
            pickup_order: CI.DV_pickUpOrder
          }).then((resp) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-success');
            CI.DV_processingRequest = false;
          }).catch((error) => {
            // Clear the fields to stay out of scope
            CI.DV_cardNumber = '';
            CI.DV_cvv = '';
            CI.DV_firstName = '';
            CI.DV_lastName = '';
            CI.DV_expMonth = '';
            CI.DV_expYear = '';

            CI.$emit('event-payment-failed', error.error_title);
            CI.DV_processingRequest = false;
          });
        }
      }
    }
  },

  computed: {
    C_termAndConditionPresent() {
      return (this.P_paymentLink.terms_type_number && this.P_paymentLink.terms_type_number !== 0)
    },

    shippingAddress1: {
      get() {
        if (this.DV_sameAsBillingAddress === true) {
          return this.DV_billing_address_line_1;
        } else {
          return this.DV_shipping_address_line_1;
        }
      },
      set(value) {
        if (!this.DV_sameAsBillingAddress) {
          this.DV_shipping_address_line_1 = value;
        }
      }
    },

    shippingAddress2: {
      get() {
        if (this.DV_sameAsBillingAddress === true) {
          return this.DV_billing_address_line_2;
        } else {
          return this.DV_shipping_address_line_2;
        }
      },
      set(value) {
        if (!this.DV_sameAsBillingAddress) {
          this.DV_shipping_address_line_2 = value;
        }
      }
    },

    shippingCity: {
      get() {
        if (this.DV_sameAsBillingAddress === true) {
          return this.DV_billing_city;
        } else {
          return this.DV_shipping_city;
        }
      },
      set(value) {
        if (!this.DV_sameAsBillingAddress) {
          this.DV_shipping_city = value;
        }
      }
    },

    shippingState: {
      get() {
        if (this.DV_sameAsBillingAddress === true) {
          return this.DV_billing_state;
        } else {
          return this.DV_shipping_state;
        }
      },
      set(value) {
        if (!this.DV_sameAsBillingAddress) {
          this.DV_shipping_state = value;
        }
      }
    },

    shippingZipCode: {
      get() {
        if (this.DV_sameAsBillingAddress === true) {
          return this.DV_billingZipCode;
        } else {
          return this.DV_shippingZipCode;
        }
      },
      set(value) {
        if (!this.DV_sameAsBillingAddress) {
          this.DV_shippingZipCode = value;
        }
      }
    }
  },
  watch: {
    DV_pickUpOrder: {
      handler() {
        if (this.DV_pickUpOrder === true) {
          this.DV_sameAsBillingAddress = false
        }
      },
      deep: true
    },
    DV_sameAsBillingAddress: {
      handler() {
        if (this.DV_sameAsBillingAddress === true) {
          this.DV_pickUpOrder = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: hidden
}
.card>div:not(:last-child):not(.break) {
  padding: 0 5px 5px 0
}
.card>.fieldset:last-of-type {
  padding-right: 0!important
}
.card .cc {
  position: relative;
  flex: 1 1 auto;
  min-width: 200px;
  padding: 0 5px 0 0;
}
.card .cc .cc-icon {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 0 0 5px;
  left: 5px
}
.card .cc .cc-icon .icon {
  font-size: 18px;
  width: 20px;
}
.card .cc input{
  padding-left: 35px;
  width: 100%;
}
.card .break{
  height: 0px;
  padding: 0;
  margin: 0;
  flex: 1 1 100%
}
.card .exp, .card .cvv {
  position: relative;
  flex: 1 1 50%;
  min-width: 50px;
  max-width: initial
}
@media (max-width: 500px) {
  .card .cc {
    padding-right:0!important
  }
}
.terms-iframe {
  width: 100%;
  border: none;
}
</style>