<template>
  <div
    style="height: 80vh"
    class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll"
  >
    <!-- Header -->
    <div class="text-center mb-3 flex items-center justify-between">
      <svg
        @click="$emit('event-back')"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>

      <div class="text-black text-xl">Paid Invoice Details</div>

      <svg
        @click="$emit('event-back')"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>

    <hr />

    <!-- Loading Payment info -->
    <div v-if="DV_loading">
      <svg
        class="h-12 mt-2 text-gray-400 spin-anticlock mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    </div>

    <!-- Payment info -->
    <div v-else-if="DV_paymentInfoFound" class="w-min mx-auto">
      <div class="mt-4 font-medium text-center">
        <div class="flex items-center text-center w-max mx-auto">
          <span class="dot online"></span>
          <span class="ml-2">Payment Successful</span>
        </div>

        <div v-show="C_dateTime != 'Unknown date'">
          <span class="mt-2 block"
            >Processed {{ C_dateTime.split("at")[0] }}</span
          >
          <span class="mt-2 block">at {{ C_dateTime.split("at")[1] }}</span>
        </div>

        <div class="text-left mt-6">
          Card number
          <span class="text-lime-500">
            **** **** **** {{ C_lastFourDigits }}</span
          >
        </div>
        <div class="text-lime-500 text-left mt-3">{{ C_cardHolderName }}</div>
        <div class="text-left mt-3">
          Total <span class="text-lime-500">${{ P_paymentLink.amount }}</span>
        </div>
        <br />
      </div>

      <button
        v-if="DV_downloadingReceipt"
        :disabled="true"
        class="btn btn-lime text-center w-72 mt-16"
        type="submit"
      >
        Downloading PDF Receipt...
      </button>
      <button
        v-else
        :disabled="DV_downloadingReceipt"
        @click.stop="downloadPdfReceipt"
        class="btn btn-lime text-center w-72 mt-16"
        type="submit"
      >
        Download PDF Receipt
      </button>

      <button
        v-if="DV_sendingEmail"
        :disabled="true"
        class="btn btn-lime text-center w-72 mt-4"
        type="submit"
      >
        Sending Email Receipt...
      </button>
      <button
        v-else
        :disabled="DV_sendingEmail"
        @click.stop="confirmEmailForReceipt"
        class="btn btn-lime text-center w-72 mt-4"
        type="submit"
      >
        Email Me A Receipt
      </button>
    </div>

    <!-- Payment info not found -->
    <div v-else>
      <div class="mt-4 font-medium">
        Unable to fetch payment information. Please contact support at
        <a href="mailto:info@texnrewards.com">info@texnrewards.com</a>.
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import dataValidator from "../../includes/bi1Datavalidation";
import { CR, RC } from "../../includes/bi2SuccessCodes";
import requestEmailReceipt from "../../includes/server_calls/p1s5/p1s5e7";
import moment from "moment-timezone";
import AWN from "awesome-notifications";

export default {
  name: "c2c1c2PaymentLinkReceipt",

  props: ["P_paymentLink"],

  data() {
    return {
      DV_sendingEmail: false,
      DV_downloadingReceipt: false,
      DV_paymentLinksInformation: {},
      DV_currentPaymentInfo: {},
      DV_loading: true,
      DV_paymentInfoFound: false,
    };
  },

  mounted() {
    const CI = this;
    CI.DV_paymentLinksInformation =
      window.G_bi2_data.IV_payment_links_info.value;

    // wait for sometime to show payment info not found
    setTimeout(() => {
      CI.DV_loading = false;
    }, 2500);
  },

  methods: {
    downloadPdfReceipt() {
      const CI = this;
      CI.DV_downloadingReceipt = true;

      const conversationInfo =
        window.G_bi2_data.IV_conversation_information.value;

      let params = {
        consumer_uid: conversationInfo.consumer_uid,
        recycler_business_uid:
          window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        recycler_location_uid:
          window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        conversation_uid: conversationInfo.conversation_uid,
        payment_link_uid: CI.P_paymentLink.payment_link_uid,
        email: "placeholder@email.com",
        request_type: 1,
      };

      requestEmailReceipt(params)
        .then((resp) => {
          // Save the PDF with a specified name
          try {
            if (resp.content_receipt) {
              // Decode the Base64 data
              const decodedData = atob(resp.content_receipt);

              // Convert the decoded data into a Blob
              const blob = new Blob([decodedData], { type: 'application/pdf' });
              let linkSource = URL.createObjectURL(blob);

              let downloadLink = document.createElement("a");
              downloadLink.classList.add("hide");
              let fileName = "synergy_auto_solutions_receipt.pdf";
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.textContent = 'Download PDF Now'; 

              // Append the link to the document
              document.body.appendChild(downloadLink);

              // Trigger a click event to start the download
              downloadLink.click();
              downloadLink.remove();

              // Clean up by revoking the blob URL
              URL.revokeObjectURL(linkSource);
            }
          } catch (e) {
            CI.$awn.alert(
              "Failed to download the PDF. Please reload page and try again. Or contact support if the issue persist."
            );
          }
          CI.DV_downloadingReceipt = false;
        })
        .catch((error) => {
          const errorMessage = error.message;
          CI.DV_downloadingReceipt = false;
          CI.$awn.alert(
            "Failed to download the PDF. Please reload page and try again. Or contact support if the issue persist."
          );
        });
    },

    confirmEmailForReceipt() {
      const CI = this;

      CI.DV_sendingEmail = true;

      const defaultEmail =
        window.G_bi2_data.IV_consumer_information.value.email;
      var email = window.prompt(
        "Please verify / enter your email to get a receipt",
        defaultEmail || ""
      );

      let call_result = {};
      call_result = dataValidator.is_email_address(email);

      if (call_result[CR.success] !== RC.success) {
        CI.$awn.warning("Please provide a valid email address.");
        CI.DV_sendingEmail = false;
        return;
      }

      const conversationInfo =
        window.G_bi2_data.IV_conversation_information.value;

      let params = {
        consumer_uid: conversationInfo.consumer_uid,
        recycler_business_uid:
          window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        recycler_location_uid:
          window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        conversation_uid: conversationInfo.conversation_uid,
        payment_link_uid: CI.P_paymentLink.payment_link_uid,
        email: email,
        request_type: 2,
      };

      requestEmailReceipt(params)
        .then((resp) => {
          CI.DV_sendingEmail = false;
          CI.$awn.success(
            `Your receipt has been successfully emailed to '${email}'.`
          );
        })
        .catch((error) => {
          const errorMessage = error.message;
          CI.DV_sendingEmail = false;
          CI.$awn.alert(
            "Failed to send receipt via email. If you already requested an email in last 5 minutes please try again later. Or contact support if the issue persist."
          );
        });
    },

    getFormattedPhoneNumber(phone_number) {
      if (!phone_number) {
        return "";
      }

      let formated_phone_number = phone_number.substr(0, 3);
      formated_phone_number += "-";
      formated_phone_number += phone_number.substr(3, 3);
      formated_phone_number += "-";
      formated_phone_number += phone_number.substr(6, 4);

      return formated_phone_number;
    },
  },

  computed: {
    C_dateTime() {
      const CI = this;

      if (CI.P_paymentLink.payment_paid_time) {
        return moment
          .utc(CI.P_paymentLink.payment_paid_time.seconds * 1000)
          .tz(moment.tz.guess())
          .format("MMMM Do YYYY [at] h:mm A z");
      }

      return "Unknown date";
    },

    C_lastFourDigits() {
      const CI = this;

      return CI.DV_currentPaymentInfo.last_4_digits || "Unknown";
    },

    C_cardHolderName() {
      const CI = this;

      if (
        CI.DV_currentPaymentInfo.last_name ||
        CI.DV_currentPaymentInfo.first_name
      ) {
        return `${CI.DV_currentPaymentInfo.first_name} ${CI.DV_currentPaymentInfo.last_name}`;
      }

      return "Unknown";
    },

    C_formattedAddressLine2() {
      let address = "";

      if (window.G_bi2_data.IV_recycler_information.value.city) {
        address += window.G_bi2_data.IV_recycler_information.value.city;
      }
      if (window.G_bi2_data.IV_recycler_information.value.state) {
        address += `, ${window.G_bi2_data.IV_recycler_information.value.state}`;
      }
      if (window.G_bi2_data.IV_recycler_information.value.postal_code) {
        address += `, ${window.G_bi2_data.IV_recycler_information.value.postal_code}`;
      }

      return address;
    },

    C_formattedStreetAddress() {
      let address = "";

      if (window.G_bi2_data.IV_recycler_information.value.address_line_1) {
        address +=
          window.G_bi2_data.IV_recycler_information.value.address_line_1;
      }
      if (window.G_bi2_data.IV_recycler_information.value.address_line_2) {
        address += `, ${window.G_bi2_data.IV_recycler_information.value.address_line_2}`;
      }

      return address;
    },
  },

  watch: {
    DV_paymentLinksInformation: {
      handler() {
        const CI = this;

        if (
          CI.P_paymentLink.payment_link_uid in CI.DV_paymentLinksInformation ==
          false
        ) {
          return;
        }

        CI.DV_loading = false;
        CI.DV_paymentInfoFound = true;

        for (let payment_info_uid in CI.DV_paymentLinksInformation[
          CI.P_paymentLink.payment_link_uid
        ]) {
          const paymentInfo =
            CI.DV_paymentLinksInformation[CI.P_paymentLink.payment_link_uid][
              payment_info_uid
            ] || {};

          CI.DV_currentPaymentInfo = Object.assign({}, paymentInfo);
        }
      },

      deep: true,
    },
  },
};
</script>
