<template>
  <div class="mt-8 w-full px-12 text-md">
    <div>Please rate the interactions you had with {{C_employeeName}} during this conversation.

    <!-- rating buttons -->
    <span class="isolate rounded-md shadow-sm text-xs flex flex-col">
      <button :disabled="DV_submittingFeedback" @click.stop="DV_employeeRating = 100; submitFeedback()" :class="{'active': DV_employeeRating == 100}" type="button" class="btn filter-button mt-4">
        Amazing
      </button>
      <button :disabled="DV_submittingFeedback" @click.stop="DV_employeeRating = 80; submitFeedback()" :class="{'active': DV_employeeRating == 80}" type="button" class="btn filter-button mt-4">
        Good
      </button>
      <button :disabled="DV_submittingFeedback" @click.stop="DV_employeeRating = 60; submitFeedback()" :class="{'active': DV_employeeRating == 60}" type="button" class="btn filter-button mt-4">
        Average
      </button>
      <button :disabled="DV_submittingFeedback" @click.stop="DV_employeeRating = 40; submitFeedback()" :class="{'active': DV_employeeRating == 40}" type="button" class="btn filter-button mt-4">
        Below Average
      </button>
      <button :disabled="DV_submittingFeedback" @click.stop="DV_employeeRating = 20; submitFeedback()" :class="{'active': DV_employeeRating == 20}" type="button" class="btn filter-button mt-4">
        Bad
      </button>

      <button :disabled="DV_submittingFeedback" @click.stop="$emit('show-live-chat')" type="button" class="btn filter-button mt-4">
        I Don't Want To Give A Rating
      </button>

      <button
        v-show="DV_submittingFeedback"
        @click.stop="$emit('show-live-chat')"
        class="mt-4 btn btn-lime text-sm flex items-center justify-center"
      >
        Save Rating
      </button>
    </span>
    
    
    </div> 
  </div>
</template>

<script>
import rateEmployeeInteraction from "../../includes/server_calls/p1s3/p1s3e9"
import AWN from "awesome-notifications";

export default {
  name: "c2c2ConsumerReview",

  data() {
    return {
      DV_employeeRating: null,
      DV_submittingFeedback: false
    }
  },

  mounted() {
    // 
  },

  methods: {
    submitFeedback() {
      const CI = this;
      let rating = 0
      
      try {
        rating = parseInt(this.DV_employeeRating);
      } catch(e) {
        CI.$awn.alert("There was an issue submitting the rating. Please reload page and try again.");
        return;
      }

      this.DV_submittingFeedback = true;
      rateEmployeeInteraction(
        window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        window.G_bi2_data.IV_recycler_information.value.assigned_employee_uid,
        window.G_bi2_data.IV_conversation_information.value.consumer_uid,
        window.G_bi2_data.IV_conversation_information.value.conversation_uid,
        rating
      )
    }
  },

  computed: {
    C_employeeName() {
      return window.G_bi2_data.IV_recycler_information.value.assigned_employee_name || "this staff member"
    }
  }
}
</script>
