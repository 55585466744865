<template>
  <div>
    <div class="mx-2">
      <div class="py-3">
        <button
          @click="$emit('back-step')"
          class="btn btn-lime text-sm flex items-center justify-center mx-auto"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 font-bold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>

          Go Back To Previous Step
        </button>
      </div>
      <h1 class="font-bold">Input interchange numbers or part descriptions below. Use '+' for more parts, then 'Continue.'</h1>
    </div>
 
    <div class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 h-[38svh] overflow-y-scroll">

      <ul v-if="DV_partNumbersList.length > 0">
        <li
          v-for="(part, i) in DV_partNumbersList"
          :key="part.index"
          class="grid grid-cols-7 gap-2 items-center mb-2"
        >
          <span class="font-medium text-sm col-span-1"
            >Part<span>{{ i + 1 }}</span
            >:</span
          >
          <input
            v-model="part.value"
            type="text"
            class="col-span-5 w-full border border-gray-400 rounded-md px-2 py-1 focus:outline-none"
          />
          <div class="col-span-1 justify-between flex">
            <svg v-if="i == DV_partNumbersList.length - 1" @click="addNewPart" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-green-600" >
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg
              v-show="DV_partNumbersList.length > 1" @click="removePart(part.index)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600" > 
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </li>
      </ul>
    </div>

    <div class="mb-5 mt-3">
      <button @click="continueToNextStep" class="btn btn-lime p-2 w-2/3">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "c1c5InterchangePartNumbers",

  props: ['P_interchangePartNumbersList'],

  data() {
    return {
      DV_partNumbersList: [{ index: 0, value: "" }],
    };
  },

  mounted() {
    if (this.P_interchangePartNumbersList && this.P_interchangePartNumbersList.length > 0) {
      this.DV_partNumbersList = Object.assign([], this.P_interchangePartNumbersList);
    }
  },

  methods: {
    addNewPart() {
      let maxIndex = Math.max(...this.DV_partNumbersList.map((o) => o.index));
      this.DV_partNumbersList.push({ index: maxIndex + 1, value: "" });
    },

    removePart(index) {
      let itemIndex = this.DV_partNumbersList.findIndex(
        (part) => part.index == index
      );
      this.DV_partNumbersList.splice(itemIndex, 1);
      this.$forceUpdate();
    },

    continueToNextStep() {
      if (this.DV_partNumbersList.length == 1 && this.DV_partNumbersList[0].value.trim() == "") {
        this.$emit('next-step', [])
        return;
      }

      this.$emit('next-step', this.DV_partNumbersList)
    }
  },
};
</script>
