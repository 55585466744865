<template>
  <div class="mt-4 ml-5 mr-5">
    <div class="flex flex-col text-xl items-center gap-3">
      <h1 class="font-bold py-2 text-center text-xl">
        Enter Contact Information 
      </h1>

      <div class="sm:w-full w-3/4">
        <span>Name</span>
        <input v-model="DV_fullName" type="text"
          class="col-span-5 border border-gray-400 rounded-md px-2 py-1 focus:outline-none text-center"
          placeholder="Your Name"/>
        <span class="text-center text-red-500 text-xl">
          {{ DV_NameError }}
        </span>
      </div>


      <div class="sm:w-full w-3/4">
        <span> Phone Number </span>
        <input v-model="DV_phoneNumber" type="number" inputmode="numeric"
          class="col-span-5 border border-gray-400 rounded-md px-2 py-1 focus:outline-none text-center"
          placeholder="Your Phone Number"/>
        <span class="text-center text-red-500 text-xl">
          {{ DV_phoneNumberError }}
        </span>
      </div>
      <div class="mt-5">
        <button
          @click="submit()"
          class="btn btn-lime text-xl flex items-center justify-center mx-auto w-[68vw]"
        >
        Next
        </button>
      </div>

      <div class="flex items-center">
        <input type="checkbox" id="terms_agreement" name="terms_agreement" v-model="DV_agreement" class="h-4 mr-2 mt-0 w-4">
        <label v-if="isIframeActive" for="terms_agreement" class="cursor-pointer text-sm text-left">I agree to the terms & conditions stated below</label>
        <label v-else for="terms_agreement" class="cursor-pointer text-sm text-left">I agree to the terms & conditions stated below.</label>
      </div>

      <p class="text-sm text-left">
        By continuing after this page, you consent to receive automated text messages and calls from an autodialer system or live person. Consent to receive automated text alerts not required for purchase. Msg & Data Rates May Apply. Reply 'HELP' for HELP, 'STOP ALL' to cancel. <a class="text-blue-500" href="https://synergy-auto-solutions.com/use">Terms apply upon use</a>.
      </p>
    </div>
  </div>
</template>

<script>
import dataValidator from "../../includes/bi1Datavalidation";
import {CR,RC} from "../../includes/bi2SuccessCodes"

export default {
  name: "c1c10GetConsumerDetails",
  props: [],
  data() {
    return {
      DV_fullName: "",
      DV_phoneNumber: "",
      DV_NameError: "",
      DV_phoneNumberError: "",
      DV_locationName: "",
      DV_agreement: false,
      isIframeActive: window.isIframeActive
    };
  },

  mounted() {
    this.DV_locationName = window.G_bi2_data.IV_recycler_information.value.recycler_location_name;
    //load cached data
    
    this.DV_fullName = window.localStorage.getItem("c1c10_full_name") || "";
    this.DV_phoneNumber = window.localStorage.getItem("c1c10_phone_number") || "";
  },

  methods: {
    // Makeshift input validation, should probably be replaced later
    submit() {
      const CI = this;
      CI.DV_NameError = "";
      CI.DV_phoneNumberError = "";
      
      let fail_flag = false;
      let call_result = {};
      let first_name = "";
      let last_name = "";

      if (!CI.DV_agreement) {
        CI.$awn.alert("You must agree to the terms first");
        return;
      }

      //split full name so we don't have to have 2 fields
      let name_array = CI.DV_fullName.split(" ");
      for(let index in name_array) {
        if(index === 0 || index === '0') {
          first_name = name_array[index];
          continue;
        }
        last_name += name_array[index] + " ";
      }

      if(first_name === "") {
        first_name = CI.DV_fullName;
      }
      if (last_name === "") {
        last_name = "- Unknown Last Name";
      }

      call_result = dataValidator.is_string(CI.DV_fullName, 3, 40);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_NameError = "Name is required and should be between 3 and 40 letters.";
          fail_flag = true;
        }


      call_result = dataValidator.is_string(CI.DV_phoneNumber, 9, 10);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_phoneNumberError = "Phone number should be 10 digits";
        fail_flag = true;
      }

      call_result = dataValidator.is_us_phone_number(CI.DV_phoneNumber);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_phoneNumberError = "Valid US phone number is required.";
        fail_flag = true;
      }

      if (fail_flag) {
        return;
      }
      
      window.localStorage.setItem("c1c10_full_name",CI.DV_fullName);
      window.localStorage.setItem("c1c10_phone_number",CI.DV_phoneNumber);

      CI.$emit(
        "next-step",
        first_name,
        last_name,
        CI.DV_phoneNumber
      );
      
    },
  },
};
</script>
