/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface QueueConversationArguments {
  recycler_uid: string;
  recycler_location_uid: string;
  conversation_info:
    | {
        type: "buy parts";
        first_name: string;
        last_name: string;
        vehicles_and_parts: Array<
          | {
              vehicle_make_uid: string;
              vehicle_model_uid: string;
              vehicle_year: string;
              interchange_part_number: string;
            }
          | { interchange_part_number: string }
        >;
      }
    | {
        type: "sell vehicle";
        first_name: string;
        last_name: string;
        vehicle_vin: string;
        hast_title: boolean;
        owns_vehicle: boolean;
        vin_image_data: string;
      };
  phone_number: string;
}

export default function queueConsumerConversation({
  recycler_uid,
  recycler_location_uid,
  conversation_info,
  phone_number,
}: QueueConversationArguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      queue_request_source: 2,
      phone_number: phone_number,
    };

    if (conversation_info.type === "buy parts") {
      Object.assign(params, {
        conversation_type: 1,
        first_name: conversation_info.first_name,
        last_name: conversation_info.last_name,
        conversation_type_1_fields: JSON.stringify({vehicle_list: conversation_info.vehicles_and_parts,
        }),
      });
    } else if (conversation_info.type === "sell vehicle") {
      Object.assign(params, {
        conversation_type: 2,
        first_name: conversation_info.first_name,
        last_name: conversation_info.last_name,
        conversation_type_2_fields: JSON.stringify({
          vehicle_vin: conversation_info.vehicle_vin,
          has_title: conversation_info.hast_title,
          owns_vehicle: conversation_info.owns_vehicle,
          vin_image_data: conversation_info.vin_image_data,
        })
      });
    }

    ajax({
      url:
        window.G_cloud_p1s3_base_url + "p1s3e16QueueConsumerConversation",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e16.ts",
          class_name: "p1s3e16",
          method_name: "queueConsumerConversation",
          error_title: "Failed to queue consumer conversation",
          error_description:
            "The server responded with an error when trying to queue a consumer conversation",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}
