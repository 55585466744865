/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function getConversationDetails(conversation_uid: String) {
  return new Promise(function (resolve, reject) {
    let params = {
      auth_token: conversation_uid,
    };

    window.G_loading_data = true; 

    ajax({
      url:
        window.G_cloud_p1s3_base_url +
        "p1s3e17GetConsumerAuthDetailsFromToken",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e17.ts",
          class_name: "p1s3e17",
          method_name: "getConversationDetails",
          error_title: "Failed to get login / conversation details",
          error_description:
            "The server responded with an error when trying to send get the conversation details",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default getConversationDetails;
