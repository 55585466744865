const manufacturers = [
  "ACURA",
  "ALFA-ROMEO",
  "AMERICAN MOTORS",
  "ASTON MARTIN",
  "AUDI",
  "BENTLEY",
  "BMW",
  "BRICKLIN",
  "BUICK",
  "CADILLAC",
  "CHECKER",
  "CHEVROLET",
  "CHRYSLER",
  "CITROEN",
  "DAEWOO",
  "DAIHATSU",
  "DE TOMASO",
  "DELOREAN",
  "DODGE",
  "EAGLE",
  "FERRARI",
  "FIAT",
  "FISKER",
  "FORD",
  "GMC",
  "HONDA",
  "HYUNDAI",
  "INFINITI",
  "INTERNATIONAL HARVESTER",
  "ISUZU",
  "JAGUAR",
  "JEEP",
  "JENSEN",
  "KIA",
  "LAMBORGHINI",
  "LEXUS",
  "LINCOLN",
  "LOTUS",
  "MAHINDRA",
  "MASERATI",
  "MAYBACH",
  "MAZDA",
  "MERCEDES-BENZ",
  "MERCURY",
  "MERKUR",
  "MINI",
  "MITSUBISHI",
  "MORGAN",
  "NISSAN",
  "OLDSMOBILE",
  "OPEL",
  "PEUGEOT",
  "PLYMOUTH",
  "PONTIAC",
  "PORSCHE",
  "RENAULT",
  "ROLLS ROYCE",
  "ROOTES",
  "ROVER",
  "SAAB",
  "SATURN",
  "SCION",
  "SIMCA",
  "SKODA",
  "SMART",
  "STUDEBAKER",
  "SUBARU",
  "SUNBEAM",
  "SUZUKI",
  "TOYOTA",
  "TRIUMPH",
  "TVR",
  "VOLKSWAGEN",
  "VOLVO",
];

// TODO: Fill out the correct value for the UIDs
export const manufacturer_uids = {
  ACURA: "ACURA",
  "ALFA-ROMEO": "ALFA-ROMEO",
  "AMERICAN MOTORS": "AMERICAN MOTORS",
  "ASTON MARTIN": "ASTON MARTIN",
  AUDI: "AUDI",
  BENTLEY: "BENTLEY",
  BMW: "BMW",
  BRICKLIN: "BRICKLIN",
  BUICK: "BUICK",
  CADILLAC: "CADILLAC",
  CHECKER: "CHECKER",
  CHEVROLET: "CHEVROLET",
  CHRYSLER: "CHRYSLER",
  CITROEN: "CITROEN",
  DAEWOO: "DAEWOO",
  DAIHATSU: "DAIHATSU",
  "DE TOMASO": "DE TOMASO",
  DELOREAN: "DELOREAN",
  DODGE: "DODGE",
  EAGLE: "EAGLE",
  FERRARI: "FERRARI",
  FIAT: "FIAT",
  FISKER: "FISKER",
  FORD: "FORD",
  GMC: "GMC",
  HONDA: "HONDA",
  HYUNDAI: "HYUNDAI",
  INFINITI: "INFINITI",
  "INTERNATIONAL HARVESTER": "INTERNATIONAL HARVESTER",
  ISUZU: "ISUZU",
  JAGUAR: "JAGUAR",
  JEEP: "JEEP",
  JENSEN: "JENSEN",
  KIA: "KIA",
  LAMBORGHINI: "LAMBORGHINI",
  LEXUS: "LEXUS",
  LINCOLN: "LINCOLN",
  LOTUS: "LOTUS",
  MAHINDRA: "MAHINDRA",
  MASERATI: "MASERATI",
  MAYBACH: "MAYBACH",
  MAZDA: "MAZDA",
  "MERCEDES-BENZ": "MERCEDES-BENZ",
  MERCURY: "MERCURY",
  MERKUR: "MERKUR",
  MINI: "MINI",
  MITSUBISHI: "MITSUBISHI",
  MORGAN: "MORGAN",
  NISSAN: "NISSAN",
  OLDSMOBILE: "OLDSMOBILE",
  OPEL: "OPEL",
  PEUGEOT: "PEUGEOT",
  PLYMOUTH: "PLYMOUTH",
  PONTIAC: "PONTIAC",
  PORSCHE: "PORSCHE",
  RENAULT: "RENAULT",
  "ROLLS ROYCE": "ROLLS ROYCE",
  ROOTES: "ROOTES",
  ROVER: "ROVER",
  SAAB: "SAAB",
  SATURN: "SATURN",
  SCION: "SCION",
  SIMCA: "SIMCA",
  SKODA: "SKODA",
  SMART: "SMART",
  STUDEBAKER: "STUDEBAKER",
  SUBARU: "SUBARU",
  SUNBEAM: "SUNBEAM",
  SUZUKI: "SUZUKI",
  TOYOTA: "TOYOTA",
  TRIUMPH: "TRIUMPH",
  TVR: "TVR",
  VOLKSWAGEN: "VOLKSWAGEN",
  VOLVO: "VOLVO",
};

const models = {
  acura: [
    "CL",
    "CSX",
    "EL",
    "ILX",
    "INTEGRA",
    "LEGEND",
    "MDX",
    "NSX",
    "RDX",
    "RL",
    "RLX",
    "RSX",
    "SLX",
    "TL",
    "TLX",
    "TSX",
    "VIGOR",
    "ZDX",
  ],
  "alfa-romeo": [
    "147",
    "156",
    "1600 DUETTO SPIDER",
    "164",
    "166",
    "2000",
    "4C",
    "8C COMPETIZIONE",
    "8C SPIDER",
    "ALFAROMEO 147",
    "ALFAROMEO 156",
    "ALFAROMEO 1600 DUETTO SPIDER",
    "ALFAROMEO 166",
    "ALFAROMEO 2000",
    "ALFAROMEO 8C COMPETIZIONE",
    "ALFAROMEO 8C SPIDER",
    "ALFAROMEO ALFETTA",
    "ALFAROMEO GT",
    "ALFAROMEO GTV",
    "ALFETTA",
    "GIULIA",
    "GT",
    "GTV",
    "GTV6",
    "MILANO",
    "SPIDER",
  ],
  "american-motors": [
    "AMBASSADOR",
    "AMERICAN",
    "AMX",
    "CLASSIC",
    "CLASSIC 98",
    "CONCORD",
    "EAGLE",
    "EAGLE VISION",
    "GREMLIN",
    "H2",
    "H3",
    "H3T",
    "HORNET",
    "HUMMER",
    "HUMMER H1",
    "JAVELIN",
    "MARLIN",
    "MATADOR",
    "PACER",
    "RAMBLER",
    "REBEL",
    "ROGUE",
    "SPIRIT",
    "SUPER SIX",
  ],
  "aston-martin": [
    "DB4",
    "DB6",
    "DB7",
    "DB9",
    "DBS",
    "LAGONDA",
    "LAGONDA SALOON",
    "VANQUISH",
    "VANTAGE",
    "VIRAGE",
    "VOLANTE",
  ],
  audi: [
    "100",
    "100 QUATTRO",
    "100 SERIES",
    "200",
    "200 QUATTRO",
    "4000",
    "4000 QUATTRO",
    "5000",
    "5000 QUATTRO",
    "80",
    "80 QUATTRO",
    "90",
    "90 QUATTRO",
    "A3",
    "A3 ETRON",
    "A3 QUATTRO",
    "A3 SPORTBACK ETRON",
    "A4",
    "A4 ALLROAD",
    "A4 QUATTRO",
    "A5",
    "A5 QUATTRO",
    "A6",
    "A6 QUATTRO",
    "A7",
    "A7 QUATTRO",
    "A8",
    "A8 QUATTRO",
    "ALLROAD",
    "ALLROAD QUATTRO",
    "CABRIOLET",
    "COUPE",
    "COUPE QUATTRO",
    "FOX",
    "Q3",
    "Q3 QUATTRO",
    "Q5",
    "Q7",
    "QUATTRO",
    "R8",
    "RS4",
    "RS5",
    "RS6",
    "RS7",
    "S3",
    "S4",
    "S5",
    "S6",
    "S7",
    "S8",
    "SQ5",
    "SUPER 90",
    "TT",
    "TT QUATTRO",
    "TT RS",
    "TTS",
    "V8 QUATTRO",
  ],
  bentley: [
    "8",
    "ARNAGE",
    "AZURE",
    "BR0OKLAND",
    "BROOKLAND",
    "CONTINENTAL",
    "CONTINENTAL 33",
    "CONTINENTAL FLYING SPUR",
    "CONTINENTAL GT",
    "CONTINENTAL R",
    "MULSANNE",
    "TURBO R",
    "TURBO RL",
  ],
  bmw: [
    "1 SERIES M",
    "128I",
    "135I",
    "135IS",
    "1500",
    "1600",
    "16002",
    "1600TI",
    "1602",
    "1800",
    "1802",
    "2.8 BAVARIA",
    "2000",
    "2002",
    "2002TI",
    "2002TII",
    "228I",
    "228I XDRIVE",
    "230I",
    "2500",
    "2800",
    "2800CS",
    "3 SERIES",
    "3.0CS",
    "3.0CSI",
    "3.0S",
    "3.0SI",
    "4 SERIES",
    "5 SERIES",
    "6 SERIES",
    "7 SERIES",
    "8 SERIES",
    "ACTIVEHYBRID 3",
    "ACTIVEHYBRID 5",
    "ACTIVEHYBRID 7",
    "ALPINA B6 XDRIVE GRAN COUPE",
    "ALPINA B7",
    "ALPINA B7 XDRIVE",
    "ALPINA B7L",
    "ALPINA B7L XDRIVE",
    "BAVARIA",
    "BMW 116",
    "BMW 316I",
    "BMW 323I",
    "BMW M54OI",
    "I3",
    "I8",
    "L6",
    "L7",
    "M1",
    "M2",
    "M235I",
    "M235I XDRIVE",
    "M240I",
    "M3",
    "M4",
    "M5",
    "M540I",
    "M6",
    "M6 GRAN COUPE",
    "M760I",
    "X1",
    "X3",
    "X4",
    "X5",
    "X5M",
    "X6",
    "X6M",
    "Z3",
    "Z4",
    "Z8",
  ],
  bricklin: ["BRICKLIN", "SV1"],
  buick: [
    "ALLURE",
    "APOLLO",
    "CASCADA",
    "CENTURION",
    "CENTURY",
    "CENTURY CLASS",
    "COMMERCIAL CHASSIS",
    "ELECTRA",
    "ENCLAVE",
    "ENCORE",
    "ENVISION",
    "ESTATE WAGON",
    "GRAN SPORT",
    "GS",
    "GS 350",
    "GS 400",
    "GS 455",
    "INVICTA",
    "LACROSSE",
    "LESABRE",
    "LUCERNE",
    "PARK AVENUE",
    "RAINIER",
    "REATTA",
    "REGAL",
    "RENDEZVOUS",
    "RIVIERA",
    "ROADMASTER",
    "SKYHAWK",
    "SKYLARK",
    "SOMERSET",
    "SOMERSET REGAL",
    "SPECIAL",
    "SPORTWAGON",
    "TERRAZA",
    "VERANO",
    "WILDCAT",
  ],
  cadillac: [
    "60 SPECIAL",
    "ALLANTE",
    "ATS",
    "BROUGHAM",
    "CALAIS",
    "CATERA",
    "CIMARRON",
    "COMMERCIAL CHASSIS",
    "CT6",
    "CTS",
    "DEVILLE",
    "DEVILLE LIMO",
    "DTS",
    "ELDORADO",
    "ELR",
    "ESCALADE",
    "ESCALADE ESV",
    "ESCALADE EXT",
    "FLEETWOOD",
    "SERIES 60 FLEETWOOD",
    "SERIES 62",
    "SERIES 75 FLEETWOOD",
    "SEVILLE",
    "SRX",
    "STS",
    "XLR",
    "XT5",
    "XTS",
  ],
  checker: ["CAB", "MARATHON"],
  chevrolet: [
    "ASTRO",
    "AVALANCHE",
    "AVALANCHE 1500",
    "AVALANCHE 2500",
    "AVEO",
    "AVEO5",
    "BEAT",
    "BEAUMONT",
    "BEL AIR",
    "BERETTA",
    "BISCAYNE",
    "BLAZER",
    "BOLT",
    "BORREGO",
    "BROOKWOOD",
    "C10",
    "C10 PICKUP",
    "C10 SUBURBAN",
    "C1500",
    "C1500 SUBURBAN",
    "C20",
    "C20 PANEL",
    "C20 PICKUP",
    "C20 SUBURBAN",
    "C2500",
    "C2500 SUBURBAN",
    "C30",
    "C30 PANEL",
    "C30 PICKUP",
    "C3500",
    "C3500HD",
    "C50",
    "C6000 TOPKICK",
    "C6500 KODIAK",
    "C70",
    "CAMARO",
    "CAPRICE",
    "CAPTIVA SPORT",
    "CAVALIER",
    "CELEBRITY",
    "CHEVELLE",
    "CHEVETTE",
    "CHEVROLET 10 VAN",
    "CHEVROLET 1500 PICKUP",
    "CHEVROLET 2500 PICKUP",
    "CHEVROLET 3500 PICKUP",
    "CHEVROLET CORVAIR TRUCK",
    "CHEVROLET FORWARD CONTROL",
    "CHEVROLET HEAVY TRUCK",
    "CHEVROLET MOTORHOME",
    "CHEVY II",
    "CITATION",
    "CITATION II",
    "CITY EXPRESS",
    "CLASSIC",
    "COBALT",
    "COLORADO",
    "COMMERCIAL CHASSIS",
    "CORSICA",
    "CORVAIR",
    "CORVAIR TRUCK",
    "CORVAN",
    "CORVETTE",
    "CRUZE",
    "CRUZE LIMITED",
    "EL CAMINO",
    "ENVOY",
    "EPIC",
    "EPICA",
    "EQUINOX",
    "EXPRESS 1500",
    "EXPRESS 1500 VAN",
    "EXPRESS 2500",
    "EXPRESS 3500",
    "FIRENZA",
    "G10",
    "G10 VAN",
    "G20",
    "G20 VAN",
    "G30",
    "G30 VAN",
    "GREENBRIER",
    "GT AMERICA",
    "GT STRADA 5300",
    "GT VELOCE",
    "HHR",
    "IMPALA",
    "IMPALA LIMITED",
    "JIMMY",
    "K10",
    "K10 PICKUP",
    "K10 SUBURBAN",
    "K1500",
    "K1500 SUBURBAN",
    "K20",
    "K20 PICKUP",
    "K20 SUBURBAN",
    "K2500",
    "K2500 SUBURBAN",
    "K30",
    "K30 PICKUP",
    "K3500",
    "K5 BLAZER",
    "KADETT",
    "KINGSWOOD",
    "LAGUNA",
    "LUMINA",
    "LUMINA APV",
    "LUMINA VAN",
    "LUV",
    "LUV PICKUP",
    "MALIBU",
    "MATIZ",
    "METRO",
    "MONTE CARLO",
    "MONZA",
    "NOVA",
    "OPEL",
    "OPTRA",
    "ORLANDO",
    "P10",
    "P10 SERIES",
    "P10 VAN",
    "P20",
    "P20 SERIES",
    "P20 VAN",
    "P30",
    "P30 SERIES",
    "P30 VAN",
    "PRIZM",
    "R10",
    "R10 SUBURBAN",
    "R1500 SUBURBAN",
    "R20",
    "R20 SUBURBAN",
    "R2500",
    "R2500 SUBURBAN",
    "R30",
    "R3500",
    "S10",
    "S10 BLAZER",
    "S15",
    "S15 JIMMY",
    "SILVERADO",
    "SONIC",
    "SONOMA",
    "SPARK",
    "SPARK EV",
    "SPECTRUM",
    "SPRINT",
    "SS CAPRICE",
    "SSR",
    "STORM",
    "SUBURBAN",
    "SUBURBAN 1500",
    "SUBURBAN 2500",
    "SUBURBAN 30",
    "SUBURBAN 3500",
    "TACUMA",
    "TAHOE",
    "TOWNSMAN",
    "TRACKER",
    "TRAILBLAZER",
    "TRAILBLAZER EXT",
    "TRAVERSE",
    "TRAX",
    "UPLANDER",
    "V10",
    "V10 SUBURBAN",
    "V1500 SUBURBAN",
    "V20",
    "V20 SUBURBAN",
    "V2500 SUBURBAN",
    "V30",
    "V3500",
    "VEGA",
    "VENTURE",
    "VICTOR",
    "VIVANT",
    "VOLT",
  ],
  chrysler: [
    "200",
    "300",
    "300M",
    "5TH AVENUE",
    "ARROW",
    "ARROW PICKUP",
    "ASPEN",
    "CHALLENGER",
    "CHAMP",
    "CIRRUS",
    "COLT",
    "CONCORDE",
    "CONQUEST",
    "CORDOBA",
    "CRICKET",
    "CROSSFIRE",
    "D50",
    "DE SOTO",
    "DESOTO",
    "E CLASS",
    "EXECUTIVE LIMOUSINE",
    "EXECUTIVE SEDAN",
    "FIFTH AVENUE",
    "GRAND VOYAGER",
    "IMPERIAL",
    "LASER",
    "LEBARON",
    "LHS",
    "NEON",
    "NEW YORKER",
    "NEW YORKER (RWD)",
    "NEWPORT",
    "PACIFICA",
    "POWER RAM 50",
    "PROWLER",
    "PT CRUISER",
    "RAIDER",
    "RAM 50",
    "SAPPORO",
    "SARATOGA",
    "SEBRING",
    "STEALTH",
    "TC MASERATI",
    "TOWN &amp; COUNTRY",
    "VISTA",
    "VISTA HATCHBACK",
    "VOYAGER",
    "WINDSOR",
  ],
  citroen: [
    "BERLINGO",
    "C1",
    "C2",
    "C3",
    "C3 PLURIEL",
    "C4",
    "C5",
    "CITROEN",
    "DS21",
    "DS21 PALLAS",
    "ID19",
    "PICASSO",
    "SM",
    "VISA",
    "XSARA",
  ],
  daewoo: ["CIELO", "ESPERO", "J100", "KORANDO", "LANOS", "LEGANZA", "NUBIRA"],
  daihatsu: ["CHARADE", "CUORE", "ROCKY", "SIRION", "TERIOS"],
  "de-tomaso": ["PANTERA"],
  delorean: ["DELOREAN", "DMC 12"],
  dodge: [
    "1500",
    "2000 GTX",
    "2500",
    "330",
    "330 GT",
    "3500",
    "400",
    "440",
    "4500",
    "5500",
    "600",
    "880",
    "A100",
    "A108 VAN",
    "ARIES",
    "ASPEN",
    "AVENGER",
    "B100",
    "B100 VAN",
    "B150",
    "B1500",
    "B200",
    "B200 VAN",
    "B250",
    "B2500",
    "B300",
    "B300 VAN",
    "B350",
    "B3500",
    "C/V",
    "CALIBER",
    "CARAVAN",
    "CB300",
    "CHALLENGER",
    "CHARGER",
    "CORONET",
    "CUSTOM",
    "D100",
    "D100 PICKUP",
    "D100 SERIES",
    "D150",
    "D200",
    "D200 PICKUP",
    "D200 SERIES",
    "D250",
    "D300",
    "D300 PICKUP",
    "D300 SERIES",
    "D350",
    "D400",
    "D450",
    "DAKOTA",
    "DART",
    "DAYTONA",
    "DIPLOMAT",
    "DODGE 100 PICKUP",
    "DODGE 3500 MEGA CAB PICKUP",
    "DODGE 400 PICKUP",
    "DODGE 450 PICKUP",
    "DODGE FORWARD CONTROL",
    "DODGE HEAVY DUTY TRUCK",
    "DODGE MOTORHOME",
    "DODGE PICKUP TRUCK",
    "DURANGO",
    "DYNASTY",
    "EXPO",
    "GRAND CARAVAN",
    "INTREPID",
    "JOURNEY",
    "LANCER",
    "MAGNUM",
    "MATADOR",
    "MINI RAM",
    "MIRADA",
    "MONACO",
    "NEON",
    "OMNI",
    "P100",
    "P200",
    "P300",
    "POLARA",
    "PROMASTER 1500",
    "PROMASTER 2500",
    "PROMASTER 3500",
    "PROMASTER CITY",
    "RAM 1500 VAN",
    "RAM 2500",
    "RAM 2500 VAN",
    "RAM 3500",
    "RAM 3500 VAN",
    "RAM 4500",
    "RAM 5500",
    "RAM CHARGER",
    "RAM PICKUP",
    "RAMCHARGER",
    "RAMPAGE",
    "RD200",
    "REGENT",
    "ROYAL MINI RAM",
    "ROYAL MONACO",
    "SHADOW",
    "SPIRIT",
    "SPRINTER 2500",
    "SPRINTER 3500",
    "SPRINTER VAN 3500",
    "ST. REGIS",
    "STRATUS",
    "VIPER",
    "W100",
    "W100 PICKUP",
    "W100 SERIES",
    "W150",
    "W200",
    "W200 PICKUP",
    "W200 SERIES",
    "W250",
    "W300",
    "W300 PICKUP",
    "W300 SERIES",
    "W350",
  ],
  eagle: ["PREMIER", "SUMMIT", "TALON", "VISION", "VISTA SEDAN"],
  ferrari: [
    "300 GT",
    "308",
    "308 GTB",
    "308 GTBI",
    "308 GTS",
    "308 GTSI",
    "308GTS",
    "328",
    "328 GTB",
    "328 GTS",
    "330 GT",
    "330 GTC",
    "330 GTS",
    "330GT",
    "348",
    "348 GTB",
    "348 GTS",
    "348 SPIDER",
    "348 TB",
    "348 TS",
    "355",
    "360",
    "400I",
    "456 GT",
    "456 GTA",
    "456 M",
    "456 M GT",
    "456 M GTA",
    "512M",
    "550",
    "575 M MARANELLO",
    "599 GTB",
    "612 SCAGLIETTI",
    "CALIFORNIA",
    "DINO 308 GT4",
    "ENZO",
    "F355",
    "F355 BERLINETTA",
    "F355 F1",
    "F355 GTS",
    "F355 SPIDER",
    "F40",
    "F430",
    "MONDIAL",
    "MONDIAL 8",
    "SPIDER",
    "TESTAROSSA",
  ],
  fiat: [
    "1100",
    "1100R",
    "124",
    "124 SPIDER",
    "128",
    "131",
    "1500",
    "1500 SERIES",
    "500",
    "500L",
    "850",
    "BERTONE",
    "BETA",
    "BRAVA",
    "DOBLO",
    "LANCIA",
    "MULTIPLA",
    "PALIO",
    "PANDA",
    "PUNTO",
    "RITMO",
    "SCORPION",
    "SEICENTO",
    "STILO",
    "STRADA",
    "UNO",
    "X 1/9",
    "X1/9",
    "ZAGATO",
  ],
  fisker: ["KARMA"],
  ford: [
    "300",
    "AEROSTAR",
    "ASPIRE",
    "BRONCO",
    "BRONCO II",
    "CLUB WAGON",
    "CMAX",
    "CONTOUR",
    "CORTINA",
    "COUNTRY SEDAN",
    "COUNTRY SQUIRE",
    "COURIER",
    "COURIER SEDAN DELIVERY",
    "CROWN VICTORIA",
    "CUSTOM",
    "CUSTOM 300",
    "CUSTOM 500",
    "E-150",
    "E100 ECONOLINE",
    "E100 ECONOLINE CLUB WAGON",
    "E150 CLUB WAGON",
    "E200 ECONOLINE",
    "E250",
    "E250 ECONOLINE",
    "E250 ECONOLINE CLUB WAGON",
    "E250 SUPER DUTY",
    "E300 ECONOLINE",
    "E350",
    "E350 CLUB WAGON",
    "E350 ECONOLINE",
    "E350 ECONOLINE CLUB WAGON",
    "E350 SUPER DUTY",
    "E450 ECONOLINE SUPER DUTY",
    "E450 ECONOLINE SUPER DUTY STRIPPED",
    "E450 SUPER DUTY",
    "ECONOLINE",
    "ECONOLINE SUPER DUTY",
    "EDGE",
    "ELITE",
    "ESCAPE",
    "ESCORT",
    "EXCURSION",
    "EXP",
    "EXPEDITION",
    "EXPLORER",
    "EXPLORER SPORT TRAC",
    "F SUPER DUTY",
    "F-150",
    "F100",
    "F100 PANEL DELIVERY",
    "F100 PICKUP",
    "F150 HERITAGE",
    "F250",
    "F250 PICKUP",
    "F250 SUPER DUTY",
    "F350",
    "F350 PICKUP",
    "F350 SUPER DUTY",
    "F450 SUPER DUTY",
    "F500",
    "F53",
    "F550 SUPER DUTY",
    "F59",
    "FAIRLANE",
    "FAIRMONT",
    "FALCON",
    "FALCON SEDAN DELIVERY",
    "FESTIVA",
    "FIESTA",
    "FIVE HUNDRED",
    "FLEX",
    "FOCUS",
    "FOCUS RS",
    "FORD E150 VAN",
    "FORD E550SD VAN",
    "FORD F150 RAPTOR",
    "FORD F450 PICKUP",
    "FORD F650SD PICKUP",
    "FORD F750SD PICKUP",
    "FORD FORWARD CONTROL",
    "FORD HEAVY TRUCK",
    "FORD MOTORHOME",
    "FORD VAN",
    "FREESTAR",
    "FREESTYLE",
    "FUSION",
    "GALAXIE",
    "GALAXIE 500",
    "GRAN TORINO",
    "GRANADA",
    "GT",
    "LTD",
    "LTD CROWN VICTORIA",
    "LTD II",
    "MAVERICK",
    "MONDEO",
    "MUSTANG",
    "MUSTANG II",
    "P350",
    "P400",
    "P500",
    "PINTO",
    "PROBE",
    "RANCH WAGON",
    "RANCHERO",
    "RANGER",
    "SHELBY COBRA",
    "SPRINT",
    "SQUIRE",
    "STARLINER",
    "SUNLINER",
    "TAURUS",
    "TAURUS X",
    "TEMPO",
    "THUNDERBIRD",
    "TORINO",
    "TRANSIT CONNECT",
    "TRANSIT150",
    "TRANSIT250",
    "TRANSIT350",
    "TRANSIT350 HD",
    "VICTORIA",
    "WINDSTAR",
  ],
  gmc: [
    "1000 SERIES",
    "1500 SERIES",
    "2500 SERIES",
    "ACADIA",
    "BLAZER",
    "C15",
    "C15 SUBURBAN",
    "C15/C1500 PICKUP",
    "C15/C1500 SUBURBAN",
    "C1500",
    "C1500 SUBURBAN",
    "C25",
    "C25 SUBURBAN",
    "C25/C2500 PICKUP",
    "C25/C2500 SUBURBAN",
    "C2500",
    "C2500 SUBURBAN",
    "C35",
    "C35/C3500 PICKUP",
    "C3500",
    "C3500HD",
    "C5000",
    "C6000",
    "C6500 TOPKICK",
    "C7000",
    "CABALLERO",
    "CANYON",
    "ENVOY",
    "ENVOY XL",
    "ENVOY XUV",
    "G1000 SERIES",
    "G15",
    "G15/G1500 VAN",
    "G1500",
    "G25",
    "G25/G2500 VAN",
    "G2500",
    "G35",
    "G35/G3500 VAN",
    "G3500",
    "GMC 1000 PICKUP",
    "GMC 1500 PICKUP",
    "GMC 1500 VAN",
    "GMC 2500 PICKUP",
    "GMC 2500 VAN",
    "GMC 3500 PICKUP",
    "GMC 3500 VAN",
    "GMC FORWARD CONTROL",
    "GMC HEAVY TRUCK",
    "JIMMY",
    "K15",
    "K15 SUBURBAN",
    "K15/K1500 PICKUP",
    "K15/K1500 SUBURBAN",
    "K1500",
    "K1500 SUBURBAN",
    "K25",
    "K25 SUBURBAN",
    "K25/K2500 PICKUP",
    "K25/K2500 SUBURBAN",
    "K2500",
    "K2500 SUBURBAN",
    "K35",
    "K35/K3500 PICKUP",
    "K3500",
    "K5 BLAZER",
    "P15",
    "P15/P1500 VAN",
    "P1500",
    "P25",
    "P25/P2500 VAN",
    "P2500",
    "P35",
    "P35/P3500 VAN",
    "P3500",
    "R1500",
    "R1500 SUBURBAN",
    "R2500",
    "R2500 SUBURBAN",
    "R3500",
    "S10",
    "S10 BLAZER",
    "S15",
    "S15 JIMMY",
    "SAFARI",
    "SAVANA 1500",
    "SAVANA 1500 VAN",
    "SAVANA 2500",
    "SAVANA 3500",
    "SIERRA",
    "SIERRA 1500",
    "SIERRA 1500 CLASSIC",
    "SIERRA 1500 HD",
    "SIERRA 1500 HD CLASSIC",
    "SIERRA 2500",
    "SIERRA 2500 HD",
    "SIERRA 2500 HD CLASSIC",
    "SIERRA 3500",
    "SIERRA 3500 CLASSIC",
    "SIERRA 3500 HD",
    "SIERRA 3500 PICKUP",
    "SIERRA C3",
    "SIERRA DENALI",
    "SIERRA DENALI 1500",
    "SIERRA DENALI 2500",
    "SIERRA DENALI 3500",
    "SONOMA",
    "SPRINT",
    "SUBURBAN",
    "SUBURBAN 1500",
    "SUBURBAN 2500",
    "SYCLONE",
    "TERRAIN",
    "TRUCK",
    "TYPHOON",
    "V1500",
    "V1500 SUBURBAN",
    "V2500",
    "V2500 SUBURBAN",
    "V3500",
    "W4500 FORWARD",
    "YUKON",
    "YUKON XL",
    "YUKON XL 1500",
    "YUKON XL 2500",
  ],
  honda: [
    "600",
    "ACCORD",
    "ACCORD CROSSTOUR",
    "CIVIC",
    "CIVIC DEL SOL",
    "CR-V",
    "CROSSTOUR",
    "CRX",
    "CRZ",
    "DEL SOL",
    "ELEMENT",
    "EV PLUS",
    "FCX",
    "FIT",
    "HRV",
    "INSIGHT",
    "ODYSSEY",
    "PASSPORT",
    "PILOT",
    "PRELUDE",
    "RIDGELINE",
    "S2000",
  ],
  hyundai: [
    "ACCENT",
    "ATOS",
    "ATOS PRIME",
    "AZERA",
    "ELANTRA",
    "ELANTRA COUPE",
    "ELANTRA GT",
    "ENTOURAGE",
    "EQUUS",
    "EXCEL",
    "GENESIS",
    "GENESIS COUPE",
    "GETZ",
    "H1",
    "H100",
    "HD65",
    "IONIQ",
    "MATRIX",
    "PONY",
    "SANTA FE",
    "SANTA FE XL",
    "SCOUPE",
    "SONATA",
    "STELLAR",
    "TIBURON",
    "TRAJET",
    "TUCSON",
    "VELOSTER",
    "VERACRUZ",
    "XG300",
    "XG350",
  ],
  infiniti: [
    "EX35",
    "EX37",
    "FX35",
    "FX37",
    "FX45",
    "FX50",
    "G20",
    "G25",
    "G35",
    "G37",
    "I30",
    "I35",
    "J30",
    "JX35",
    "M30",
    "M35",
    "M35H",
    "M37",
    "M45",
    "M56",
    "Q3",
    "Q3 QUATTRO",
    "Q40",
    "Q45",
    "Q50",
    "Q60",
    "Q70",
    "Q70L",
    "QX30",
    "QX4",
    "QX50",
    "QX56",
    "QX60",
    "QX70",
    "QX80",
  ],
  "international-harvester": [
    "INTERNATIONAL",
    "INTERNATIONAL HARVESTER TRUCK",
    "SCOUT",
    "SCOUT II",
  ],
  isuzu: [
    "AMIGO",
    "ASCENDER",
    "AXIOM",
    "HOMBRE",
    "I280",
    "I290",
    "I350",
    "I370",
    "IMARK",
    "IMPULSE",
    "ISUZU FRR",
    "ISUZU FSR",
    "ISUZU FTR",
    "ISUZU FVR",
    "ISUZU NPR",
    "ISUZU NQR",
    "OASIS",
    "OPTIMA",
    "PICKUP",
    "RODEO",
    "RODEO SPORT",
    "STYLUS",
    "TROOPER",
    "VEHICROSS",
  ],
  jaguar: [
    "3.8S",
    "FPACE",
    "FTYPE",
    "MARK X",
    "STYPE",
    "SUPER V8",
    "VANDEN PLAS",
    "X-TYPE",
    "XE",
    "XF",
    "XFR",
    "XFRS",
    "XJ",
    "XJ12",
    "XJ40",
    "XJ6",
    "XJ8",
    "XJR",
    "XJRS",
    "XJS",
    "XK",
    "XK150",
    "XK8",
    "XKE",
    "XKR",
    "XKRS",
  ],
  jeep: [
    "ARMY",
    "CHEROKEE",
    "CJ3",
    "CJ5",
    "CJ5A",
    "CJ6",
    "CJ6A",
    "CJ7",
    "COMANCHE",
    "COMMANDER",
    "COMMANDO",
    "COMPASS",
    "DJ3",
    "DJ5",
    "DJ6",
    "FARGO 1 TON",
    "FARGO 1/2 TON",
    "FARGO 3/4 TON",
    "FC150",
    "FC170",
    "FCSERIES",
    "GLADIATOR",
    "GRAND CHEROKEE",
    "GRAND VOYAGER",
    "GRAND WAGONEER",
    "J10",
    "J100",
    "J20",
    "J200",
    "J210",
    "J220",
    "J230",
    "J2500",
    "J2600",
    "J2700",
    "J2800",
    "J300",
    "J310",
    "J320",
    "J330",
    "J3500",
    "J3600",
    "J3700",
    "J3800",
    "J4500",
    "J4600",
    "J4700",
    "J4800",
    "LIBERTY",
    "NITRO",
    "PATRIOT",
    "PB100",
    "PB100 VAN",
    "PB150",
    "PB200 VAN",
    "PB250",
    "PB300",
    "PB300 VAN",
    "PB350",
    "PLYMOUTH 200 VAN",
    "PLYMOUTH TRUCK",
    "RENEGADE",
    "SCAMP",
    "SCRAMBLER",
    "STATION WAGON",
    "STER",
    "TJ",
    "TRAILDUSTER",
    "TRUCK",
    "VOYAGER",
    "WAGONEER",
    "WRANGLER",
  ],
  jensen: [
    "541R",
    "541S",
    "CV8",
    "CV8 MARK III",
    "FF",
    "FF MARK II",
    "FF MARK III",
    "HE",
    "HEALEY",
    "INTERCEPTOR",
    "INTERCEPTOR SP",
  ],
  kia: [
    "AMANTI",
    "BORREGO",
    "CADENZA",
    "CERATO",
    "FORTE",
    "FORTE KOUP",
    "FORTE5",
    "K2700",
    "K3000",
    "K3600",
    "K900",
    "MAGENTIS",
    "NIRO",
    "OPTIMA",
    "PICANTO",
    "PREGIO II",
    "RIO",
    "RIO5",
    "RONDO",
    "SEDONA",
    "SEPHIA",
    "SHUMA II",
    "SORENTO",
    "SOUL",
    "SOUL EV",
    "SPECTRA",
    "SPECTRA5",
    "SPORTAGE",
  ],
  lamborghini: ["DIABLO", "ESPADA", "GALLARDO", "MURCIELAGO"],
  lexus: [
    "CT200H",
    "ES250",
    "ES300",
    "ES300H",
    "ES330",
    "ES350",
    "GS F",
    "GS200T",
    "GS300",
    "GS350",
    "GS400",
    "GS430",
    "GS450H",
    "GS460",
    "GX460",
    "GX470",
    "HS250H",
    "IS F",
    "IS TURBO",
    "IS200T",
    "IS250",
    "IS300",
    "IS350",
    "LFA",
    "LS400",
    "LS430",
    "LS460",
    "LS600H",
    "LS600HL",
    "LX450",
    "LX470",
    "LX570",
    "NX200T",
    "NX300H",
    "RC F",
    "RC200T",
    "RC300",
    "RC350",
    "RX300",
    "RX330",
    "RX350",
    "RX400H",
    "RX450H",
    "SC300",
    "SC400",
    "SC430",
  ],
  lincoln: [
    "AVIATOR",
    "BLACKWOOD",
    "CONTINENTAL",
    "LS",
    "MARK III",
    "MARK IV",
    "MARK LT",
    "MARK V",
    "MARK VI",
    "MARK VII",
    "MARK VIII",
    "MKC",
    "MKS",
    "MKT",
    "MKX",
    "MKZ",
    "NAVIGATOR",
    "PREMIER",
    "SERIES",
    "TOWN CAR",
    "VERSAILLES",
    "ZEPHYR",
  ],
  lotus: [
    "ECLAT",
    "ELAN",
    "ELAN SPRINT",
    "ELISE",
    "ELITE",
    "ESPRIT",
    "EUROPA",
    "EVORA",
    "EXIGE",
  ],
  mahindra: ["APPALACHIAN"],
  maserati: [
    "228",
    "425I",
    "430",
    "BITURBO",
    "BORA",
    "COUPE CC",
    "COUPE GT",
    "GRANSPORT",
    "GRANTURISMO",
    "M128",
    "M138",
    "MISTRAL",
    "QUATTROPORTE",
    "QUATTROPORTE SPORT GT",
    "SPYDER",
    "SPYDER CC",
    "SPYDER GT",
  ],
  maybach: [
    "57",
    "62",
    "S500",
    "S550",
    "S600",
    "S63 AMG",
    "S65 AMG",
    "SL400",
    "SL500",
    "SL550",
    "SL63 AMG",
    "SL65 AMG",
  ],
  mazda: [
    "1200",
    "121",
    "1800",
    "2",
    "3",
    "3 SPORT",
    "323",
    "5",
    "6",
    "626",
    "808",
    "929",
    "ASTINA",
    "B-SERIES",
    "COSMO",
    "CX5",
    "CX7",
    "CX9",
    "ETUDE",
    "GLC",
    "MIATA",
    "MILLENIA",
    "MPV",
    "MX3",
    "MX5 MIATA",
    "MX6",
    "NAVAJO",
    "PROTEGE",
    "PROTEGE5",
    "R100",
    "ROTARY PICKUP",
    "RX2",
    "RX3",
    "RX4",
    "RX7",
    "RX8",
    "TRIBUTE",
  ],
  "mercedes-benz": [
    "190D",
    "190E",
    "190SL",
    "200",
    "200D",
    "220",
    "220D",
    "230",
    "230E",
    "230SL",
    "230SLK",
    "240D",
    "250",
    "250 GT",
    "250 LM",
    "250C",
    "250S",
    "250SE",
    "250SL",
    "260E",
    "280",
    "280C",
    "280CE",
    "280E",
    "280S",
    "280SE",
    "280SEC",
    "280SEL",
    "280SL",
    "280TE",
    "300CD",
    "300CE",
    "300D",
    "300E",
    "300SD",
    "300SDL",
    "300SE",
    "300SEL",
    "300SL",
    "300TD",
    "300TE",
    "350SD",
    "350SDL",
    "350SE",
    "350SL",
    "350SLC",
    "380SE",
    "380SEC",
    "380SEL",
    "380SL",
    "380SLC",
    "400-CLASS",
    "500E",
    "500SE",
    "500SEC",
    "500SEL",
    "500SL",
    "560SEC",
    "560SEL",
    "560SL",
    "600",
    "600SEC",
    "600SEL",
    "600SL",
    "A160",
    "A170",
    "B200",
    "B250",
    "B250E",
    "C220",
    "C230",
    "C240",
    "C250",
    "C250D",
    "C280",
    "C300",
    "C32",
    "C320",
    "C350",
    "C350E",
    "C36",
    "C400",
    "C43",
    "C450",
    "C55",
    "C63",
    "CL500",
    "CL55",
    "CL550",
    "CL600",
    "CL63",
    "CL65",
    "CLA250",
    "CLA45",
    "CLK320",
    "CLK350",
    "CLK430",
    "CLK500",
    "CLK55",
    "CLK550",
    "CLK63",
    "CLS400",
    "CLS500",
    "CLS55",
    "CLS550",
    "CLS63",
    "CLS63S",
    "E250",
    "E250D",
    "E280",
    "E300",
    "E300D",
    "E320",
    "E320CONV",
    "E320CPE",
    "E320T",
    "E320T SW",
    "E350",
    "E400",
    "E420",
    "E43",
    "E430",
    "E500",
    "E55",
    "E550",
    "E63",
    "E63S",
    "G500",
    "G55",
    "G550",
    "G63",
    "G65",
    "GL320",
    "GL350",
    "GL450",
    "GL550",
    "GL63",
    "GLA250",
    "GLA45",
    "GLAH",
    "GLK250",
    "GLK350",
    "M-CLASS",
    "MERCEDES 190",
    "MERCEDES 220",
    "MERCEDES 220S",
    "MERCEDES 220SEB",
    "MERCEDES 230/4",
    "MERCEDES 230/6",
    "MERCEDES 240D",
    "MERCEDES 280",
    "MERCEDES 350SL",
    "MERCEDES 450",
    "MERCEDES 600",
    "MERCEDES GLCCLASS",
    "MERCEDES GLECLASS",
    "MERCEDES GLSCLASS",
    "MERCEDES GT",
    "MERCEDES SLCCLASS",
    "MERCEDES SLS",
    "MERCEDES SPRINTER 2500",
    "MERCEDES SPRINTER 3500",
    "METRIS",
    "R320",
    "R350",
    "R500",
    "R63 AMG",
    "S320",
    "S350",
    "S350D",
    "S400",
    "S420",
    "S430",
    "S450 CDN",
    "S500",
    "S500CPE",
    "S500SEL",
    "S55",
    "S550",
    "S550E",
    "S600",
    "S600CPE",
    "S600SEL",
    "S63",
    "S65",
    "S80SE",
    "SL320",
    "SL400",
    "SL500",
    "SL55",
    "SL550",
    "SL600",
    "SL63",
    "SL65",
    "SLK230",
    "SLK250",
    "SLK280",
    "SLK300",
    "SLK32",
    "SLK320",
    "SLK350",
    "SLK55",
    "SLR MCLAREN",
    "SLS AMG",
    "SPRINTER",
    "VITO",
  ],
  mercury: [
    "BOBCAT",
    "BREEZEWAY",
    "CALIENTE",
    "CAPRI",
    "COLONY PARK",
    "COMET",
    "COMMUTER",
    "COUGAR",
    "CYCLONE",
    "EDSEL",
    "GRAND MARQUIS",
    "LEMOYNE",
    "LN7",
    "LYNX",
    "MARAUDER",
    "MARINER",
    "MARQUIS",
    "METEOR",
    "METEOR MONTCALM",
    "METEOR RIDEAU",
    "METEOR S33",
    "MILAN",
    "MONARCH",
    "MONTCLAIR",
    "MONTEGO",
    "MONTEREY",
    "MOUNTAINEER",
    "MYSTIQUE",
    "PARK LANE",
    "SABLE",
    "TOPAZ",
    "TRACER",
    "VILLAGER",
    "VOYAGER",
    "ZEPHYR",
  ],
  merkur: [
    "BANTAM",
    "COURIER",
    "FAIRMONT",
    "FALCON",
    "FIESTA",
    "IKON",
    "KA",
    "LASER",
    "METEOR",
    "MONDEO",
    "SCORPIO",
    "SIERRA",
    "TELSTAR",
    "XR4TI",
  ],
  mini: ["CLUBMAN", "COOPER", "COOPER COUNTRYMAN", "COOPER PACEMAN", "PACEMAN"],
  mitsubishi: [
    "3000GT",
    "COLT",
    "CORDIA",
    "DELICA",
    "DELICA D5",
    "DELICA SPACE",
    "DELICA STAR",
    "DIAMANTE",
    "ECLIPSE",
    "ENDEAVOR",
    "EXPO",
    "EXPO LRV",
    "GALANT",
    "LANCER",
    "MIGHTY MAX",
    "MIRAGE",
    "MONTERO",
    "MONTERO SPORT",
    "OUTLANDER",
    "OUTLANDER SPORT",
    "PAJERO",
    "PRECIS",
    "RAIDER",
    "RVR",
    "SIGMA",
    "STARION",
    "TREDIA",
    "VAN",
  ],
  morgan: ["PLUS FOUR"],
  nissan: [
    "1000",
    "1200",
    "200SX",
    "210",
    "240SX",
    "240Z",
    "260Z",
    "280Z",
    "280ZX",
    "300ZX",
    "310",
    "311",
    "350Z",
    "370Z",
    "410",
    "411",
    "510",
    "610",
    "620",
    "620 PICKUP",
    "710",
    "720",
    "810",
    "ALMERA",
    "ALTIMA",
    "ARMADA",
    "AXXESS",
    "B210",
    "CUBE",
    "D21",
    "F10",
    "FRONTIER",
    "GC22 VAN",
    "GTR",
    "HARDBODY",
    "HEAVYDUTY TRUCK",
    "JUKE",
    "LEAF",
    "MAXIMA",
    "MICRA",
    "MICRA K12",
    "MULTI",
    "MURANO",
    "NV1500",
    "NV200",
    "NV2500",
    "NV3500",
    "NX",
    "PATHFINDER",
    "PATHFINDER ARMADA",
    "PATROL",
    "PICKUP",
    "PULSAR",
    "PULSAR NX",
    "QUEST",
    "ROGUE",
    "ROGUE SELECT",
    "SCARGO",
    "SENTRA",
    "SKYLINE R32",
    "SKYLINE R33",
    "SKYLINE R34",
    "STANZA",
    "TERRANO II",
    "TITAN",
    "TITAN XD",
    "TSURU",
    "VAN",
    "VERSA",
    "VERSA NOTE",
    "XTERRA",
    "XTRAIL",
  ],
  oldsmobile: [
    "442",
    "88",
    "98",
    "ACHIEVA",
    "ALERO",
    "AURORA",
    "BRAVADA",
    "CALAIS",
    "CIERA",
    "CUSTOM CRUISER",
    "CUTLASS",
    "CUTLASS CALAIS",
    "CUTLASS CIERA",
    "CUTLASS CRUISER",
    "CUTLASS SALON",
    "CUTLASS SUPREME",
    "CUTLASS TIARA",
    "DELMONT 88",
    "DELTA 88",
    "DYNAMIC",
    "F85",
    "FIESTA",
    "FIRENZA",
    "INTRIGUE",
    "JETFIRE",
    "JETSTAR 88",
    "JETSTAR I",
    "LSS",
    "OMEGA",
    "REGENCY",
    "SILHOUETTE",
    "STARFIRE",
    "SUPER 88",
    "TORONADO",
    "VISTA CRUISER",
  ],
  opel: [
    "1900",
    "ASTRA",
    "ASTRA F",
    "ASTRA G",
    "ASTRA GTC",
    "ASTRA H",
    "CORSA B",
    "CORSA C",
    "CORSA HATCHBACK",
    "GT",
    "GT AMERICA",
    "GT STRADA 5300",
    "GT VELOCE",
    "INSIGNIA",
    "KADETT",
    "MANTA",
    "MERIVA",
    "MONZA",
    "OMEGA B",
    "OPEL",
    "REKORD",
    "SENATOR",
    "TIGRA",
    "TIGRA TT",
    "ZAFIRA A",
    "ZAFIRA B",
  ],
  peugeot: [
    "1007",
    "206",
    "304",
    "307",
    "404",
    "405",
    "406",
    "407",
    "504",
    "505",
    "604",
    "605",
    "607",
    "807",
    "BOXER VAN",
    "PARTNER",
  ],
  plymouth: [
    "ACCLAIM",
    "BARRACUDA",
    "BELVEDERE",
    "BELVEDERE II",
    "BREEZE",
    "CARAVELLE",
    "CUDA",
    "CUSTOM",
    "DUSTER",
    "FLEET SPECIAL",
    "FURY",
    "FURY I",
    "FURY II",
    "FURY III",
    "GRAN FURY",
    "GTX",
    "HORIZON",
    "LASER",
    "NEON",
    "PROWLER",
    "RELIANT",
    "ROADRUNNER",
    "SATELLITE",
    "SAVOY",
    "SCAMP",
    "SPORT WAGON",
    "SUBURBAN",
    "SUNDANCE",
    "TURISMO",
    "VALIANT",
    "VOLARE",
  ],
  pontiac: [
    "6000",
    "ACADIAN",
    "ASTRE",
    "AZTEK",
    "BONNEVILLE",
    "CATALINA",
    "CHIEF",
    "EXECUTIVE",
    "FIERO",
    "FIREBIRD",
    "FIREFLY",
    "G3",
    "G3 WAVE",
    "G5",
    "G6",
    "G8",
    "GRAND AM",
    "GRAND LEMANS",
    "GRAND PRIX",
    "GRAND SAFARI",
    "GRANDVILLE",
    "GTO",
    "J2000",
    "J2000 SUNBIRD",
    "LAURENTIAN",
    "LE MANS",
    "LEMANS",
    "MONTANA",
    "PARISIENNE",
    "PHOENIX",
    "PURSUIT",
    "SAFARI",
    "SOLSTICE",
    "STAR CHIEF",
    "SUNBIRD",
    "SUNBURST",
    "SUNFIRE",
    "SUNRUNNER",
    "T1000",
    "TEMPEST",
    "TORRENT",
    "TRANS SPORT",
    "VENTURA II",
    "VIBE",
    "WAVE",
  ],
  porsche: [
    "356",
    "356B",
    "911",
    "912",
    "914",
    "918",
    "924",
    "928",
    "930",
    "944",
    "968",
    "BOXSTER",
    "CARRERA",
    "CARRERA GT",
    "CAYENNE",
    "CAYMAN",
    "MACAN",
    "PANAMERA",
  ],
  renault: [
    "1310",
    "1410",
    "4CV",
    "A310",
    "ALLIANCE",
    "CLIO II",
    "CLIO III",
    "DACIA",
    "DAUPHINE",
    "ENCORE",
    "ESPACE",
    "ESPACE IV",
    "FUEGO",
    "KANGOO",
    "LAGUNA",
    "LECAR",
    "MEDALLION",
    "MEGANE I",
    "MEGANE II",
    "MODUS",
    "PLATINA",
    "R10",
    "R12",
    "R15",
    "R16",
    "R17",
    "R18",
    "R18I",
    "R5",
    "R5/LE CAR",
    "R8",
    "SCENI II",
    "SCENIC",
    "VEL SATIS",
  ],
  "rolls-royce": [
    "CAMARGUE",
    "CORNICHE",
    "CORNICHE II",
    "CORNICHE IV",
    "CORNICHE S",
    "GHOST",
    "PHANTOM",
    "PHANTOM V",
    "ROLLS ROYCE",
    "SERAPH",
    "SILVER CLOUD",
    "SILVER DAWN",
    "SILVER SHADOW",
    "SILVER SPIRIT",
    "SILVER SPUR",
    "SILVER WRAITH",
  ],
  rootes: ["SUNBEAM"],
  rover: [
    "3500",
    "827",
    "DEFENDER 110",
    "DEFENDER 90",
    "DISCOVERY",
    "DISCOVERY SPORT",
    "FREELANDER",
    "LAND",
    "LR2",
    "LR3",
    "LR4",
    "RANGE",
    "RANGE  EVOQUE",
    "RANGE  SPORT",
    "STERLING",
  ],
  saab: [
    "9-00",
    "9-000",
    "9-2X",
    "9-3",
    "9-3F",
    "9-3X",
    "9-4X",
    "9-5",
    "9-6",
    "9-6 GT",
    "9-6 SPORT",
    "9-7X",
    "9-9",
    "GT750",
    "SONETT",
  ],
  saturn: [
    "ASTRA",
    "AURA",
    "EV1",
    "ION",
    "L100",
    "L300",
    "LS",
    "LS1",
    "LS2",
    "LW2",
    "LW200",
    "LW300",
    "OUTLOOK",
    "RELAY",
    "S-SERIES",
    "SKY",
    "VUE",
  ],
  scion: ["FRS", "IA", "IM", "IQ", "TC", "XA", "XB", "XD"],
  simca: ["1000", "1000 MB", "1000 SERIES", "1118", "1204", "ETOILE"],
  skoda: ["105", "120", "FAVORIT", "RAPID"],
  smart: ["CABRIOLET", "FORFOUR", "FORTWO", "ROADSTER COUPE", "SMART"],
  studebaker: ["AVANTI", "CHAMP", "COMMANDER", "II", "LARK", "PICKUP"],
  subaru: [
    "B9 TRIBECA",
    "BAJA",
    "BRAT",
    "BRZ",
    "DL",
    "FE",
    "FORESTER",
    "GF",
    "GL",
    "GL10",
    "GLF",
    "IMPREZA",
    "JUSTY",
    "LEGACY",
    "LOYALE",
    "OUTBACK",
    "PASS.",
    "RX",
    "STANDARD",
    "STREEGA",
    "SVX",
    "TRIBECA",
    "WRX",
    "WRX STI",
    "XT",
    "XV CROSSTREK",
  ],
  sunbeam: ["ALPINE"],
  suzuki: [
    "AERIO",
    "CARRY",
    "EQUATOR",
    "ESTEEM",
    "FORENZA",
    "FORSA",
    "GRAND VITARA",
    "JIMNY",
    "KIZASHI",
    "LJ80",
    "RENO",
    "SAMURAI",
    "SIDEKICK",
    "SJ410",
    "SWIFT",
    "SX4",
    "SX4 CROSSOVER",
    "VERONA",
    "VITARA",
    "X90",
    "XL7",
  ],
  toyota: [
    "4RUNNER",
    "86",
    "AVALON",
    "CAMRY",
    "CARINA",
    "CELICA",
    "CHASER",
    "CONDOR",
    "COROLLA",
    "COROLLA IM",
    "COROLLA RUNX",
    "CORONA",
    "CORONA MARK II",
    "CRESSIDA",
    "CROWN",
    "ECHO",
    "FJ CRUISER",
    "FX",
    "HIACE",
    "HIGHLANDER",
    "HILUX",
    "LAND CRUISER",
    "MARK II",
    "MASTER ACE",
    "MATRIX",
    "MIRAI",
    "MR2",
    "MR2 SPYDER",
    "PASEO",
    "PICKUP",
    "PRADO",
    "PREVIA",
    "PRIUS",
    "PRIUS C",
    "PRIUS PLUGIN",
    "PRIUS V",
    "RAV4",
    "RUSH",
    "SEQUOIA",
    "SERA",
    "SIENNA",
    "SOARER",
    "SOLARA",
    "STARLET",
    "STOUT",
    "SUPRA",
    "SURF",
    "T100",
    "TACOMA",
    "TERCEL",
    "TOWN ACE",
    "TUNDRA",
    "VAN",
    "VAN WAGON",
    "VENTURE",
    "VENZA",
    "YARIS",
    "YARIS IA",
  ],
  triumph: [
    "GT6",
    "HERALD",
    "SPITFIRE",
    "STAG",
    "TR250",
    "TR3",
    "TR4",
    "TR4A",
    "TR6",
    "TR7",
    "TR8",
  ],
  tvr: ["280I", "350I", "CHIMAERA", "GRIFFITH", "S4C", "V8S"],
  volkswagen: [
    "411",
    "412",
    "BEETLE",
    "CABRIO",
    "CABRIOLET",
    "CAMPMOBILE",
    "CC",
    "CORRADO",
    "DASHER",
    "EOS",
    "EUROVAN",
    "FASTBACK",
    "FOX",
    "GOLF",
    "GOLF CITY",
    "GOLF GTI",
    "GOLF R",
    "GTI",
    "JETTA",
    "JETTA CITY",
    "JETTA GLI",
    "KARMANN GHIA",
    "PASSAT",
    "PASSAT CC",
    "PHAETON",
    "QUANTUM",
    "R32",
    "RABBIT",
    "RABBIT CONVERTIBLE",
    "RABBIT PICKUP",
    "ROUTAN",
    "SCIROCCO",
    "SQUAREBACK",
    "SUPER BEETLE",
    "THING",
    "TIGUAN",
    "TOUAREG",
    "TRANSPORTER",
    "TYPE 3",
    "VANAGON",
  ],
  volvo: [
    "122",
    "144",
    "145",
    "164",
    "1800",
    "240",
    "242",
    "244",
    "245",
    "260 SERIES",
    "262",
    "264",
    "265",
    "444",
    "445",
    "480",
    "544",
    "70 SERIES",
    "740",
    "760",
    "780",
    "80 SERIES",
    "850",
    "940",
    "960",
    "C30",
    "C70",
    "S40",
    "S60",
    "S70",
    "S80",
    "S90",
    "V40",
    "V50",
    "V60",
    "V60 CROSS COUNTRY",
    "V70",
    "V90",
    "XC60",
    "XC70",
    "XC90",
  ],
};

export { models as models };
export { manufacturers as manufacturers };
