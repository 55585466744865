/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface sendEmployeeMessageArguments {
  consumer_uid: string;
  recycler_business_uid: string;
  recycler_location_uid: string;
  conversation_uid: string;
  message_type: 1 | 2;
  message_content: string;
  file_name?: string;
}

function sendEmployeeMessage({
  recycler_business_uid,
  recycler_location_uid,
  consumer_uid,
  conversation_uid,
  message_type,
  message_content,
  file_name,
}: sendEmployeeMessageArguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      recycler_uid: recycler_business_uid,
      recycler_location_uid: recycler_location_uid,
      consumer_contact_uid: consumer_uid,
      conversation_uid: conversation_uid,
      p1s5e1_sender_uid: consumer_uid,
      p1s5e1_message_type: message_type,
      p1s5e1_content: message_content,
      ...(file_name && { p1s5e1_file_name: file_name }),
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_email: window.G_firebase_auth.IV_email_address,
      firebase_token: window.G_firebase_auth.IV_id_token,
    };

    ajax({
      url:
        window.G_cloud_p1s5_base_url +
        "p1s5e1ConsumerSendMessageToEmployee",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s5e1.ts",
          class_name: "p1s5e1",
          method_name: "sendEmployeeMessage",
          error_title: "Failed to send message",
          error_description:
            "The server responded with an error when trying to send a message for this conversation",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default sendEmployeeMessage;
