<template>
  <div class="mx-auto">
    <p1c1Header :customerTypeText="C_customerTypeText" />
    <div>
      <p1s1SaleVehicle 
        v-if="DV_customerType === 'sale'" 
        :P_customerType="DV_customerType" 
      />
      
      <p1s2PartAvailability 
        v-if="DV_customerType === 'part'" 
        :P_customerType="DV_customerType"
      />
      
      <c2LiveChatModule v-if="DV_customerType == 'chat'"/>
    </div>
  </div>
</template>

<script>
import p1s2PartAvailability from "../components/p1/p1s2PartAvailability.vue";
import p1s1SaleVehicle from "../components/p1/p1s1SaleVehicle.vue";
import p1c1Header from "../components/p1c1Header.vue";
import c2LiveChatModule from "../components/c2/c2LiveChatModule.vue";

export default {
  name: "p1HomeView",
  
  components: {
    p1c1Header,
    p1s1SaleVehicle,
    p1s2PartAvailability,
    c2LiveChatModule,
  },
  
  data() {
    return {
      DV_customerType: "",
      DV_locationName: "",
    };
  },
  
  mounted() {
    this.DV_locationName = window.G_bi2_data.IV_recycler_information.value.recycler_location_name;
    if (window.G_site_type === 2) {
      this.DV_customerType = this.$route.query.type || "part";
    }
    if (window.G_site_type === 1) {
      this.DV_customerType = "chat";
    }
  },

  computed: {
    C_customerTypeText() {
      if (this.DV_customerType === "sale") {
        return "Vehicle Buying Form";
      }
      if (this.DV_customerType === "part") {
        return "Part Availability Live Chat";
      }
      return "";
    }
  },
  
  methods: {
   //
  }
};
</script>
