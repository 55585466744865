import runFirst from "./run_first";
import VueAWN from "vue-awesome-notifications"

// Initial functionality before project loading.
runFirst();

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/base.less";
import "./assets/tailwind.css";

// vue-awesome-notifications configs
const options = {
  maxNotifications: 1 ,
  position: 'top-right'
};
Vue.use(VueAWN, options);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
