<template>
  <div class="container mx-auto">
    <c1PickVehicleStepForm :P_customerType="P_customerType" />

  </div>
</template>

<script>
import c1PickVehicleStepForm from "../c1/c1PickVehicleStepForm.vue";

export default {
  name: "p1s2PartAvailability",

  props: ["P_customerType"],

  components: { c1PickVehicleStepForm }
};
</script>
