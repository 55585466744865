/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

interface RequestEmailReceiptArguments {
  consumer_uid: string;
  recycler_business_uid: string;
  recycler_location_uid: string;
  conversation_uid: string;
  payment_link_uid: string;
  email: string;
  request_type: number;
}

function requestEmailReceipt({
  recycler_business_uid,
  recycler_location_uid,
  consumer_uid,
  conversation_uid,
  payment_link_uid,
  email,
  request_type,
}: RequestEmailReceiptArguments) {
  return new Promise(function (resolve, reject) {
    let params = {
      recycler_uid: recycler_business_uid,
      recycler_location_uid: recycler_location_uid,
      consumer_contact_uid: consumer_uid,
      conversation_uid: conversation_uid,
      p1s5e7_payment_link_uid: payment_link_uid,
      p1s5e7_email_address: email,
      firebase_uid: window.G_firebase_auth.IV_uid,
      firebase_email: window.G_firebase_auth.IV_email_address,
      firebase_token: window.G_firebase_auth.IV_id_token,
      p1s5e7_request_type: request_type
    };

    ajax({
      url:
        window.G_cloud_p1s5_base_url + "p1s5e7RequestEmailReceipt",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s5e7.ts",
          class_name: "p1s5e7",
          method_name: "requestEmailReceipt",
          error_title: "Failed to send message",
          error_description:
            "The server responded with an error when trying to send a receipt.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default requestEmailReceipt;
