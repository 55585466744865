import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
  Auth,
  User,
  NextOrObserver,
} from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { bi2DataInteractions } from "./bi2DataInteractions";

interface LoginPageCallbacks {
  signing_in: Function | null;
  signed_in: Function | null;
  sign_in_failed: Function | null;
  passwordless_email_sent: Function | null;
  passwordless_email_failed: Function | null;
  email_valid: Function | null;
  email_invalid: Function | null;
  password_valid: Function | null;
  password_invalid: Function | null;
  create_user_failed: Function | null;
  password_reset_sent: Function | null;
  password_reset_failed: Function | null;
}

class FirebaseAuth {
  IV_email_address: string;
  IV_first_name: string;
  IV_last_name: string;
  Iv_email_verified: boolean;
  iv_photo_url: string;
  IV_uid: string;
  IV_is_guest: boolean;
  IV_id_token: string;
  IV_token_received: boolean;
  IV_is_user_logged_in: boolean;
  IV_signout_requested_flag: boolean;
  IV_initialized: boolean;
  IV_firebase_app: FirebaseApp;
  IV_firestore_object: Firestore;
  IV_firestore: any;
  IV_auth: Auth;
  IV_login_page_callbacks: LoginPageCallbacks;
  IV_signed_in_global_callback: Function | null;

  constructor() {
    this.IV_email_address = "";
    this.IV_first_name = "";
    this.IV_last_name = "";
    this.Iv_email_verified = false;
    this.iv_photo_url = "";
    this.IV_uid = "";
    this.IV_is_guest = false;
    this.IV_id_token = "";
    this.IV_token_received = false;
    this.IV_is_user_logged_in = false;
    this.IV_signout_requested_flag = false;
    this.IV_initialized = false;
    this.IV_signed_in_global_callback = null;
    this.IV_login_page_callbacks = {
      signing_in: null,
      signed_in: null,
      sign_in_failed: null,
      passwordless_email_sent: null,
      passwordless_email_failed: null,
      email_valid: null,
      email_invalid: null,
      password_valid: null,
      password_invalid: null,
      create_user_failed: null,
      password_reset_sent: null,
      password_reset_failed: null,
    };


    if (window.G_dev_flag === true) {
      //development creds
      var firebaseConfig = {
        apiKey: "AIzaSyBSLV6iHlbiYPSDDpCOif9VmcLwyubjGwo",
        authDomain: "dev-synergy-auto-solutions.firebaseapp.com",
        projectId: "dev-synergy-auto-solutions",
        storageBucket: "dev-synergy-auto-solutions.appspot.com",
        messagingSenderId: "1023676156474",
        appId: "1:1023676156474:web:1f6749efb98b6e4e33c303"
      };
    } else {
      // production creds
      var firebaseConfig = {
        apiKey: "AIzaSyBASJzg9qD8ZAcvEXuttgv0JzmlsXPg_XI",
        authDomain: "synergy-auto-solutions.firebaseapp.com",
        projectId: "synergy-auto-solutions",
        storageBucket: "synergy-auto-solutions.appspot.com",
        messagingSenderId: "731673619628",
        appId: "1:731673619628:web:eca956ae425e53264a0309",
      };
    }
    

    this.IV_firebase_app = initializeApp(firebaseConfig);
    this.IV_auth = getAuth();
    this.IV_firestore_object = getFirestore(this.IV_firebase_app);
  }

  initFirebase() {
    if (this.IV_initialized === true) {
      console.log(
        "WARN: Attempt to call initFirebase after the instance has already been initialized"
      );
      return;
    }

    this.IV_auth.onAuthStateChanged(
      this.onAuthStateChanged.bind(this) as NextOrObserver<User>,
      this.onErrorAuthState
    );

    this.IV_initialized = true;
  }

  onAuthStateChanged(user: User) {
    const CI = this;

    if (user) {
      const email = user.email as string;

      CI.IV_is_user_logged_in = true;
      CI.IV_email_address = email;
      CI.IV_first_name = CI.getUserFirstName(user.displayName, email);
      CI.IV_last_name = CI.getUserLastName(user.displayName, email);
      CI.Iv_email_verified = false;
      CI.iv_photo_url = "";
      CI.IV_uid = user.uid;

      // call user signed in callback
      CI.callCallBackFunction(CI.IV_login_page_callbacks.signed_in, user);

      user
        .getIdToken(true)
        .then(
          function (accessToken: string) {
            CI.IV_id_token = accessToken;
            CI.IV_token_received = true;
          }.bind(CI)
        )
        .catch(
          function (error: any) {
            CI.IV_token_received = false;
          }.bind(CI)
        );

      //initialize Bi2

      return true;
    }

    this.IV_is_user_logged_in = false;
    this.IV_token_received = false;
    this.IV_email_address = "";
    this.IV_first_name = "";
    this.IV_last_name = "";
    this.Iv_email_verified = false;
    this.iv_photo_url = "";
    this.IV_uid = "";
  }

  getUserFirstName(name: string | null, email: string) {
    if (name) {
      return name.split(" ")[0];
    }

    return email.split("@")[0];
  }

  getUserLastName(name: string | null, email: string) {
    if (name) {
      const name_parts = name.split(" ");
      return name_parts[name_parts.length - 1];
    }

    return email.split("@")[0];
  }

  onErrorAuthState(error: Error) {
    console.log(error);
  }

  setLoginPageCallbacks(
    signing_in = null,
    signed_in = null,
    sign_in_failed = null,
    passwordless_email_sent = null,
    passwordless_email_failed = null,
    email_valid = null,
    email_invalid = null,
    password_valid = null,
    password_invalid = null,
    create_user_failed = null,
    password_reset_sent = null,
    password_reset_failed = null
  ) {
    const callback_id_list = this.typedLoginPageCallbacksKeys(
      this.IV_login_page_callbacks
    );

    const callback_list = [
      signing_in,
      signed_in,
      sign_in_failed,
      passwordless_email_sent,
      passwordless_email_failed,
      email_valid,
      email_invalid,
      password_valid,
      password_invalid,
      create_user_failed,
      password_reset_sent,
      password_reset_failed,
    ];

    /// if a callback is set validate and store its reference, otherwise set that callback to null
    for (const callback in callback_list) {
      if (typeof callback_list[callback] === "function") {
        this.IV_login_page_callbacks[callback_id_list[callback]] =
          callback_list[callback];
      }
    }
    ///</end> if a callback is set validate and store its reference, otherwise set that callback to null
  }

  setSignedInGlobalCallback(callback: Function | null) {
    this.IV_signed_in_global_callback = callback;
    return;
  }

  typedLoginPageCallbacksKeys<LoginPageCallbacks>(
    o: LoginPageCallbacks
  ): (keyof LoginPageCallbacks)[] {
    return Object.keys(o) as (keyof LoginPageCallbacks)[];
  }

  createUserAccount(email = "", password = "") {
    const CI = this;

    localStorage.removeItem("firebase_token");
    localStorage.removeItem("firebase_token_expiration");

    createUserWithEmailAndPassword(CI.IV_auth, email, password).catch(function (
      error = null
    ) {
      CI.callCallBackFunction(
        CI.IV_login_page_callbacks.create_user_failed,
        error
      );
      return;
    });
  }

  callCallBackFunction(callback: Function | null, args: any = []) {
    if (!callback) {
      return;
    }

    try {
      if (Array.isArray(args)) {
        callback(args);
      } else {
        callback([args]);
      }
    } catch (err) {
      return;
    }

    return;
  }

  emailPasswordLogin(email = "", password = "") {
    const CI = this;

    signInWithEmailAndPassword(CI.IV_auth, email, password).catch(function (
      error = null
    ) {
      CI.callCallBackFunction(CI.IV_login_page_callbacks.sign_in_failed, error);
      return;
    });
  }

  signOut() {
    const CI = this;

    localStorage.removeItem("firebase_token");
    localStorage.removeItem("firebase_token_expiration");

    CI.IV_signout_requested_flag = true;
    window.G_bi2_data = null;
    signOut(CI.IV_auth).then(
      function () {
        location.assign(window.location.origin + `/login`);
      },
      function (error) {
        alert("Error while signing out.");
      }
    );
  }
}

export default FirebaseAuth;
