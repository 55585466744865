<template>
  <div class="mt-4">
    <div v-if="DV_activeView == 'default'">Click on an item to pay for it</div>

    <div v-if="DV_activeView == 'default'" class="flex flex-col">
      <div style="height: 67vh;" class="text-left border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll">
        <div 
          v-for="item in DV_paymentLinks" 
          :key="item.payment_link_uid" 
          class="border border-gray-400 shadow-sm rounded-sm p-2 mb-1"
          @click.stop="DV_activePaymentLink = item"
        >
          <span><strong>{{item.payment_link_description}}</strong> @ ${{item.amount.toFixed(2)}}</span>
          <br/>
          <span><i>{{getPaymentStatus(item)}}</i></span>
          
          <button 
            v-if="DV_activePaymentLink.payment_link_uid == item.payment_link_uid" 
            class="mt-2 btn btn-lime text-sm flex items-center justify-center w-8/12 m-auto"
            @click.stop="openPaymentPage(item)"
          >
            Open Payment Page
          </button>
        </div>
      </div>
      <div class="mx-2">
        <button
          @click.stop="$emit('show-live-chat')"
          class="btn btn-lime text-sm flex items-center justify-center w-full"
        >
          Return to live chat
        </button>
      </div>
    </div> 

    <c2c1c1PaymentLinkPage
      v-if="DV_activeView == 'payment-page'"
      :P_paymentLink="DV_activePaymentLink"
      :P_retryFormData="DV_retryFormData"
      @event-back="DV_activeView = 'default'"
      @event-payment-failed="eventPaymentFailed"
      @event-payment-success="eventPaymentSuccess"
    ></c2c1c1PaymentLinkPage>

    <c2c1c2PaymentLinkReceipt
      v-if="DV_activeView == 'payment-receipt'"
      :P_paymentLink="DV_activePaymentLink"
      @event-back="DV_activeView = 'default'"
    ></c2c1c2PaymentLinkReceipt>

    <c2c1c3PaymentLinkProgressPage
      v-if="DV_activeView == 'payment-progress'"
      :P_paymentLink="DV_activePaymentLink"
      @event-back="DV_activeView = 'default'"
    ></c2c1c3PaymentLinkProgressPage>

     <c2c1c4PaymentLinkFailedPage
      v-if="DV_activeView == 'payment-failed'"
      :P_paymentLink="DV_activePaymentLink"
      @event-back="DV_activeView = 'default'"
      @event-try-payment-again="eventTryAgainPayment"
      @event-try-different-payment="eventtTryAgainDifferentPayment"
    ></c2c1c4PaymentLinkFailedPage>
  </div>

</template>

<script>
import c2c1c1PaymentLinkPage from "./c2c1c1PaymentLinkPage"
import c2c1c2PaymentLinkReceipt from "./c2c1c2PaymentLinkReceipt"
import c2c1c3PaymentLinkProgressPage from "./c2c1c3PaymentLinkProgressPage"
import c2c1c4PaymentLinkFailedPage from "./c2c1c4PaymentLinkFailedPage"
import AWN from "awesome-notifications";

export default {
  name: "c2c1PaymentLinks",

  components: { c2c1c1PaymentLinkPage, c2c1c2PaymentLinkReceipt, c2c1c3PaymentLinkProgressPage, c2c1c4PaymentLinkFailedPage },

  props: ['P_activePaymentLinkUid'],

  data() {
    return {
      DV_activeView: "default",
      DV_activePaymentLink: {},
      DV_paymentLinks: {},
      DV_retryFormData: {}
    }
  },

  mounted() {
    // 
    this.DV_paymentLinks = window.G_bi2_data.IV_consumer_conversation_payment_links.value
    // Direct payment link
    if (this.P_activePaymentLinkUid && this.P_activePaymentLinkUid in this.DV_paymentLinks === true) {
      this.DV_activePaymentLink = this.DV_paymentLinks[this.P_activePaymentLinkUid];
      this.openPaymentPage(this.DV_paymentLinks[this.P_activePaymentLinkUid]);
    }
  },

  methods: {
    openPaymentPage(payment_link) {
      if (payment_link.payment_status == "no_status") {
        this.DV_activeView = 'payment-page';
        return;
      }

      if (payment_link.payment_status == "paid") {
        this.DV_activeView = 'payment-receipt';
        return;
      }

      if (payment_link.payment_status == "failed") {
        this.DV_activeView = 'payment-failed';
        return;
      }
      
      if (payment_link.payment_status == "partial_refund") {
        this.DV_activeView = 'payment-receipt';
        return;
      }
      
      if (payment_link.payment_status == "refunded") {
        this.DV_activeView = 'payment-receipt';
        return;
      }

      this.DV_activeView = 'payment-progress';
    },

    getPaymentStatus(payment_link) {
      if (payment_link.payment_status == "failed") {
        return "Failed";
      }

      if (payment_link.payment_status == "paid") {
        return "Paid";
      }

      if (payment_link.payment_status == "in_progress") {
        return "In Process";
      }

      if (payment_link.payment_status == "partial_refund") {
        return `Partial Refund @ $${payment_link.refunded_amount.toFixed(2)}`;
      }

      if (payment_link.payment_status == "refunded") {
        return "Refunded";
      }

      return "Pending";
    },

    eventTryAgainPayment() {
      const CI = this;

      if (!CI.DV_activePaymentLink || !CI.DV_activePaymentLink.payment_link_uid) {
        this.DV_activeView = 'payment-page';
        return;
      }

      if (CI.DV_activePaymentLink.payment_link_uid in window.G_bi2_data.IV_payment_links_info.value == false) {
        this.DV_activeView = 'payment-page';
        return;
      }

      for (let payment_info_uid in window.G_bi2_data.IV_payment_links_info.value[CI.DV_activePaymentLink.payment_link_uid]) {
        const paymentInfo = window.G_bi2_data.IV_payment_links_info.value[CI.DV_activePaymentLink.payment_link_uid][payment_info_uid] || {};

        CI.DV_retryFormData.firstName = paymentInfo.first_name || null;
        CI.DV_retryFormData.lastName = paymentInfo.last_name || null;
        CI.DV_retryFormData.expMonth = paymentInfo.expiry_month || null;
        CI.DV_retryFormData.expYear = paymentInfo.expiry_year || null;
      }

      this.DV_activeView = 'payment-page';
    },

    eventtTryAgainDifferentPayment() {
      const CI = this;
      CI.DV_retryFormData = {};
      CI.DV_activeView = 'payment-page'
    },

    eventPaymentFailed(message="Failed to process transaction") {
      const CI = this;

      CI.DV_activeView = 'payment-failed';
      CI.$awn.alert(message)
    },

    eventPaymentSuccess(message="") {
      const CI = this;
      CI.$awn.success("Payment accepted. Thank you!")
      CI.DV_activeView = 'payment-receipt';
    }
  },

  computed: {
    // 
  }
}
</script>
