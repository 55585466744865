import { fs103ConversationStatuses } from "../firestore_schemas/fs103ConversationStatuses"
import { setDocIn_fs103ConversationStatuses } from "../firestore_schemas/fs103ConversationStatuses"
import { getDocFrom_fs103ConversationStatuses } from "../firestore_schemas/fs103ConversationStatuses"
import { setFieldIn_fs103ConversationStatusese } from "../firestore_schemas/fs103ConversationStatuses"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
};

export async function fs103Writer(
  w_type: string,
  fs1_uid: string,
  fs2_uid: string,
  fs3_uid: string,
  fs4_uid: string,
  fs6_uid: string,
  data: any
) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs103_writer:fs103Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_uid || !fs2_uid || !fs3_uid || !fs4_uid) {
    retval.success = false;
    retval.return_msg += "Insufficient arguments.";
    retval.error_title = "Insufficient arguments.";
    retval.error_summary = "uids not set.";
    retval.error_next_steps = "Please provide correct uids.";
    return retval;
  }
  /// <END> Validate arguments according to write type

  // Edit
  if (w_type == "e") {
    let response_data = await getDocFrom_fs103ConversationStatuses(
      fs1_uid,
      fs2_uid,
      fs3_uid,
      fs4_uid,
      fs6_uid
    )

    const fs103Data: fs103ConversationStatuses = {
      last_activity_time: "",
      employee_typing_status: "",
      consumer_typing_status: ""
    }

    if (response_data.success === true) {
      if (data.last_activity_time) {
        await setFieldIn_fs103ConversationStatusese(fs1_uid, fs2_uid, fs3_uid, fs4_uid, fs6_uid, "last_activity_time", data.last_activity_time);
      }

      if (data.employee_typing_status) {
        await setFieldIn_fs103ConversationStatusese(fs1_uid, fs2_uid, fs3_uid, fs4_uid, fs6_uid, "employee_typing_status", data.employee_typing_status);
      }

      if (data.consumer_typing_status) {
        await setFieldIn_fs103ConversationStatusese(fs1_uid, fs2_uid, fs3_uid, fs4_uid, fs6_uid, "consumer_typing_status", data.consumer_typing_status);
      }
      return;
    }

    // New document
    fs103Data.last_activity_time = data.last_activity_time || new Date(0);
    fs103Data.employee_typing_status = data.employee_typing_status || "not-typing";
    fs103Data.consumer_typing_status = data.consumer_typing_status || "not-typing";

    await setDocIn_fs103ConversationStatuses(
      fs1_uid,
      fs2_uid,
      fs3_uid,
      fs4_uid,
      fs6_uid,
      fs103Data
    )

    return retval
  }

}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
