<template>
  <div>
    <header class="container mx-auto">
      <div class="items-center flex flex-col sm:blok justify-center mt-4">
        <img
          class="recycler-header-logo sm:mb-2"
          style="max-height: 12vw;"
          :src="DV_logoUrl"
          alt=""
        />
        <span class="text-lg md:text-xl font-bold mt-2">{{ C_locationName }}</span>
        <span class="text-lg md:text-xl font-bold mt-2">{{ customerTypeText }} </span>
      </div>
    </header>
  </div>
</template>

<script>

export default {
  name: "p1c1Header",

  props: ["customerTypeText"],

  data() {
    return {
      DV_recyclerInfo: {},
      DV_logoUrl: "",
    };
  },
  
  mounted() {
    this.DV_recyclerInfo = window.G_bi2_data.IV_guest_consumer_information;
  },

  computed: {
    C_locationName() {
      try {
        return window.G_bi2_data.IV_recycler_information.value.recycler_location_name;
      } catch (error) {
        return ""
      }
    }
  },

  methods: {
    imageURL() {
      let date = new Date()
      let businessUid = window.G_bi2_data.IV_guest_consumer_information.recycler_business_uid || ""
      let locationUid = window.G_bi2_data.IV_guest_consumer_information.recycler_location_uid || "";
      const urlParams = new URLSearchParams(window.location.search);

      if ( (window.location !== window.parent.location)) {
        this.DV_logoUrl = urlParams.get('business-logo')
        return;
      }

      this.DV_logoUrl = `${window.G_recycler_logos_bucket}${businessUid}-${locationUid}?timestamp=${date}`
    }
  },

  watch: {
    DV_recyclerInfo: {
      handler() {
        this.imageURL();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.recycler-header-logo {
  display: block;
  max-width: 125px;
  max-height: 60px;
  width: auto;
  height: auto;
}
</style>