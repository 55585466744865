<template>
  <div>
    <div class="mx-2">
      <div class="py-3">
        <button @click="$emit('back-step')" class="btn btn-lime text-sm flex items-center justify-center mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 font-bold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
          </svg>

          Go Back To Previous Step
        </button>
      </div>


    </div>

    <div class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 h-[29svh] overflow-y-scroll">

      <!-- Interchange parts -->
      <ul v-if="P_interchangePartNumbersList && P_interchangePartNumbersList.length > 0" class="overflow-y-scroll">
        <li
          v-for="(part, i) in P_interchangePartNumbersList"
          :key="i"
          class="flex items-center justify-start bg-blue-50 border rounded mb-3"
        >
          <svg @click="$emit('event-remove-part', part)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600 mr-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
          <p class="font-medium">{{ part.value }}</p>
        </li>
      </ul>

      <!-- Vehicles -->
      <ul v-if="P_partsVehiclesList && P_partsVehiclesList.length > 0" class="overflow-y-scroll">
        <li
          v-for="(vehicle, i) in P_partsVehiclesList"
          :key="i"
          class="flex justify-start bg-blue-50 border rounded mb-3"
        >
          <svg @click="$emit('event-remove-vehicle', vehicle)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-red-600 mr-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
          </svg>
          <p class="font-medium text-left">{{ vehicle.manufacturer }} {{ vehicle.model }} {{ vehicle.year }} {{ vehicle.parts }} <br/>{{ vehicle.vehicle_parts_description }}</p>
        </li>
      </ul>
    </div>
    <h1 class="font-bold">
        Have you selected all the vehicles you need parts for?
      </h1>
    <div class="grid grid-cols-2 gap-2 m-2">
      <button
        @click="$emit('next-step')"
        class="btn btn-lime w-full h-full text-sm"
      >
        Yes - Connect With a Live Representative
      </button>
      <button
        @click="$emit('event-add-vehicle')"
        class="btn btn-lime w-full h-full text-sm"
      >
        Add Vehicle
      </button>
    </div>
    <div class="mx-2 mb-5">
      <button
        @click="$emit('goto-step', 'part-numbers')"
        class="btn btn-lime w-full"
      >
        Add Part Number
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "c1c6VehiclesYouNeedPart",

  props: ["P_interchangePartNumbersList", "P_partsVehiclesList"],

  data() {
    return {
      // 
    };
  },
};
</script>
