<template>
  <div>
    <button
      v-if="P_returnTextEnglish || P_returnTextSpanish"
      @click="$emit('back-step')"
      class="btn btn-lime text-sm flex items-center justify-center mx-auto mb-2"
    >
      {{ P_returnTextEnglish }}
      <br />
      {{ P_returnTextSpanish }}
    </button>

    <div v-if="$route.query.type && $route.query.type != 'chat'">
      <h1 class="font-bold text-sm">
        A live chat with our team will start shortly!
      </h1>
    </div>

    <!-- Loading section -->
    <div v-if="DV_checkingForDirectPaymentLink || DV_checkingForIdentityVerificationLink" class="mt-12">
      Loading...
    </div>

    <!-- Chat module -->
    <div v-if="DV_activeView == 'chat' && !DV_checkingForDirectPaymentLink && !DV_checkingForIdentityVerificationLink">
      <div v-if="!C_chatFinished && DV_identityVerificationLinksCount > 0" class="mx-2 my-2">
        <button
          @click.stop="DV_activeView = 'identity-verification'"
          class="btn btn-lime text-sm flex items-center justify-center w-full"
        >
          Open Identity Verification Request
        </button>
      </div>

      <div v-else-if="!C_chatFinished && DV_paymentLinksCount > 0" class="mx-2 my-2">
        <button
          @click.stop="DV_activeView = 'payments'"
          class="btn btn-lime text-sm flex items-center justify-center w-full"
        >
          Open Payment Requests ({{DV_paymentLinksCount}})
        </button>
      </div>

      <div class="mt-2 italic text-sm text-orange-500 mx-2">
        <span v-if="C_chatFinished && C_feedbackSubmitted">
          Chat has been finished, please contact support to resume this chat or click <a target="_self" class="text-blue-500" :href="C_newConversationLink">here</a> to start a new conversation. 
        </span>
        <span v-if="C_chatFinished && !C_feedbackSubmitted">
          Chat has been finished click <a @click.stop="DV_activeView = 'review'" class="text-blue-500" href="javascript:;">here</a> to submit feedback. You can start a new conversation from <a class="text-blue-500" :href="C_newConversationLink">here</a>.
        </span>
      </div>

      <div
        class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll flex"
        :class="[(!C_chatFinished && DV_paymentLinksCount > 0) ? 'h-[58dvh]' : 'h-[65dvh]']"
      >
        <div
          id="chat-message-container"
          class="w-full px-2 flex flex-col overflow-auto"
        >
          <div
            v-for="(message, index) in DV_activeConversationMessages"
            class="flex flex-col mt-2"
            v-bind:key="index"
            :class="{'not-sent-chat-message': message[DV_chatMessagesIndexes.timestamp].nanoseconds == 'not-sent'}"
          >
            <div
              :class="[message[DV_chatMessagesIndexes.message_sender] == 1 ? '' : 'flex-row-reverse']"
              class="flex justify-end mb-2"
            >
              <div
                :class="[message[DV_chatMessagesIndexes.message_sender] == 1 ? 'right' : 'left']"
                class="chat-message "
              >
                <span 
                v-if="message[DV_chatMessagesIndexes.message_type] == 1"
                v-html="message[DV_chatMessagesIndexes.message_content]">
                </span>
                
                <div
                  v-else-if="(message[DV_chatMessagesIndexes.file_type] == 1)" 
                   class="flex flex-col">
                    <img                   
                    style="max-height: 50vh;"
                    :src="message[DV_chatMessagesIndexes.file_url]"
                    >
                      <a 
                        class="text-blue-300 cursor-pointer"
                        @click="downloadMedia(message[DV_chatMessagesIndexes.file_url])"
                      >
                        <b>Download Image</b>
                      </a>
                </div>
                  <span 
                  class="text-lg"
                  v-else-if="(message[DV_chatMessagesIndexes.file_type] > 1)">
                  Uploaded File:
                  <a
                  @click="downloadMedia(message[DV_chatMessagesIndexes.file_url])"
                  class="text-blue-300 break-words w-3/4 cursor-pointer"> {{ message[DV_chatMessagesIndexes.file_name] }} <span v-if="getUploadedFileSize(message)">({{getUploadedFileSize(message)}})</span>
                </a></span>

                  <span v-else class="deleted-message">
                    Deleted message
                  </span>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!C_chatFinished" class="mb-2 mt-8 mr-2 ml-1 flex items-center relative">
        <!-- upload file -->
        <div :disabled="DV_uploadingImage" @click.stop="showImageUpload" class="mr-2">
          <img class="w-10 mb-[7px]" v-bind:src="require('../../assets/images/upload_file_icon.png')" />
        </div>

        <div class="w-full">
          <div v-show="C_isStaffMemberTyping" style="top: -20px;" class="absolute text-left text-sm">{{C_typingNotificationText}}</div>
          <textarea
            id="live-chat"
            rows="1"
            ref="messageTextInput"
            v-model="DV_outboundMessageText"
            class="border focus:outline-none p-2 border-gray-400 rounded-md shadow-sm w-full pr-10 hide-scrollbar"
            v-on:keydown.enter="sendMessageTypeText"
          ></textarea>
          <input id="image-upload-input" ref="messageImageInput" class="hidden" type="file" @change="handleMessageImageUpload" />
        </div>

        <button :disabled="DV_uploadingImage" @click.stop="sendMessageTypeText" class="btn-lime mx-1 mb-[7px] rounded-full absolute right-0 btn p-1">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
          </svg>
        </button>
      </div>
    </div>

    <c2c1PaymentLinks
      v-if="DV_activeView == 'payments'"
      :P_activePaymentLinkUid="DV_activePaymentLinkUid"
      @show-live-chat="DV_activeView = 'chat'"
    ></c2c1PaymentLinks>

    <c2c2ConsumerReview
      v-if="DV_activeView == 'review'"
      @show-live-chat="DV_activeView = 'chat'"
    ></c2c2ConsumerReview>

    <c2c3IdentityVerification
      v-if="DV_activeView == 'identity-verification'"
      @show-live-chat="DV_activeView = 'chat'"
    ></c2c3IdentityVerification>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import sendEmployeeMessage from "../../includes/server_calls/p1s5/p1s5e1";
import c2c1PaymentLinks from "./c2c1PaymentLinks.vue";
import c2c2ConsumerReview from "./c2c2ConsumerReview.vue";
import c2c3IdentityVerification from "./c2c3IdentityVerification.vue";
import { fs103Writer } from "../../includes/firestore_writers/fs103_writer.ts";
import _ from "lodash"
import AWN from "awesome-notifications";

export default {
  name: "c2LiveChatModule",

  props: ["P_returnTextEnglish", "P_returnTextSpanish"],

  components: { c2c1PaymentLinks, c2c2ConsumerReview, c2c3IdentityVerification },

  data() {
    return {
      DV_activeView: "chat",
      DV_activeConversationMessagesRef: window.G_bi2_data.IV_conversation_messages,
      DV_activeConversationMessages: [],
      DV_activePaymentLinkUid: null,
      DV_checkingForDirectPaymentLink: true,
      DV_checkingForIdentityVerificationLink: true,
      DV_chatMessagesIndexes: window.G_bi2_data.IV_al_conversation_messages,
      DV_outboundMessageText: "",
      DV_employeeNames: window.G_bi2_data.IV_employee_names,
      DV_uploadingImage: false,
      DV_defaultEmployeeName: window.G_bi2_data.IV_recycler_information.assigned_employee_name,
      DV_paymentLinksCount: 0,
      DV_paymentLinks: {},
      DV_identityVerificationLinks: {},
      DV_identityVerificationLinksCount: 0,
      DV_conversationInformation: window.G_bi2_data.IV_conversation_information.value,
      DV_currentTypingStatus: "not-typing",
      DV_lastTypingTime: new Date(0),
      DV_typingDebounceTimeInMillis: 1000,
      DV_employeeTypingStatusInfo: {},
      DV_employeeTypingStatus: "not-typing"
    };
  },

  mounted() {
    const CI = this;

    CI.startPaymentLinksListener();
    CI.updateMessagesList();
    CI.lazyUpdateActiveStatus("active");
    CI.scrollChatToBottom();
    CI.checkForDirectPaymentLink(0);
    CI.checkForIdentityVerificationLink(0);

    // Listen for every possible event to check user's interaction.
    const eventsList = ["click", "mouseover", "mousemove", "touchmove", "focus"];    
    eventsList.forEach((eventName)=>{
      window.addEventListener(eventName, () => {
        CI.lazyUpdateActiveStatus("active");
      }); 
    });

    CI.DV_paymentLinksCount = Object.keys(window.G_bi2_data.IV_consumer_conversation_payment_links.value).length;
    CI.DV_employeeTypingStatusInfo = window.G_bi2_data.IV_conversation_activity_status.value;

    setInterval(CI.lazyUpdateTypingStatus, 500);
  },

  computed: {
    C_chatFinished() {
      const CI = this;

      return CI.DV_conversationInformation && 
              CI.DV_conversationInformation.status && 
              CI.DV_conversationInformation.status.includes('z');
    },

    C_feedbackSubmitted() {
      const CI = this;

      return CI.DV_conversationInformation && 
              CI.DV_conversationInformation.status && 
              CI.DV_conversationInformation.status.includes('z') &&
              (CI.DV_conversationInformation.status.includes('g') || 
              CI.DV_conversationInformation.status.includes('h'))
    },
    
    C_newConversationLink() {
      return `${location.origin}?recycler_business_uid=${window.G_bi2_data.IV_recycler_information.value.recycler_business_uid}&recycler_location_uid=${window.G_bi2_data.IV_recycler_information.value.recycler_location_uid}&type=part`;
    },

    C_employeeName() {
      const CI = this;
        
      let empUid = window.G_bi2_data.IV_recycler_information.value.assigned_employee_uid;

      if (empUid && CI.DV_employeeNames && CI.DV_employeeNames && empUid in CI.DV_employeeNames === true) {
        return CI.DV_employeeNames[empUid];
      }

      return "Staff member";
    },

    C_typingNotificationText() {
      const CI = this;
      const bothTypingFlag = (CI.DV_currentTypingStatus == 'typing' && CI.C_isStaffMemberTyping);

      return `${bothTypingFlag ? 'You and ' : ''} ${CI.C_employeeName} ${bothTypingFlag ? 'are' : 'is'} typing...`;
    },

    C_isStaffMemberTyping() {
      const CI = this;
      return CI.DV_employeeTypingStatus === "typing"
    },

    C_isDirectVerificationURL() {
      return window.location.pathname.includes("/verify");
    },

    C_showWelcomeMessage() {
      return (window.G_bi2_data.IV_conversation_information.value.status == 'a')
    },

    C_businessName() {
      return window.G_bi2_data.IV_recycler_information.value.recycler_location_name
    }
  },

  methods: {
    startPaymentLinksListener(retry_count=0) {
      const CI = this;
      const uids = window.G_bi2_data.IV_conversation_uids.value;

      if (retry_count > 20) { return; }

      if (!uids.fs2_doc_id || !uids.fs4_doc_id || !uids.fs4_doc_id) {
        setTimeout(function() { CI.startPaymentLinksListener(++retry_count) }, 100);
        return;
      }

      window.G_bi2_data.startPaymentLinksListener(
        window.G_bi2_data,
        uids.fs4_doc_id,
        `${uids.fs1_doc_id}-${uids.fs2_doc_id}-${uids.fs4_doc_id}`,
        `${uids.fs1_doc_id}-${uids.fs2_doc_id}-${uids.fs6_doc_id}`
      )

      window.G_bi2_data.startConsumerIdentityVerificationDetailsListener(
        window.G_bi2_data,
        uids.fs4_doc_id,
        `${uids.fs1_doc_id}-${uids.fs2_doc_id}-${uids.fs4_doc_id}`,
        `${uids.fs1_doc_id}-${uids.fs2_doc_id}-${uids.fs6_doc_id}`
      )

      CI.DV_paymentLinksCount = Object.keys(window.G_bi2_data.IV_consumer_conversation_payment_links.value).length
      CI.DV_paymentLinks = window.G_bi2_data.IV_consumer_conversation_payment_links.value
      CI.DV_identityVerificationLinks = window.G_bi2_data.IV_consumer_identity_verification_links.value
      
      if (CI.DV_identityVerificationLinks) {
        CI.DV_identityVerificationLinksCount = _.filter(CI.DV_identityVerificationLinks, (item) => {return (item.status === "a" || item.status === "requires_input") }).length
      }
      CI.checkForIdentityVerificationLink(0);
      CI.checkForDirectPaymentLink(0);
    },

    checkForDirectPaymentLink(retry_count=0) {
      const CI = this;
      const urlParams = new URLSearchParams(window.location.search);
      CI.DV_activePaymentLinkUid = urlParams.get("p");

      // No direct payment link uid present.
      if (!CI.DV_activePaymentLinkUid) { 
        CI.DV_checkingForDirectPaymentLink = false;
        return; 
      }

      // Incorrect payment uid
      if (retry_count > 30) { 
        CI.DV_checkingForDirectPaymentLink = false;
        return; 
      }

      // Payment links not loaded yet
      if (CI.DV_activePaymentLinkUid in CI.DV_paymentLinks == false) {
        setTimeout(function() { CI.checkForDirectPaymentLink(++retry_count) }, 100);
        return;
      }

      // Everything good to go, 
      CI.DV_checkingForDirectPaymentLink = false;

      // If pending verification
      if (CI.DV_identityVerificationLinksCount > 0) {
        CI.DV_activeView = 'identity-verification';
      } else {
        CI.DV_activeView = 'payments';
      }
    },

    checkForIdentityVerificationLink(retry_count=0) {
      const CI = this;

      // Not direct verification link
      if (!CI.C_isDirectVerificationURL) { 
        CI.DV_checkingForIdentityVerificationLink = false;
        return; 
      }

      // Waited but no verification link available, open the 
      if (retry_count > 30) { 
        CI.DV_checkingForIdentityVerificationLink = false;
        CI.DV_activeView = 'chat';
        return; 
      }

      // Verification links not loaded yet
      if (Object.keys(CI.DV_identityVerificationLinks).length === 0) {
        setTimeout(function() { CI.checkForIdentityVerificationLink(++retry_count) }, 100);
        return;
      }

      // Everything good to go
      CI.DV_activeView = 'identity-verification';
      CI.DV_checkingForIdentityVerificationLink = false;
    },

    updateMessagesList() {
      const CI = this;

      CI.DV_activeConversationMessages = window.G_bi2_data.IV_conversation_messages;
      CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
      CI.$forceUpdate()
      setTimeout(CI.scrollChatToBottom, 1000);
    },

    lazyUpdateActiveStatus: _.debounce(function() {
      const CI = this;

      fs103Writer(
        "e",
        window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        window.G_bi2_data.IV_recycler_information.value.assigned_employee_uid,
        window.G_bi2_data.IV_conversation_information.value.consumer_uid,
        window.G_bi2_data.IV_conversation_information.value.conversation_uid,
        {
          last_activity_time: new Date()
        }
      )
    }, 2000),

    sendMessageTypeText() {
      const CI = this;

      if (CI.DV_outboundMessageText.length < 1) {
        return;
      }

      // Show the message immediately in unsent form
      var unSendMessageUid = new Date().getTime().toString(); // fake uid of message
      let unSendMessage = [
        1, // consumer send message
        1, // text message
        unSendMessageUid, // fake uid of message
        unSendMessageUid, // self employ uid,
        {
          nanoseconds: "not-sent", // fake nano seconds value to show un-sent status in chat
          seconds: Math.round(new Date().getTime()/1000)
        },
        CI.DV_outboundMessageText
      ]

      CI.DV_activeConversationMessages.push(unSendMessage);
      CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
      CI.$forceUpdate()
      setTimeout(CI.scrollChatToBottom, 50);
      // Show the message immediately in unsent form

      let params = {
        consumer_uid: CI.DV_conversationInformation.consumer_uid,
        recycler_business_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        conversation_uid: CI.DV_conversationInformation.conversation_uid,
        message_type: 1,
        message_content: CI.DV_outboundMessageText,
      };
      sendEmployeeMessage(params);

      CI.DV_outboundMessageText = "";
    },

    showImageUpload() {
      this.$refs.messageImageInput.click();
    },

    scrollChatToBottom() {
      const objDiv = document.getElementById("chat-message-container");
      if (!objDiv) {return;}
      objDiv.scroll({ top: objDiv.scrollHeight, behavior: 'smooth' })
    },

    handleMessageImageUpload(image) {
      const CI = this;

      if (!image.target.files || !image.target.files[0])
        return;

      if (image.target.files > 1) {
        CI.$awn.warning("Please choose only one file per message.");
        return;
      }

      // validate size
      var file = image.target.files[0];
      if (file.size > (30*(1024*1024))) {
        CI.$awn.warning("File size must be less than 30 megabytes.");
        return;
      }

      CI.DV_uploadingImage = true;

      // set data
      var reader = new FileReader();
      reader.onload = (obj) => {
        
        // Show the message immediately in unsent form
        var unSendMessageUid = new Date().getTime().toString(); // fake uid of message
        let unSendMessage = [
          1, // consumer send message
          1, // text message
          unSendMessageUid, // fake uid of message
          unSendMessageUid, // self employ uid,
          {
            nanoseconds: "not-sent", // fake nanoseconds value to show un-sent status in chat
            seconds: Math.round(new Date().getTime()/1000)
          },
          `Uploading ${CI.UploadingFileType(file)}`
        ]

        CI.DV_activeConversationMessages.push(unSendMessage);
        CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
        CI.$forceUpdate()
        setTimeout(CI.scrollChatToBottom, 500);
        // Show the message immediately in unsent form

        // Send actual message
        let params = {
          consumer_uid: CI.DV_conversationInformation.consumer_uid,
          recycler_business_uid: window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
          recycler_location_uid: window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
          conversation_uid: CI.DV_conversationInformation.conversation_uid,
          message_type: 2,
          file_name: file.name,
          message_content: obj.srcElement.result,
        };
        sendEmployeeMessage(params).then((resp) => {
          CI.DV_uploadingImage = false;
          setTimeout(CI.scrollChatToBottom, 1000);
        }).catch((error) => {
          // Remove the unsent message from the list. 
          let index = CI.DV_activeConversationMessages.findIndex(item => item[2] == unSendMessageUid)
          if (index > 0) {CI.DV_activeConversationMessages.splice(index, 1)}
          CI.DV_uploadingImage = false;          
          CI.$awn.alert(error.error_title)
        });;
      }

      reader.readAsDataURL(file)
    },

    UploadingFileType(file) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const acceptedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      const acceptedDocExtensions = ['.doc', '.docx', '.pdf'];
      const acceptedDocTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-word.document.macroEnabled.12', 'application/pdf'];
      const acceptedVideoTypes = [ 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/x-msvideo', 'video/x-flv', 'video/x-matroska', 'video/webm', 'video/x-ms-wmv'];
      const acceptedVideoExtensions = ['.mp4', '.mpeg', '.mov', '.avi', '.flv', '.mkv', '.webm', '.wmv'];

      const fileType = file.type.toLowerCase();;
      const fileName = file.name;
      const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();

      if (acceptedImageTypes.includes(fileType) || acceptedImageExtensions.includes(fileExtension)) {
        return "Image";
      }

      if (acceptedDocTypes.includes(fileType) || acceptedDocExtensions.includes(fileExtension)) {
        return "File";
      }

      if (acceptedVideoTypes.includes(fileType) || acceptedVideoExtensions.includes(fileExtension)) {
        return "Video";
      }

    },

    getUploadedFileSize(message) {
      const CI = this;

      let file_size_in_bytes = message[CI.DV_chatMessagesIndexes.file_size]

      if (!file_size_in_bytes) { return null; }

      if (file_size_in_bytes < 1024) {
        return `${file_size_in_bytes} bytes`
      }

      let file_size_in_kbs = (file_size_in_bytes / 1024).toFixed(2);
      if (file_size_in_kbs < 1024) {
        return `${file_size_in_kbs} KB`
      }

      let file_size_in_mbs = (file_size_in_kbs / 1024).toFixed(2);
      return `${file_size_in_mbs} MB`
    },

    lazyUpdateTypingStatus() {
      const CI = this;
      var textarea = CI.$refs.messageTextInput;
      let typingStatus = "not-typing";

      if (!textarea) { return; }

      if (document.activeElement != textarea || textarea.value == '' || (new Date().getTime() - CI.DV_lastTypingTime.getTime()) > CI.DV_typingDebounceTimeInMillis) {
        typingStatus = "not-typing";
      } else {
        typingStatus = "typing";
      }

      if (CI.DV_currentTypingStatus == typingStatus) { return; }
      CI.DV_currentTypingStatus = typingStatus;

      fs103Writer(
        "e",
        window.G_bi2_data.IV_recycler_information.value.recycler_business_uid,
        window.G_bi2_data.IV_recycler_information.value.recycler_location_uid,
        window.G_bi2_data.IV_recycler_information.value.assigned_employee_uid,
        window.G_bi2_data.IV_conversation_information.value.consumer_uid,
        window.G_bi2_data.IV_conversation_information.value.conversation_uid,
        {
          consumer_typing_status: typingStatus
        }
      )
    },

    downloadMedia(url) {
      const storage = getStorage();
      const httpsReference = ref(storage, url);

      getDownloadURL(httpsReference)
      .then((url) => {

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;

          // Create a link for the user to download the blob
          const blobUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.classList.add("hide");
          downloadLink.href = blobUrl;
          downloadLink.download = `${blob["type"].replace("/", ".")}`; // Specify the desired filename
          downloadLink.textContent = 'Download Image';

          // Append the link to the document
          document.body.appendChild(downloadLink);

          // Trigger a click event to start the download
          downloadLink.click();
          downloadLink.remove();

          // Clean up by revoking the blob URL
          URL.revokeObjectURL(blobUrl);
          
        };
        xhr.open('GET', url);
        xhr.send();

      })
      .catch((error) => {
        this.$awn.alert("Error while downloading. Please try again later.");
      });
    }
  },

  watch: {
    DV_activeConversationMessagesRef: {
      handler() {
        this.updateMessagesList();
      },
      deep: true
    },

    DV_paymentLinks: {
      handler() {
        this.startPaymentLinksListener();
        this.checkForDirectPaymentLink(0);
        this.checkForIdentityVerificationLink(0);
      },
      deep: true
    },

    DV_identityVerificationLinks: {
      handler() {
        this.startPaymentLinksListener();
      },
      deep: true
    },

    DV_conversationInformation: {
      handler() {
        if (this.C_chatFinished && !this.C_feedbackSubmitted) {
          this.DV_activeView = 'review';
        }
      },
      deep: true
    },

    DV_outboundMessageText() {
      this.DV_lastTypingTime = new Date();
    },

    DV_employeeTypingStatusInfo: {
      handler() {
        const CI = this;

        if ("employee_typing_status" in CI.DV_employeeTypingStatusInfo) {
          CI.DV_employeeTypingStatus = CI.DV_employeeTypingStatusInfo["employee_typing_status"];
        }        
      },

      deep: true
    },
    DV_checkingForDirectPaymentLink: {
      handler() {
        console.log(this.DV_checkingForDirectPaymentLink)
        this.DV_paymentLinksCount = Object.keys(window.G_bi2_data.IV_consumer_conversation_payment_links.value).length;
      },
      deep: true
    },
    DV_checkingForIdentityVerificationLink: {
      handler() {
        console.log(this.DV_checkingForIdentityVerificationLink)
      },
      deep: true
    }
  }
};
</script>
