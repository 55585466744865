<template>
  <div id="app">
    <p1HomeView />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import p1HomeView from "./views/p1HomeView.vue";

export default Vue.extend({
  name: "App",

  components: {
    p1HomeView,
  },

  data() {
    return {
      //
    };
  },
});
</script>

<style lang="less">
@import '~vue-awesome-notifications/dist/styles/style.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
