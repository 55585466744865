<template>
  <div class="p-2">
    <div>
      <div class="py-3">
        <button @click="$emit('back-step')" class="btn btn-lime text-sm flex items-center justify-center mx-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 font-bold">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"/>
          </svg>
          Go Back To Previous Step
        </button>
      </div>
    </div>

    <div :class="[P_customerType == 'part' ? 'h-full' : 'h-40vh']" class="border border-gray-400 shadow-sm rounded-xl p-2 overflow-y-scroll"> 
      <!-- Select Vehicle Year -->
      <h1 class="font-bold text-left">
        Year
      </h1>
      <select
        class="text-center" 
        v-model="DV_vehicle.selectedYear"
        style="background-color: white;" 
      >
        <option 
          disabled 
          value=""
        >What Year Is Your Vehicle?</option>
        <option 
          v-for="(year, index) in C_yearsList" 
          :key="index" 
          :value="year"
        >
          {{ year }}
        </option>
      </select>
      
      <!-- Select Vehicle Make -->
      <h1 class="font-bold text-left mt-4">
        Make
      </h1>
      <select 
        class="text-center" 
        v-model="DV_vehicle.selectedManufacturer" 
        :disabled="!DV_vehicle.selectedYear" 
        style="background-color: white;" 
      >
        <option disabled value="">What Is Your Vehicle Make?</option>
        <option v-for="(manufacturer, index) in DV_manufacturers" :key="index" :value="manufacturer">
          {{ manufacturer }}
        </option>
      </select> 

      <!-- Select Vehicle Model -->
      <h1 class="font-bold text-left mt-4">
        Model
      </h1>
      <select
        class="text-center" 
        v-model="DV_vehicle.selectedModel" 
        :disabled="!(DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear)" 
        style="background-color: white;" 
      >
        <option 
          disabled 
          value=""
        >What Model Is Your Vehicle?</option>
        <option 
          v-for="(model, index) in C_modelsList" 
          :key="index" 
          :value="model"
        >
          {{ model }}
        </option>
      </select>

      <div class="mt-4" v-if="P_customerType == 'part' && (DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear && DV_vehicle.selectedModel)">
        <hr class="mb-2">
        <h1 class="font-bold">Describe the parts you're looking for</h1>
        <textarea
         class="w-[90vw] h-full"
         v-model="DV_vehicle.vehiclePartDescription"
        ></textarea>
      </div>
      
      <div class="mt-4" v-if="P_customerType == 'sale' && (DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear && DV_vehicle.selectedModel)">
        <hr class="mb-2">
        <h1 class="font-bold text-start">Please Answer the Questions Below</h1>
          <!-- question1 -->
          <div class="mt-2">
            <h1 class="font-bold text-sm">Do you have the title?</h1>
            <div class="flex justify-around items-center">
              <button
                @click="DV_vehicle.hasTitle = 'yes'"
                :class="{ active: DV_vehicle.hasTitle == 'yes' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                Yes
              </button>
              <button
                @click="DV_vehicle.hasTitle = 'no'"
                :class="{ active: DV_vehicle.hasTitle == 'no' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                No
              </button>
            </div>
          </div>
          <!-- question2 -->
          <div class="my-3">
            <h1 class="font-bold text-sm">Is your name on the title?</h1>
            <div class="flex justify-around items-center">
              <button
                @click="DV_vehicle.nameOnTitle = 'yes'"
                :class="{ active: DV_vehicle.nameOnTitle == 'yes' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                Yes
              </button>
              <button
                @click="DV_vehicle.nameOnTitle = 'no'"
                :class="{ active: DV_vehicle.nameOnTitle == 'no' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                No
              </button>
            </div>
          </div>
          <!-- question3 -->
          <div>
            <h1 class="font-bold text-sm">
              Do you have a picture of the vehicles VIN number on you phone?
            </h1>
            <div class="flex justify-around items-center">
              <button
                @click="showImageUpload"
                :class="{ active: DV_vehicle.vinPicture == 'yes' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                Yes
              </button>
              <button
                @click="DV_vehicle.vinPicture = 'no'"
                :class="{ active: DV_vehicle.vinPicture == 'no' }"
                class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
              >
                No
              </button>

              <input
                data-cy="image-upload-input"
                ref="imageInput"
                class="hidden"
                type="file"
                @change="handleImageUpload"
              />
            </div>

            <div v-show="DV_vehicle.vinPicture == 'no'" class="mt-2 mx-1">
              <input
                v-model="DV_vehicle.viNumber"
                type="text"
                class="w-full border border-gray-400 rounded-md px-2 py-1 focus:outline-none"
              />
            </div> 
        </div> 
      </div>
    </div>

    <div
      v-if="DV_vehicle.vinPicture"
      class="flex justify-between mx-1 items-center my-3"
    >
      <button @click="$emit('next-step', DV_vehicle)" class="text-xs btn btn-lime">
        Start Live Chat
      </button>
      <button
        @click="showImageUpload"
        v-show="DV_vehicle.vinPicture == 'yes'"
        class="text-xs btn btn-lime"
      >
        Change Uploaded Image
      </button>
    </div>

    <div v-if="P_customerType == 'part'" class="mt-2">
      <button 
        @click="$emit('next-step', 'part-numbers')" 
        class="btn btn-lime text-sm flex items-center justify-center mx-auto w-full"
      >
       Enter Part Number 
      </button>
      
      <button 
        @click="$emit('next-step', DV_vehicle)" 
        :class="{
          'btn btn-lime text-sm flex items-center justify-center mx-auto w-full mt-4': DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear && DV_vehicle.selectedModel,
          'btn btn-white text-sm flex items-center justify-center mx-auto w-full mt-4': !(DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear && DV_vehicle.selectedModel),
        }"
        :disabled="!(DV_vehicle.selectedManufacturer && DV_vehicle.selectedYear && DV_vehicle.selectedModel)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { manufacturers, models } from "../../includes/vehicles.ts";

export default {
  name: "c1c1SelectVehicle",

  props: ["P_customerType", "P_selectedManufacturer", "P_selectedYear", "P_selectedModel"],

  data() {
    return {
      DV_manufacturers: manufacturers,
      DV_vehicle: {
        selectedManufacturer: this.P_selectedManufacturer || "",
        selectedYear: this.P_selectedYear || "",
        selectedModel: this.P_selectedModel || "",
        vehiclePartDescription: "",
        hasTitle: "",
        nameOnTitle: "",
        vinPicture: "",
        liveChatView: "false",
        viNumber: "",
      },
    };
  },
  computed: {
    C_yearsList() {
      var max = new Date().getFullYear();
      if (this.P_maxYear != null) {
        max = this.P_maxYear;
      }

      var min = 1980;
      if (this.P_minYear != null) {
        min = this.P_minYear;
      }
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i);
      }
      return years;
    },
    
    C_modelsList() {
      return models[this.DV_vehicle.selectedManufacturer.replace(" ", "-").toLowerCase()];
    },
  },
  methods: {
    handleImageUpload() {
      //
    },
    showImageUpload() {
      this.DV_vehicle.vinPicture = "yes";
      this.$refs.imageInput.click();
    },
  }
};
</script>
<style scoped></style>
