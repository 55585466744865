/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function getPublicTokenForPaymentProcessor(
  recycler_uid: string,
  recycler_location_uid: string
) {

  return new Promise(function (resolve, reject) {
    const params = {
      firebase_uid: window.G_bi2_data.IV_firebase_uid.value,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e25GetPublicTokenForPaymentProcessor",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e25.ts",
          class_name: "p1s3e25",
          method_name: "getPublicTokenForPaymentProcessor",
          error_title: "Failed to get public token for payment processor",
          error_description:
            "The server responded with an error when getting public token for payment processor.",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default getPublicTokenForPaymentProcessor;
