/* eslint-disable */
import { ajax } from "noquery-ajax";
import { httpsLogger } from "../p1s7/p1s7e1";

function rateEmployeeInteraction(
  recycler_uid: string,
  recycler_location_uid: string,
  employee_uid: string,
  consumer_contact_uid: string,
  conversation_uid: String,
  rating: number) {
  return new Promise(function (resolve, reject) {
    let params = {
      firebase_uid: window.G_bi2_data.IV_firebase_uid.value,
      firebase_token: window.G_firebase_auth.IV_id_token,
      recycler_uid: recycler_uid,
      recycler_location_uid: recycler_location_uid,
      employee_uid: employee_uid,
      consumer_contact_uid: consumer_contact_uid,
      conversation_uid: conversation_uid,
      p1s3e9_rating: rating
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e9RateEmployeeInteraction",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,
      success: function (result) {
        resolve(result);
      },
      error: function (error) {
        let error_data = {
          request_object: error,
          input_data: params,
          file_name: "p1s3e9.ts",
          class_name: "p1s3e9",
          method_name: "rateEmployeeInteraction",
          error_title: "Failed to get login / conversation details",
          error_description:
            "The server responded with an error when trying to send get the conversation details",
        };

        httpsLogger(error_data);
        reject(error_data);
      },
    });
  });
}

export default rateEmployeeInteraction;
