import FirebaseAuth from "./includes/firebase_auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import getConversationDetails from "./includes/server_calls/p1s3/p1s3e17";
import { bi2DataInteractions } from "./includes/bi2DataInteractions";

// All global variables we'll use as window.VARIABLE
declare global {
  interface Window {
    G_dev_live_flag_force: any;
    G_firebase_auth: any;
    G_dev_flag: any;
    G_vue_instance: any;
    G_bi2_data: any;
    G_base_url: any;
    G_loaded_includes: any;
    G_cloud_functions_base_url: string;
    G_cloud_p1s3_base_url: string;
    G_cloud_p1s5_base_url: string;
    G_recycler_logos_bucket: string;
    G_site_type: 0 | 1 | 2;
    isIframeActive: boolean,
    G_loading_data: boolean
  }
}

const runFirst = () => {
  if (window.G_loading_data) { return } // skip repeated calls

  // produtction versus dev stuff
  window.G_site_type = 0;
  window.G_dev_live_flag_force = false;
  window.G_dev_flag = false;
  window.isIframeActive = false;
  window.document.title = "Synergy Auto Solution Consumer Live Chat";


  if (window.location.hostname.toString() === "c.synergy-auto-solutions.com") {
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-synergy-auto-solutions.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-synergy-auto-solutions.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-east4-synergy-auto-solutions.cloudfunctions.net/"
    window.G_recycler_logos_bucket = "https://storage.googleapis.com/live-fbs3recyclerlocationlogos/"
    window.G_base_url = "https://us-east4-synergy-auto-solutions.net"
    window.G_dev_flag = false;
  }

  else if (
    window.location.hostname.toString().includes("dev") ||
    window.location.hostname.toString().includes("localhost") ||
    window.location.hostname.toString().includes("127.0.0.1")
  ) {
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"
    window.G_recycler_logos_bucket = "https://storage.googleapis.com/fbs3recyclerlocationlogos/";

    window.G_dev_flag = true;
  }

  //forcing live flag should only work on localhost
  else if (
    (window.location.hostname.toString().includes("localhost") ||
      window.location.hostname.toString().includes("127.0.0.1")) &&
    window.G_dev_live_flag_force === true
  ) {
    window.G_dev_flag = false;
  }

  else {
    window.G_cloud_p1s3_base_url = "https://p1s3-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_p1s5_base_url = "https://p1s5-dot-dev-synergy-auto-solutions.appspot.com/"
    window.G_cloud_functions_base_url = "https://us-central1-dev-synergy-auto-solutions.cloudfunctions.net/"

  }

  window.G_firebase_auth = new FirebaseAuth();
  window.G_firebase_auth.initFirebase();
  window.G_bi2_data = new bi2DataInteractions(
  window.G_firebase_auth.IV_firestore_object
  );

  if ( window.location !== window.parent.location ) {
    window.isIframeActive = true;
  }

  //get parameters for the web widget
  if (window.location.href.lastIndexOf("/c/") === -1) {
    const urlParams = new URLSearchParams(window.location.search);

    window.G_bi2_data.IV_guest_consumer_information.recycler_business_uid = urlParams.get('recycler_business_uid') || "";
    window.G_bi2_data.IV_guest_consumer_information.recycler_location_uid = urlParams.get('recycler_location_uid') || "";
    window.G_bi2_data.IV_recycler_information.value.recycler_location_name = urlParams.get('name') || "";
    window.G_bi2_data.IV_guest_consumer_information.request_type = urlParams.get('type') || "";
    window.G_bi2_data.startRecyclerInfoListener();

    window.G_site_type = 2;
    return;
  }

  window.G_site_type = 1;
  var searchString = window.location.href.split("c/")[1]
  var conversation_uid = searchString.split("?")[0];
  var conversation_uid = conversation_uid.split("/verify")[0];

  getConversationDetails(conversation_uid).then((resp: any) => {
    //console.log(resp);
    const auth = getAuth();

    signInWithEmailAndPassword(auth, resp.username, resp.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        window.G_bi2_data.setFirebaseAccountDetails(
          window.G_firebase_auth.IV_uid,
          window.G_firebase_auth.IV_email_address
        );

        window.G_bi2_data.IV_guest_consumer_information.recycler_business_uid = resp.business_uid
        window.G_bi2_data.IV_guest_consumer_information.recycler_location_uid = resp.recycler_location_uid

        window.G_bi2_data.setConversationUids({
          recycler_business_uid: resp.business_uid,
          recycler_location_uid: resp.recycler_location_uid,
          consumer_uid: resp.consumer_contact_uid,
          conversation_uid: resp.conversation_uid,
        });
        window.G_bi2_data.startListenersStep1();
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }).catch((error) => {
    alert(error.error_title);
  });
};

runFirst();

export default runFirst;
