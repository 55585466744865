<template>
  <div>
    <div v-show="DV_liveChatView == 'false'">
      <div class="py-3">
        <button
          @click="$emit('back-step')"
          class="btn btn-lime text-sm flex items-center justify-center mx-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 font-bold"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>

          Go Back To Previous Step
        </button>
      </div>
      <h1 class="font-bold">Please Answer the Questions Below</h1>
      <div
        class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll mxh-70"
      >
        <!-- question1 -->
        <div>
          <h1 class="font-bold text-sm">Do you have the title?</h1>
          <div class="flex justify-around items-center">
            <button
              @click="title = 'yes'"
              :class="{ active: title == 'yes' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Yes
            </button>
            <button
              @click="title = 'no'"
              :class="{ active: title == 'no' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              No
            </button>
          </div>
        </div>
        <!-- question2 -->
        <div class="my-3">
          <h1 class="font-bold text-sm">Is your name on the title?</h1>
          <div class="flex justify-around items-center">
            <button
              @click="DV_nameOnTitle = 'yes'"
              :class="{ active: DV_nameOnTitle == 'yes' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Yes
            </button>
            <button
              @click="DV_nameOnTitle = 'no'"
              :class="{ active: DV_nameOnTitle == 'no' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              No
            </button>
          </div>
        </div>
        <!-- question3 -->
        <div>
          <h1 class="font-bold text-sm">
            Do you have a picture of the vehicles VIN number on you phone?
          </h1>
          <div class="flex justify-around items-center">
            <button
              @click="showImageUpload"
              :class="{ active: DV_vinPicture == 'yes' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              Yes
            </button>
            <button
              @click="DV_vinPicture = 'no'"
              :class="{ active: DV_vinPicture == 'no' }"
              class="w-3/4 relative m-1 inline-flex items-center justify-center rounded-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:text-white focus:z-10 focus:bg-blue-900 focus:outline-none focus:bg-blue-900"
            >
              No
            </button>

            <input
              data-cy="image-upload-input"
              ref="imageInput"
              class="hidden"
              type="file"
              @change="handleImageUpload"
            />
          </div>

          <div v-show="DV_vinPicture == 'no'" class="mt-2 mx-1">
            <input
              v-model="DV_viNumber"
              type="text"
              class="w-full border border-gray-400 rounded-md px-2 py-1 focus:outline-none"
            />
          </div>

          <div
            v-if="DV_vinPicture"
            class="flex justify-between mx-1 items-center my-3"
          >
            <button @click="$emit('next-step')" class="text-xs btn btn-lime">
              Start Live Chat
            </button>
            <button
              @click="showImageUpload"
              v-show="DV_vinPicture == 'yes'"
              class="text-xs btn btn-lime"
            >
              Change Uploaded Image
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c1c4VehicleInfo",
  data() {
    return {
      title: "",
      DV_nameOnTitle: "",
      DV_vinPicture: "",
      DV_liveChatView: "false",
      DV_viNumber: "",
    };
  },
  methods: {
    handleImageUpload() {
      //
    },
    showImageUpload() {
      this.DV_vinPicture = "yes";
      this.$refs.imageInput.click();
    },
  },
};
</script>
