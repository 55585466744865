<template>
  <div class="mt-8 w-full px-12 text-md">
    <div class="mb-4">
      Staff member requested identity verification. To complete any payment during this conversation please verify your identity. To start verification please click on the below button:
    </div>

    <small>
      Note: If you have already submitted the documents, please wait for the approval. You will be able to access payment links from your live chat view, once the identity approved.
    </small>

    <!-- button -->
    <div class="mx-2">
      <button 
        class="mt-2 btn btn-lime text-sm flex items-center justify-center w-full" 
        @click.stop="initVerification"
        :disabled="!DV_stripeLoaded"
      >Verify Identity</button>
      <button
        @click.stop="$emit('show-live-chat')"
        class="mt-2 btn btn-lime text-sm flex items-center justify-center w-full"
      >
        Return to live chat
      </button>
    </div>
  </div> 
</template>

<script>
import AWN from "awesome-notifications";

  export default {
    name: "c2c3IdentityVerification",

    data() {
      return {
        DV_identityVerificationLinks: {},
        DV_stripeLoaded: false,
        DV_stripe: null
      }
    },

    mounted() {
      const CI = this;

      CI.DV_identityVerificationLinks = window.G_bi2_data.IV_consumer_identity_verification_links.value;
      CI.includeStripe('js.stripe.com/v3/', function() {
        CI.DV_stripeLoaded = true;
        CI.DV_stripe = Stripe("pk_live_51MAej2LhOYag7rNDyo0sUg6zBxUMbE0JxuHvFL36VNOGdcvA84UOaFJrYHZmqZpwHyv2tCjaynsSV7tWIXevNBsx00Dlx2q5T8");
      }.bind(CI) );
    },

    methods: {
      includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
        
        object.src = '//' + URL;
        
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },

      initVerification() {
        const CI = this

        if (!CI.DV_stripe) {
          CI.$awn.alert("Failed to start the verification process. Please reload page and try again.");
          return;
        }

        const uids = window.G_bi2_data.IV_conversation_uids.value;
        const verification_uid = `${uids.fs4_doc_id}-${uids.fs6_doc_id}`

        if (verification_uid in CI.DV_identityVerificationLinks === false) {
          CI.$awn.alert("Failed to start the verification process. Please reload page and try again.");
          return;
        }

        window.open(CI.DV_identityVerificationLinks[verification_uid].verification_link, "_blank")
        // CI.DV_stripe.verifyIdentity(CI.DV_identityVerificationLinks[verification_uid].verification_session_id);
        return 
      }
    }
  }
</script>