<template>
    <div class=" flex flex-col gap-6 mt-8 ml-5 mr-5">
     <p>
        A text mesage has been sent to <span class=" font-bold text-green-600"> {{C_formatedPhoneNumber }} </span> with a link to the Live chat we've created for you. You can also scan the QR code with your phone below.
     </p>

     <div class="flex justify-center items-center !h-[27svh]">
       <img id="c1c11_qr_code" src="" class="h-auto"/>
      </div>
     <button 
        class="btn btn-lime flex items-center justify-center mx-auto"
        :href="C_conversationUrl"
        @click.stop="openConversation()">Click To Open Live Chat
    </button>

    </div>
  </template>
  
  <script>
  import dataValidator from "../../includes/bi1Datavalidation";
  import {CR,RC} from "../../includes/bi2SuccessCodes";
  import QRCode from 'qrcode';

  export default {
    name: "c1c11ConversationCreated",
    props: ["P_phoneNumber", "P_conversationUrlUid"],
    data() {
      return {
        // 
      };
    },
  
    mounted() {
        var opts = {
          errorCorrectionLevel: 'H',
          type: 'image/jpeg'
        }

        QRCode.toDataURL(this.C_conversationUrl, opts, function (err, url) {
          if (err) throw err

          var img = document.getElementById('c1c11_qr_code')
          img.src = url
        })
    },

    methods: {
      openConversation() {
        window.location.assign(this.C_conversationUrl);
      }
    },
    
    computed: {
      C_conversationUrl() {
        let port_string = "";

        if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
          port_string = ":" + window.location.port;
        }
        
        return window.location.protocol + "//"  + window.location.hostname + port_string + "/c/" + this.P_conversationUrlUid;
      },

      C_formatedPhoneNumber() {
          let formated_phone_number = this.P_phoneNumber.substr(0, 3);
          formated_phone_number += "-";
          formated_phone_number += this.P_phoneNumber.substr(3,3);
          formated_phone_number += "-";
          formated_phone_number += this.P_phoneNumber.substr(6,4);
          return formated_phone_number;

      }
    },
  };
  </script>
  