import { Unsubscribe } from "@firebase/util";
import {
  collection,
  doc,
  onSnapshot,
  getDoc,
  query,
  where,
  Firestore,
  getDocs,
  orderBy,
  //Timestamp,
} from "firebase/firestore";
import type { Ref } from "vue";
import Vue, { ref } from "vue";
import { fs1RecyclerBusiness } from "./firestore_schemas/fs1RecyclerBusiness";
import { fs2RecyclerLocation } from "./firestore_schemas/fs2RecyclerLocation";
import { fs3Employees } from "./firestore_schemas/fs3Employees";
import { fs4ConsumerContacts } from "./firestore_schemas/fs4ConsumerContacts";
import { fs6ConsumerConversations } from "./firestore_schemas/fs6ConsumerConversations";
import { fs10MessagesWithConsumer } from "./firestore_schemas/fs10MessagesWithConsumer";
import { fs84ConsumerPaymentLinks } from "./firestore_schemas/fs84ConsumerPaymentLinks";
import { fs86ConsumerSlimCdPaymentUrl } from "./firestore_schemas/fs86ConsumerSlimCdPaymentUrl";
import { fs122ConsumerAxePaymentsUrl } from "./firestore_schemas/fs122ConsumerAxePaymentsUrl";
import { fs118ConsumerIdentityVerificationDetails } from "./firestore_schemas/fs118ConsumerIdentityVerificationDetails";
import { fs85RecyclerLocationLogos } from "./firestore_schemas/fs85RecyclerLocationLogos";
import { fs103ConversationStatuses } from "./firestore_schemas/fs103ConversationStatuses";
import { fs130RecyclerTermsAndConditions } from "./firestore_schemas/fs130RecyclerTermsAndConditions";

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
};

interface setConversationUidsArguments {
  recycler_business_uid: string;
  recycler_location_uid: string;
  consumer_uid: string;
  conversation_uid: string;
}

interface __fs2DocumentListenerCallbackArguments {
  class_instance: bi2DataInteractions;
  firestore_document: fs2RecyclerLocation;
  recycler_business_uid: string;
  recycler_location_uid: string;
}

interface __f3DocumentListenerCallbackArguments {
  firestore_document: fs3Employees;
  class_instance: bi2DataInteractions;
  recycler_business_uid: string;
  recycler_location_uid: string;
  employee_uid: string;
}

interface __f4DocumentListenerCallbackArguments {
  firestore_document: fs4ConsumerContacts;
  class_instance: bi2DataInteractions;
  recycler_business_uid: string;
  recycler_location_uid: string;
  consumer_uid: string;
}

interface __f6DocumentListenerCallbackArguments {
  firestore_document: fs6ConsumerConversations;
  class_instance: bi2DataInteractions;
  recycler_business_uid: string;
  recycler_location_uid: string;
  consumer_uid: string;
  conversation_uid: string;
}

interface __fs10CollectionListenerCallbackArguments {
  firestore_document: fs10MessagesWithConsumer;
  class_instance: bi2DataInteractions;
  recycler_business_uid: string;
  recycler_location_uid: string;
  consumer_uid: string;
  conversation_uid: string;
}

export class bi2DataInteractions {
  IV_firebase_app: Firestore;
  IV_vue_app: Vue | undefined;
  IV_is_instance_ready: boolean;
  IV_firebase_uid: Ref<string>;
  IV_firebase_email: Ref<string>;

  IV_collection_active_listeners: {
    [document_id: string]: Unsubscribe;
  };

  IV_payment_links_listener: null | Unsubscribe;
  IV_identity_verification_links_listener: null | Unsubscribe;

  IV_guest_consumer_information: {
    recycler_business_uid: string;
    recycler_location_uid: string;
    request_type: string;
  };

  IV_document_active_listeners: {
    [document_id: string]: Unsubscribe;
  };

  IV_consumer_information: Ref<{
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
  }>;

  IV_consumer_conversation_payment_links: Ref <{
    [payment_link_uid: string]: {
      payment_link_uid: string;
      payment_link_url: string;
      payment_link_description: string;
      amount: number;
      order_number: string;
      payment_status: string;
      terms_type_number: number;
    };
  }>;

  IV_consumer_identity_verification_links: Ref <{
    [uuid: string]: {
      verification_session_id: string;
      verification_link: string;
      redirect_link: string;
      status: string;
      time_verified: Date;
      last_time_event_received: Date;
    };
  }>;

  IV_payment_links_info: Ref <{
    [payment_link_uid: string]: {
      [payment_link_info_uid: string]: {
        payment_gate_id: string;
        last_4_digits: string;
        card_type: string;
        expiry_month: string;
        expiry_year: string;
        first_name: string;
        last_name: string;
        email: string;
      }
    }
  }>;

  IV_transaction_terms_and_conditions: Ref <{
    [business_and_recycler_location_and_type_number_uuid: string]: {
      type_number: number;
      type_description: string;
      transaction_terms_and_conditions: string;
    }
  }>;

  // TODO: SBD
  // sources: fs101, fs102, fs103
  IV_conversation_activity_status: Ref<{
    last_activity_time: Date | string;
    employee_typing_status: string;
    consumer_typing_status: string;
  }>;

  IV_conversation_uids: Ref<{
    fs1_doc_id: string;
    fs2_doc_id: string;
    fs4_doc_id: string;
    fs6_doc_id: string;
  }>;

  IV_conversation_messages: Array<
    [
      sender: 1 | 2,
      type: 1 | 2 | 3,
      message_uid: string,
      employee_uid: string,
      timestamp: Date,
      content: string,
      file_name?: string | undefined,
      file_size?: number | undefined,
      file_type?: number | undefined
    ]
  >;

  IV_al_conversation_messages: Ref<{
    message_sender: 0;
    message_type: 1;
    message_uid: 2;
    employee_uid: 3;
    timestamp: 4;
    message_content: 5;
    file_url: 5;
    file_name: 6;
    file_size: 7;
    file_type: 8;
  }>;

  IV_conversation_information: Ref<{
    type?: number;
    status?: string;
    consumer_uid?: string;
    conversaton_uid?: string;
  }>;

  IV_recycler_information: Ref<{
    recycler_business_uid: string;
    recycler_location_uid: string;
    recycler_location_name: string;
    phone_number: string;
    email: string;
    city: string;
    state: string;
    postal_code: number;
    address_line_1: string;
    address_line_2: string;
    assigned_employee_uid: string;
    assigned_employee_name: string;
  }>;

  IV_employee_names: Ref<{
    [fs12_id: string]: string;
  }>;

  IV_vehicle_info: Ref<{
    [fs35_id: string]: {
      entry_type: 1 | 2;
      vehicle_make_uid?: string;
      vehicle_make_name?: string;
      vehicle_model_uid?: string;
      vehicle_model_name?: string;
      vehicle_year?: string;
      interchange_part_number?: string;
    };
  }>;

  constructor(firestore_app: Firestore) {
    const CI = this;
    this.IV_is_instance_ready = false;
    this.IV_firebase_app = firestore_app;
    this.IV_collection_active_listeners = {};
    this.IV_document_active_listeners = {};
    this.IV_firebase_uid = ref("");
    this.IV_firebase_email = ref("");
    this.IV_consumer_information = ref({});
    this.IV_guest_consumer_information = {
      recycler_business_uid: "",
      recycler_location_uid: "",
      request_type: ""
    };
    this.IV_payment_links_listener = null;
    this.IV_identity_verification_links_listener = null;
    this.IV_conversation_uids = ref({
      fs1_doc_id: "",
      fs2_doc_id: "",
      fs4_doc_id: "",
      fs6_doc_id: "",
    });

    this.IV_al_conversation_messages = ref({
      message_sender: 0,
      message_type: 1,
      message_uid: 2,
      employee_uid: 3,
      timestamp: 4,
      message_content: 5,
      file_url: 5,
      file_name: 6,
      file_size: 7,
      file_type: 8
    } as const);

    this.IV_conversation_messages = [];
    this.IV_conversation_information = ref({});
    this.IV_consumer_conversation_payment_links = ref({});
    this.IV_transaction_terms_and_conditions = ref({});
    this.IV_consumer_identity_verification_links = ref({});
    this.IV_payment_links_info = ref({});
    this.IV_conversation_activity_status = ref({
      employee_typing_status: "not-typing",
      consumer_typing_status: "not-typing",
      last_activity_time: new Date(0)

    });

    this.IV_recycler_information = ref({
      recycler_business_uid: "",
      recycler_location_uid: "",
      recycler_location_name: "",
      phone_number: "",
      email: "",
      city: "",
      state: "",
      postal_code: 0,
      address_line_1: "",
      address_line_2: "",
      assigned_employee_uid: "",
      assigned_employee_name: "",
    });

    this.IV_employee_names = ref({});
    this.IV_vehicle_info = ref({});
  }

  setConversationUids({
    recycler_business_uid,
    recycler_location_uid,
    consumer_uid,
    conversation_uid,
  }: setConversationUidsArguments) {
    //let call_result = {};

    //data validation
    //</end> data validation
    const CI = this;
    CI.IV_conversation_uids = ref({
      fs1_doc_id: recycler_business_uid,
      fs2_doc_id: recycler_location_uid,
      fs4_doc_id: consumer_uid,
      fs6_doc_id: conversation_uid,
    });

    Vue.set(CI.IV_conversation_information.value, "consumer_uid", consumer_uid);
    Vue.set(
      CI.IV_conversation_information.value,
      "conversation_uid",
      conversation_uid
    );

    if (CI.IV_firebase_uid !== ref("") && CI.IV_firebase_email !== ref("")) {
      CI.IV_is_instance_ready = true;
    }
  }

  setFirebaseAccountDetails(uid: string, email: string) {
    const CI = this;
    //let call_result = {};

    /// data validation
    //</end> data validation
    CI.IV_firebase_uid = ref(uid);
    CI.IV_firebase_email = ref(email);
  }

  startListenersStep1() {
    const CI = this;
    const listeners = CI.IV_document_active_listeners;
    const result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerLocationListener: ",
      success: true,
    };
    if (CI.IV_is_instance_ready !== true) {
      // TODO add error standard return keys dictionary
      console.log(
        "starting startListenersStep1 failed due to instance not being ready"
      );
      return;
    }

    this.startRecyclerInfoListener();

    const fs4_doc_ref = doc(
      CI.IV_firebase_app,
      "fs1RecyclerBusiness",
      CI.IV_conversation_uids.value.fs1_doc_id,
      "fs2RecyclerLocation",
      CI.IV_conversation_uids.value.fs2_doc_id,
      "fs4ConsumerContacts",
      CI.IV_conversation_uids.value.fs4_doc_id
    );

    const fs4_unsub = onSnapshot(fs4_doc_ref, (doc_snapshot) => {
      if (doc_snapshot.data()) {
        CI.__fs4DocumentListenerCallback({
          class_instance: CI,
          firestore_document: doc_snapshot.data() as fs4ConsumerContacts,
          recycler_business_uid: CI.IV_conversation_uids.value.fs1_doc_id,
          recycler_location_uid: CI.IV_conversation_uids.value.fs2_doc_id,
          consumer_uid: CI.IV_conversation_uids.value.fs4_doc_id,
        });
      }
    });

    const fs6_doc_ref = doc(
      CI.IV_firebase_app,
      "fs1RecyclerBusiness",
      CI.IV_conversation_uids.value.fs1_doc_id,
      "fs2RecyclerLocation",
      CI.IV_conversation_uids.value.fs2_doc_id,
      "fs4ConsumerContacts",
      CI.IV_conversation_uids.value.fs4_doc_id,
      "fs6ConsumerConversations",
      CI.IV_conversation_uids.value.fs6_doc_id
    );

    const fs6_unsub = onSnapshot(fs6_doc_ref, (doc_snapshot) => {
      if (doc_snapshot.data()) {
        CI.__fs6DocumentListenerCallback({
          class_instance: CI,
          firestore_document: doc_snapshot.data() as fs6ConsumerConversations,
          recycler_business_uid: CI.IV_conversation_uids.value.fs1_doc_id,
          recycler_location_uid: CI.IV_conversation_uids.value.fs2_doc_id,
          consumer_uid: CI.IV_conversation_uids.value.fs4_doc_id,
          conversation_uid: CI.IV_conversation_uids.value.fs6_doc_id,
        });
      }
    });

    const fs10_doc_query = collection(
      CI.IV_firebase_app,
      "fs1RecyclerBusiness",
      CI.IV_conversation_uids.value.fs1_doc_id,
      "fs2RecyclerLocation",
      CI.IV_conversation_uids.value.fs2_doc_id,
      "fs4ConsumerContacts",
      CI.IV_conversation_uids.value.fs4_doc_id,
      "fs6ConsumerConversations",
      CI.IV_conversation_uids.value.fs6_doc_id,
      "fs10MessagesWithConsumer"
    );

    const fs10_unsub = onSnapshot(fs10_doc_query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added") {
          CI.__fs10CollectionListenerCallback({
            class_instance: CI,
            firestore_document: change.doc.data() as fs10MessagesWithConsumer,
            recycler_business_uid: CI.IV_conversation_uids.value.fs1_doc_id,
            recycler_location_uid: CI.IV_conversation_uids.value.fs2_doc_id,
            consumer_uid: CI.IV_conversation_uids.value.fs4_doc_id,
            conversation_uid: CI.IV_conversation_uids.value.fs6_doc_id,
          });
        }
      });
    });
    
    const fs130_doc_query = collection(
      CI.IV_firebase_app,
      "fs130RecyclerTermsAndConditions"
    );

    // here we are listening on whole conversation queue for a recycler location
    const fs130_unsub = onSnapshot(fs130_doc_query, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          if (!change.doc.id.includes(`${CI.IV_conversation_uids.value.fs1_doc_id}-${CI.IV_conversation_uids.value.fs2_doc_id}`)) {return;}
          const doc_data = change.doc.data() as fs130RecyclerTermsAndConditions;
          CI.__fs130CollectionListenerCallback(
            CI,
            doc_data,
            CI.IV_conversation_uids.value.fs1_doc_id,
            CI.IV_conversation_uids.value.fs2_doc_id
          );
        }
      });
    });

  }

  startRecyclerInfoListener() {
    const CI = this;
    const listeners = CI.IV_document_active_listeners;
    const result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startRecyclerInfoListener: ",
      success: true,
    };

    if (CI.IV_firebase_uid == ref("") && CI.IV_firebase_email == ref("")) {
      // TODO add error standard return keys dictionary
      console.log(
      "starting startListenersStep1 failed due to instance not being ready"
      );
      return;
    }

    const fs2_doc_ref = doc(
      CI.IV_firebase_app,
      "fs1RecyclerBusiness",
      CI.IV_guest_consumer_information.recycler_business_uid,
      "fs2RecyclerLocation",
      CI.IV_guest_consumer_information.recycler_location_uid
    );

    const fs2_unsub = onSnapshot(fs2_doc_ref, (doc_snapshot) => {
      if (doc_snapshot.data()) {
        CI.__fs2DocumentListenerCallback({
          class_instance: CI,
          firestore_document: doc_snapshot.data() as fs2RecyclerLocation,
          recycler_business_uid: CI.IV_guest_consumer_information.recycler_business_uid,
          recycler_location_uid: CI.IV_guest_consumer_information.recycler_location_uid
        });
      }
    });
  }

  __fs2DocumentListenerCallback({
    class_instance,
    firestore_document,
    recycler_business_uid,
    recycler_location_uid,
  }: __fs2DocumentListenerCallbackArguments) {
    const CI = class_instance;
    Vue.set(
      CI.IV_recycler_information.value,
      "phone_number",
      firestore_document.phone
    );
    Vue.set(CI.IV_recycler_information.value, "city", firestore_document.city);
    Vue.set(
      CI.IV_recycler_information.value,
      "address_line_1",
      firestore_document.address_line_1
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "website",
      firestore_document.website
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "phone_number",
      firestore_document.phone
    );

    if (firestore_document.address_line_2 !== undefined) {
      Vue.set(
        CI.IV_recycler_information.value,
        "address_line_2",
        firestore_document.address_line_2
      );
    }

    Vue.set(
      CI.IV_recycler_information.value,
      "state",
      firestore_document.state
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "postal_code",
      firestore_document.postal_code
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "recycler_location_name",
      firestore_document.recycler_name
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "recycler_business_uid",
      recycler_business_uid
    );
    Vue.set(
      CI.IV_recycler_information.value,
      "recycler_location_uid",
      recycler_location_uid
    );

    //console.log(CI.IV_recycler_information.value);
  }
  __fs3DocumentListenerCallback({
    class_instance,
    firestore_document,
    recycler_business_uid,
    recycler_location_uid,
    employee_uid,
  }: __f3DocumentListenerCallbackArguments) {
    const CI = class_instance;
    Vue.set(
      CI.IV_employee_names.value,
      employee_uid,
      firestore_document.first_name + " " + firestore_document.last_name
    );

    // TODO
  }
  __fs4DocumentListenerCallback({
    class_instance,
    firestore_document,
    recycler_business_uid,
    recycler_location_uid,
    consumer_uid,
  }: __f4DocumentListenerCallbackArguments) {
    const CI = class_instance;

    Vue.set(CI.IV_consumer_information.value, "first_name", firestore_document.first_name);
    Vue.set(CI.IV_consumer_information.value, "last_name", firestore_document.last_name);
    Vue.set(CI.IV_consumer_information.value, "phone_number", firestore_document.phone_number);
    Vue.set(CI.IV_consumer_information.value, "email", firestore_document.email);
  }

  __fs6DocumentListenerCallback({
    class_instance,
    firestore_document,
    recycler_business_uid,
    recycler_location_uid,
    consumer_uid,
    conversation_uid,
  }: __f6DocumentListenerCallbackArguments) {
    const CI = class_instance;
    Vue.set(
      CI.IV_conversation_information.value,
      "type",
      firestore_document.conversation_type
    );
    Vue.set(
      CI.IV_conversation_information.value,
      "status",
      firestore_document.conversation_status_codes
    );

    if (
      firestore_document.assigned_employee_uid !== undefined &&
      firestore_document.assigned_employee_uid !== null
    ) {
      Vue.set(
        CI.IV_recycler_information.value,
        "assigned_employee_uid",
        firestore_document.assigned_employee_uid
      );

      CI.startConsumersActivityStatusListener(
        CI,
        recycler_business_uid,
        recycler_location_uid,
        firestore_document.assigned_employee_uid,
        consumer_uid,
        conversation_uid
      )
    }

    //console.log(firestore_document);
    //console.log(CI.IV_conversation_information.value);

    ///start listeners for the employee names
    for (const employee_uid in firestore_document.consumer_chat_employee_uid_list) {
      const fs3_doc_ref = doc(
        CI.IV_firebase_app,
        "fs1RecyclerBusiness",
        CI.IV_conversation_uids.value.fs1_doc_id,
        "fs2RecyclerLocation",
        CI.IV_conversation_uids.value.fs2_doc_id,
        "fs3Employees",
        employee_uid
      );

      const fs3_unsub = onSnapshot(fs3_doc_ref, (doc_snapshot) => {
        if (doc_snapshot.data()) {
          CI.__fs3DocumentListenerCallback({
            class_instance: CI,
            firestore_document: doc_snapshot.data() as fs3Employees,
            recycler_business_uid: CI.IV_conversation_uids.value.fs1_doc_id,
            recycler_location_uid: CI.IV_conversation_uids.value.fs2_doc_id,
            employee_uid: employee_uid,
          });
        }
      });
      ///</end> start listeners for the employee names
    }

    // TODO
  }
  __fs10CollectionListenerCallback({
    class_instance,
    firestore_document,
    recycler_business_uid,
    recycler_location_uid,
    consumer_uid,
    conversation_uid,
  }: __fs10CollectionListenerCallbackArguments) {
    const CI = class_instance;
    let employee_uid = "";

    if (firestore_document.sender === 6) { return; }

    if (
      firestore_document.employee_uid !== undefined &&
      firestore_document.employee_uid !== null
    ) {
      employee_uid = firestore_document.employee_uid;
      if (employee_uid in CI.IV_employee_names === false) {
        //start FS3 listener for this employee UID
        const fs3_doc_ref = doc(
          CI.IV_firebase_app,
          "fs1RecyclerBusiness",
          CI.IV_conversation_uids.value.fs1_doc_id,
          "fs2RecyclerLocation",
          CI.IV_conversation_uids.value.fs2_doc_id,
          "fs3Employees",
          employee_uid
        );

        const fs3_unsub = onSnapshot(fs3_doc_ref, (doc_snapshot) => {
          if (doc_snapshot.data()) {
            CI.__fs3DocumentListenerCallback({
              class_instance: CI,
              firestore_document: doc_snapshot.data() as fs3Employees,
              recycler_business_uid: CI.IV_conversation_uids.value.fs1_doc_id,
              recycler_location_uid: CI.IV_conversation_uids.value.fs2_doc_id,
              employee_uid: employee_uid,
            });
          }
        });
      }
    }

    let loop1 = 0;
    let insert_index = 0;
    while (loop1 < CI.IV_conversation_messages.length) {
      if (
        CI.IV_conversation_messages[loop1][
          CI.IV_al_conversation_messages.value.timestamp
        ] > firestore_document.timestamp
      ) {
        insert_index = loop1 - 1;
      }
      loop1 += 1;
    }

    if (typeof(firestore_document.content) == "string"
    && firestore_document.content.indexOf("[FORCE_SENDER_TYPE_4]") === 0) {
      firestore_document.sender = 4;
      firestore_document.content = firestore_document.content.replace("[FORCE_SENDER_TYPE_4]","");
    }   

    let data_to_add = [      
      firestore_document.sender,
      firestore_document.type,
      firestore_document.consumer_message_uid,
      employee_uid,
      firestore_document.timestamp
    ];

    if (firestore_document.type === 1) {
      if(firestore_document.content.indexOf("https://") !== -1) {

        var urlRegex = new RegExp('(https:\/\/\S.*|https:\/\/.*$)');
          let match_result = firestore_document.content.match(urlRegex);
          if (match_result !== null ) {
            let base_url = "";
            let text_after_url = "";
            if( match_result[0].indexOf(" ") !== -1) {
              let split_results = match_result[0].split(" ");
              //@ts-ignore
              base_url = split_results.splice(0,1)
              text_after_url = split_results.join(" ");
            } else {
              base_url = match_result[0];
            }
            //console.log(url);
            let formatted_url = "<a class='text-blue-700 break-all' href='" + base_url + "'>" + base_url + "</a>";
            
            //URLs from synergy platform should be on their own line
            if(firestore_document.content.indexOf("c.synergy-auto-solutions.com") !== -1
            && firestore_document.sender > 2) {
              formatted_url = "<br>" + formatted_url + "<br>"
            }
            if(text_after_url !=="") {
              formatted_url = formatted_url + " " + text_after_url;
            }
            firestore_document.content= firestore_document.content.replace(urlRegex,formatted_url);            
          }
      }

      data_to_add.push(firestore_document.content);
      
    }
    else if (firestore_document.type === 2 || firestore_document.type === 3){
      //@ts-ignore
        data_to_add.push(firestore_document.uploaded_file_link);
        //@ts-ignore
        data_to_add.push(firestore_document.uploaded_file_name);
        //@ts-ignore TS can't tell this is the 3rd index being appended so it thinks this is pushing to the file_link index
        data_to_add.push(firestore_document.uploaded_file_size);
        if(firestore_document.uploaded_file_name === undefined) {
          firestore_document.uploaded_file_name = "Uploaded File";
        }

        //@ts-ignore this won't be undefiend because it only runs on messages with files
        if(firestore_document.uploaded_file_name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          data_to_add.push(1); // set image file type flag
        //@ts-ignore this won't be undefiend because it only runs on messages with files
        } else  if(firestore_document.uploaded_file_name.match(/\.(mpg|mpeg|mp4|mov)$/i)) {
          data_to_add.push(2); // set video file type flag
        } else {
          //@ts-ignore
          data_to_add.push(3);
        }        
    }

    //@ts-ignore ts can't understand the else-if prevents data_to_add from only being 5 in length
    CI.IV_conversation_messages.splice(insert_index, 0,data_to_add);

    //console.log(CI.IV_conversation_messages);
  }
  __fs35CollectionListenerCallback() {
    // TODO
  }

  __fs130CollectionListenerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs130RecyclerTermsAndConditions,
    recycler_business_uid: string,
    recycler_location_uid: string
  ): void {
    const self = class_instance;
    const doc_data = firestore_document;

    if (!doc_data) { return; }

    let business_and_recycler_location_and_type_number_uuid = recycler_business_uid + "-" + recycler_location_uid + "-" + doc_data.type_number;

    Vue.set(self.IV_transaction_terms_and_conditions.value, business_and_recycler_location_and_type_number_uuid, doc_data)
  }

  startConsumersActivityStatusListener(
    class_instance: bi2DataInteractions,
    recycler_business_uid: string,
    recycler_location_uid: string,
    employe_uid: string,
    consumer_uid: string,
    conversation_uid: string
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg:
        "bi2DataInteractions:startRecyclerLocationsPaymentApiListener: ",
      success: true,
    };

    // TODO - check if listener already there

    const doc_ref = doc(
      self.IV_firebase_app,
      "fs1RecyclerBusiness",
      recycler_business_uid,
      "fs2RecyclerLocation",
      recycler_location_uid,
      "fs101EmployeeConversationStatuses",
      employe_uid,
      "fs102ConsumerConversationStatuses",
      consumer_uid,
      "fs103ConversationStatuses",
      conversation_uid
    );

    const unsub = onSnapshot(doc_ref, (doc_snapshot) => {
      const doc_data = doc_snapshot.data() as fs103ConversationStatuses;
      self.__fs103ConversationStatusesCallback(self, doc_data);
    });

    return result;
  }

  __fs103ConversationStatusesCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs103ConversationStatuses,
  ): void {
    const self = class_instance;
    const doc = firestore_document;

    if (!doc) { return; }

    Vue.set(self.IV_conversation_activity_status.value, 'last_activity_time', doc.last_activity_time);
    Vue.set(self.IV_conversation_activity_status.value, 'consumer_typing_status', doc.consumer_typing_status);
    Vue.set(self.IV_conversation_activity_status.value, 'employee_typing_status', doc.employee_typing_status);

    return;
  }

  startPaymentLinksListener(
    class_instance: bi2DataInteractions,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startPaymentLinksListener: ",
      success: true,
    };

    if (this.IV_payment_links_listener) {
      result.return_msg += `Listener already started for "fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs84ConsumerPaymentLinks"`
      return result;
    }

    const collection_ref = collection(
      self.IV_firebase_app,
      `fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs84ConsumerPaymentLinks`
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs84ConsumerPaymentLinks;
          self.__fs84ConsumerPaymentLinksCollectionListner(
            self,
            doc_data,
            fs7_uid,
            fs8_uid,
            fs9_uid
          );
        }
      });
    });

    this.IV_payment_links_listener = unsub;

    return result;
  }
  
  async __fs84ConsumerPaymentLinksCollectionListner(
    class_instance: bi2DataInteractions,
    firestore_document: fs84ConsumerPaymentLinks,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;

    let data:any = {
      payment_link_uid: doc_data.payment_link_uid, 
      payment_link_url: doc_data.payment_link_url,
      payment_link_description: doc_data.payment_link_description,
      amount: doc_data.amount || 0,
      order_number: doc_data.order_number,
      payment_status: doc_data.payment_status,
      payment_processor_id: doc_data.payment_processor_id,
      payment_paid_time: doc_data.payment_paid_time,
      terms_type_number: doc_data.terms_type_number,
      refunded_amount: doc_data.refunded_amount || 0,
    }

    let payment_link_uid:any = "";
    payment_link_uid = doc_data.payment_link_uid;

    Vue.set(
      self.IV_consumer_conversation_payment_links.value,
      payment_link_uid, 
      data
    );

    if (!data.payment_status || data.payment_status === "no_status") {
      return;
    }
    
    // Look for payment information.
    if (data.payment_processor_id === 1) {
      self.startSlimCdPymentLinkInfoListener(
        self, 
        fs7_uid, 
        fs8_uid, 
        fs9_uid, 
        data.payment_link_uid
      );
    }

    if (data.payment_processor_id === 2) {
      self.startAxePymentLinkInfoListener(
        self, 
        fs7_uid, 
        fs8_uid, 
        fs9_uid, 
        data.payment_link_uid
      );
    }
  }

  startConsumerIdentityVerificationDetailsListener(
    class_instance: bi2DataInteractions,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string
  ): Result {
    const self = class_instance;
    
    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startConsumerIdentityVerificationDetailsListener: ",
      success: true,
    };

    if (this.IV_identity_verification_links_listener) {
      result.return_msg += `Listener already started for "fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs118ConsumerIdentityVerificationDetails"`
      return result;
    }

    const collection_ref = collection(
      self.IV_firebase_app,
      `fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs118ConsumerIdentityVerificationDetails`
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs118ConsumerIdentityVerificationDetails;
          self.__fs118ConsumerIdentityVerificationDetailsListnerCallback(
            self,
            doc_data,
            fs7_uid,
            fs8_uid,
            fs9_uid,
            change.doc.id
          );
        }
      });
    });

    this.IV_identity_verification_links_listener = unsub;

    return result;
  }
  
  async __fs118ConsumerIdentityVerificationDetailsListnerCallback(
    class_instance: bi2DataInteractions,
    firestore_document: fs118ConsumerIdentityVerificationDetails,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string,
    fs118_uid: string
  ): Promise<void> {
    const self = class_instance;
    const doc_data = firestore_document;

    let data:any = {
      verification_session_id: doc_data.verification_session_id, 
      verification_link: doc_data.verification_link,
      redirect_link: doc_data.redirect_link,
      status: doc_data.status,
      time_verified: doc_data.time_verified,
      last_time_event_received: doc_data.last_time_event_received,
    }

    let fs118_uid_str:any = "";
    fs118_uid_str = fs118_uid;

    Vue.set(
      self.IV_consumer_identity_verification_links.value,
      fs118_uid_str, 
      data
    );

    return;
  }

  startSlimCdPymentLinkInfoListener(
    class_instance: bi2DataInteractions,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string,
    payment_link_uid: string
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startSlimCdPymentLinkInfoListener: ",
      success: true,
    };

    const collection_ref = collection(
      self.IV_firebase_app,
      `fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs84ConsumerPaymentLinks/${payment_link_uid}/fs86ConsumerSlimCdPaymentUrl`
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs86ConsumerSlimCdPaymentUrl;
          const doc_id = change.doc.id;
          self.__fs86ConsumerSlimCdPaymentUrlInfoListnerCallback(
            self,
            doc_data,
            doc_id,
            payment_link_uid
          );
        }
      });
    });

    return result;
  }

  __fs86ConsumerSlimCdPaymentUrlInfoListnerCallback(
      class_instance: bi2DataInteractions,
      firestore_document: fs86ConsumerSlimCdPaymentUrl,
      firestore_document_id: string,
      payment_link_uid: string
    ) {
    const self = class_instance;
    const doc_data = firestore_document;

    if (payment_link_uid in self.IV_payment_links_info.value === false) {
      Vue.set(self.IV_payment_links_info.value, payment_link_uid, {});
    }

    Vue.set(self.IV_payment_links_info.value[payment_link_uid], firestore_document_id, doc_data);
  }

  startAxePymentLinkInfoListener(
    class_instance: bi2DataInteractions,
    fs7_uid: string,
    fs8_uid: string,
    fs9_uid: string,
    payment_link_uid: string
  ): Result {
    const self = class_instance;

    let result: Result = {
      debug_data: [],
      return_msg: "bi2DataInteractions:startAxePymentLinkInfoListener: ",
      success: true,
    };

    const collection_ref = collection(
      self.IV_firebase_app,
      `fs7ConsumerAccounts/${fs7_uid}/fs8ConsumerContactPointers/${fs8_uid}/fs9ConsumerConversationPointers/${fs9_uid}/fs84ConsumerPaymentLinks/${payment_link_uid}/fs122ConsumerAxePaymentsUrl`
    );

    const unsub = onSnapshot(collection_ref, (doc_snapshots) => {
      doc_snapshots.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified" ) {
          const doc_data = change.doc.data() as fs122ConsumerAxePaymentsUrl;
          const doc_id = change.doc.id;
          self.__fs122ConsumerAxePaymentsUrlInfoListnerCallback(
            self,
            doc_data,
            doc_id,
            payment_link_uid
          );
        }
      });
    });

    return result;
  }

  __fs122ConsumerAxePaymentsUrlInfoListnerCallback(
      class_instance: bi2DataInteractions,
      firestore_document: fs122ConsumerAxePaymentsUrl,
      firestore_document_id: string,
      payment_link_uid: string
    ) {
    const self = class_instance;
    const doc_data = firestore_document;

    if (payment_link_uid in self.IV_payment_links_info.value === false) {
      Vue.set(self.IV_payment_links_info.value, payment_link_uid, {});
    }

    Vue.set(self.IV_payment_links_info.value[payment_link_uid], firestore_document_id, doc_data);
  }
}
