import { FirestoreDB } from "./include/creds";
import { setDocIn_rettype, GetDocResult } from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc
} from "firebase/firestore";

export type fs103ConversationStatuses = {
  last_activity_time: Date | string;
  employee_typing_status: string;
  consumer_typing_status: string;
};

export function isValidData(data: fs103ConversationStatuses): boolean {
  return true;
}

type SingleFieldParams = {
  [field_name: string]: string | Date;
}

export async function getDocFrom_fs103ConversationStatuses(
  fs1_uid: string,
  fs2_uid: string,
  fs3_uid: string,
  fs4_uid: string,
  fs6_uid: string
): Promise<GetDocResult> {

  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs103ConversationStatuses:getDocFrom_fs103ConversationStatuses",
    success: true,
    doc_data: {},
  };

  if (!fs1_uid || !fs2_uid || !fs4_uid || !fs6_uid || !fs6_uid) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_uid,
    "fs2RecyclerLocation",
    fs2_uid,
    "fs101EmployeeConversationStatuses",
    fs3_uid,
    "fs102ConsumerConversationStatuses",
    fs4_uid,
    "fs103ConversationStatuses",
    fs6_uid
  );

  const doc_data = (await getDoc(doc_ref)).data();

  if (doc_data === undefined) {
    result.success = false;
    result.return_msg += "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function setDocIn_fs103ConversationStatuses(
  fs1_uid: string,
  fs2_uid: string,
  fs3_uid: string,
  fs4_uid: string,
  fs6_uid: string,
  data: fs103ConversationStatuses
): Promise<setDocIn_rettype> {

  const retval = {
    docref_id: "",
    docref_path: "",
  } ;

  if (!isValidData(data)) { return await Promise.resolve(retval);}

  const doc_ref = doc(
    FirestoreDB,
    "fs1RecyclerBusiness",
    fs1_uid,
    "fs2RecyclerLocation",
    fs2_uid,
    "fs101EmployeeConversationStatuses",
    fs3_uid
  )
  const doc_ref_obj = await getDoc(doc_ref);
  if (!doc_ref_obj.exists()) { await setDoc(doc_ref, {}); }

  const doc_ref2 = doc(doc_ref, "fs102ConsumerConversationStatuses", fs4_uid );
  const doc_ref2_obj = await getDoc(doc_ref2) 
  if (!doc_ref2_obj.exists()) { await setDoc(doc_ref2, {}); }

  const doc_ref3 = doc(doc_ref2, "fs103ConversationStatuses", fs6_uid );
  await setDoc(doc_ref3, data);

  retval.docref_id = doc_ref3.id;
  retval.docref_path = doc_ref3.path;
  return await Promise.resolve(retval);
}

export async function setFieldIn_fs103ConversationStatusese(
  fs1_uid: string,
  fs2_uid: string,
  fs3_uid: string,
  fs4_uid: string,
  fs6_uid: string,
  field_name: string,
  field_value: Date | string
): Promise<setDocIn_rettype> {

  const retval = {
    docref_id: "",
    docref_path: "",
  };

  const doc_ref = doc(FirestoreDB, "fs1RecyclerBusiness", fs1_uid, "fs2RecyclerLocation", fs2_uid, "fs101EmployeeConversationStatuses", fs3_uid);
  const doc_ref_obj = await getDoc(doc_ref);
  if (!doc_ref_obj.exists()) { await setDoc(doc_ref, {}); }

  const doc_ref2 = doc(doc_ref, "fs102ConsumerConversationStatuses", fs4_uid );
  const doc_ref2_obj = await getDoc(doc_ref2) 
  if (!doc_ref2_obj.exists()) { await setDoc(doc_ref2, {}); }

  const doc_ref3 = doc(doc_ref2, "fs103ConversationStatuses", fs6_uid );
  
  let params: SingleFieldParams = {};
  params[field_name] = field_value;
  await updateDoc(doc_ref3, params);

  retval.docref_id = doc_ref3.id;
  retval.docref_path = doc_ref3.path;;

  return await Promise.resolve(retval);
}
