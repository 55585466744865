<template>
  <div style="height: 85vh;" class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll">

    <!-- Header -->
    <div class="text-center mb-3 flex items-center justify-between">
      <svg @click="$emit('event-back')"xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
      </svg>

      <div class="text-black text-xl mb-4">
        Payment Status
      </div>

      <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>

    <hr>

    <!-- Heading -->
    <h1 class="font-bold text-2xl my-4">{{P_paymentLink.payment_link_description}} for <span class="text-lime-500">${{P_paymentLink.amount}}</span></h1>

    <!-- Loading Payment info -->
    <div v-if="DV_loading">
      <svg class="h-12 mt-2 text-gray-400 spin-anticlock mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </div>
    
    <!-- Payment info not found -->
    <div class="mt-4 font-medium" v-else-if="DV_paymentInfoFound">
      
      <div class="mt-4 font-medium text-left">
        <b>Payment details</b><br/>
        <span class="text-gray-400">Card holder name: </span> {{C_cardHolderName}}<br/>
        <span class="text-gray-400">Card details: </span> **** **** **** {{C_lastFourDigits}}<br/>
        <span class="text-gray-400">Date and Time: </span> {{C_dateTime}}<br/>
      </div>
    </div>

    <!-- Payment info -->
    <div class="mt-6">
      <div class="bg-gray-300 w-10/12 m-auto p-8 font-medium">
        We are processing your request. 
        Please wait.
        <br/>
        <br/>
        <div class="bg-white border w-4/5 m-auto">
          <div class="bg-green-500" style="height:24px;width:50%"></div>
        </div>
        50%
      </div>
    </div>

    <!-- Payment info not found -->
    <div class="mt-4 font-medium" v-if="!DV_paymentInfoFound">

      Payment details are not available yet. Please contact support at <a href="mailto:info@texnrewards.com">info@texnrewards.com</a> for more details.
    </div>
    
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"
import requestEmailReceipt from "../../includes/server_calls/p1s5/p1s5e7";
import moment from "moment-timezone"

export default {
  name: "c2c1c3PaymentLinkProgressPage",

  props: ['P_paymentLink'],

  data() {
    return {
      DV_sendingEmail: false,
      DV_paymentLinksInformation: {},
      DV_currentPaymentInfo: {},
      DV_loading: true,
      DV_paymentInfoFound: false
    }
  },

  mounted() {
    const CI = this;
    CI.DV_paymentLinksInformation = window.G_bi2_data.IV_payment_links_info.value
    
    // wait for sometime to show payment info not found
    setTimeout(() => {CI.DV_loading = false;}, 2500);
  },

  methods: {
    // 
  },

  computed: {
    C_dateTime() {
      const CI = this;

      if (CI.P_paymentLink.payment_paid_time) {
        return moment.utc(CI.P_paymentLink.payment_paid_time.seconds * 1000).tz('America/New_York').format('YYYY-MM-DD [at] h:mm A z');
      }

      return "Unknown date";
    },

    C_espDateTime() {
      const CI = this;

      if (CI.P_paymentLink.payment_paid_time) {
        return moment.utc(CI.P_paymentLink.payment_paid_time.seconds * 1000).tz('America/New_York').format('YYYY-MM-DD [a las] h:mm A z');
      }

      return "Unknown";
    },

    C_lastFourDigits() {
      const CI = this;

      return CI.DV_currentPaymentInfo.last_4_digits || "Unknown";      
    },

    C_cardHolderName() {
      const CI = this;

      if (CI.DV_currentPaymentInfo.last_name || CI.DV_currentPaymentInfo.first_name) {
        return `${CI.DV_currentPaymentInfo.first_name} ${CI.DV_currentPaymentInfo.last_name}`;
      }

      return "Unknown";
    }
  },

  watch: {
    DV_paymentLinksInformation: {
      handler() {
        const CI = this;

        if (CI.P_paymentLink.payment_link_uid in CI.DV_paymentLinksInformation == false) {
          return;
        }

        CI.DV_loading = false;
        CI.DV_paymentInfoFound = true;

        for (let payment_info_uid in CI.DV_paymentLinksInformation[CI.P_paymentLink.payment_link_uid]) {
          const paymentInfo = CI.DV_paymentLinksInformation[CI.P_paymentLink.payment_link_uid][payment_info_uid] || {};

          CI.DV_currentPaymentInfo = Object.assign({}, paymentInfo);
        }        
      },

      deep: true
    }
  }
}
</script>
