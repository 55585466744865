<template>
   <div style="height: 85vh;" class="border border-gray-400 shadow-sm rounded-xl p-2 m-2 overflow-y-scroll">

    <!-- Header -->
    <div class="text-center mb-3 flex items-center justify-between">
      <svg @click="$emit('event-back')"xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="cursor-pointer w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
      </svg>

      <div class="text-black text-xl mb-4">
        Payment Failed
      </div>

      <svg @click="$emit('event-back')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="cursor-pointer w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>

    <hr>

    <!-- Heading -->
    <h1 class="font-bold text-2xl mt-4">{{P_paymentLink.payment_link_description}} for <span class="text-lime-500">${{P_paymentLink.amount}}</span></h1>

    <div class="bg-gray-300 w-11/12 m-auto p-4 font-medium mt-12">
      Payment failed.<br/>Please check details and try<br/>a different method if needed.
    
      <button @click.stop="tryAgainPayment" class="btn btn-white text-center w-72 mt-4" type="submit" id="btnSubmit">
        Try again
      </button>

      <button @click.stop="addAnotherPaymentMethod" class="btn btn-blue text-center w-72 mt-4" type="submit" id="btnSubmit">
        Add another payment method
      </button>

      <button @click.stop="cancelRequest" class="btn btn-lime text-center w-72 mt-4" type="submit" id="btnSubmit">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: "c2c1c4PaymentLinkFailedPage",

  props: ['P_paymentLink'],

  data() {
    return {
      // 
    }
  },

  mounted() {
    const CI = this;
    // 
  },

  methods: {
    tryAgainPayment() {
      const CI = this;

      CI.$emit("event-try-payment-again");
    },

    addAnotherPaymentMethod() {
      const CI = this;

      CI.$emit("event-try-different-payment");
    },

    cancelRequest() {
      const CI = this;

      CI.$emit("event-back");
    }

  },

  computed: {
    // 
  },

  watch: {
    // 
  }
}
</script>
